
import { SiteData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import ArrowRight from '../icons/ArrowRight.vue'
import NavLink from '../NavLink.vue'
import TvShowLogoCard from './TvShowLogoCard.vue'

@Options({
  name: 'TvShowLogoCardList',
  components: {
    ArrowRight,
    TvShowLogoCard,
    NavLink
  }
})
export default class TvShowLogoCardList extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: SiteData[]

  @Prop({ type: String, default: 'Összes műsor' })
  readonly btnLabel!: string

  @Prop({ type: String, default: '' })
  readonly btnUrl!: string
}
