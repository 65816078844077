export const Cookie = {
  set: (name: string, value: string, expireHours: number | null = null, expireAt: Date | null, domain: string, path = '/') => {
    let expires = ''
    if (expireHours !== null) {
      const d = new Date()
      d.setTime(d.getTime() + (expireHours * 60 * 60 * 1000))
      expires = 'expires=' + d.toUTCString() + ';'
    }

    if (expireAt) {
      expires = 'expires= ' + expireAt.toUTCString() + ';'
    }

    document.cookie = `${name}=${value};${expires}path=${path}; domain=${domain}`
  },
  get: (name: string) => {
    const query = name + '='
    const ca = document.cookie.split(';')

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(query) === 0) {
        return c.substring(query.length, c.length)
      }
    }

    return null
  },
  delete: (name: string, path = '/') => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`
  }
}

export default Cookie
