import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rtl-now"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_card_list = _resolveComponent("content-card-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.contents.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_widget_block, _mergeProps({
          title: _ctx.title,
          "show-divider": true,
          "btn-label": _ctx.btnLabel,
          "btn-url": _ctx.btnUrl,
          "show-slider": true
        }, _ctx.widgetStyleBinding), {
          default: _withCtx(() => [
            _createVNode(_component_content_card_list, {
              contents: _ctx.contents,
              decorators: _ctx.decorators,
              "show-duration": true,
              "show-tag": true,
              "show-release-date": true,
              "show-slider": true,
              "show-lead": false,
              cols: 4
            }, null, 8, ["contents", "decorators"])
          ]),
          _: 1
        }, 16, ["title", "btn-label", "btn-url"])
      ]))
    : _createCommentVNode("", true)
}