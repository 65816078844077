
import { Options, Vue } from 'vue-property-decorator'

import { detectDeviceType } from './../../utils'
import Modal from './Modal.vue'

@Options({
  name: 'ForceModal',
  components: {
    Modal
  }
})
export default class ForceModal extends Vue {
  // window.open("systemLinks.android","_system");
  // IOS: itms-apps://itunes.apple.com/app/[appId]
  // Android: market://details?id=[appPackageId]

  systemLinks = {
    android: 'https://play.google.com/store/apps/details?id=hu.telekomnewmedia.valovilag&hl=hu&gl=US',
    ios: 'https://apps.apple.com/hu/app/rtl-hu/id1148979639',
    web: ''
  }

  openStore () {
    window.location.assign(this.systemLinks[this.deviceType])
  }

  get deviceType (): 'android' | 'ios' | 'web' {
    return detectDeviceType()
  }
}
