import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentListPayload, ContentListResult } from '../../store'

@Options({
  name: 'FetchContentListMixin'
})
export default class FetchContentListMixin extends Vue {
  @Action(CONTENT_ACTION.LIST, { namespace: CONTENT_NAMESPACE })
  storeFetchContentList!: (payload: ContentListPayload) => Promise<ContentListResult>

  @Prop({
    type: Boolean,
    default: false
  })
  readonly lazy!: boolean

  get contentList (): ContentListResult['data'] | null {
    if (this.contentListPayload) {
      return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], this.contentListPayload)?.data || null
    }
    return null
  }

  get contentListPayload (): ContentListPayload | null {
    return {}
  }

  @Watch('contentListPayload', { deep: true })
  async fetchContentList () {
    if (this.contentListPayload) {
      return this.storeFetchContentList(this.contentListPayload)
    }
  }

  async serverPrefetch () {
    if (!this.lazy) {
      return this.fetchContentList()
    }
  }

  mounted () {
    return this.fetchContentList()
  }
}
