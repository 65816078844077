"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivilegeToJSONTyped = exports.PrivilegeToJSON = exports.PrivilegeFromJSONTyped = exports.PrivilegeFromJSON = void 0;
const runtime_1 = require("../runtime");
function PrivilegeFromJSON(json) {
    return PrivilegeFromJSONTyped(json, false);
}
exports.PrivilegeFromJSON = PrivilegeFromJSON;
function PrivilegeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'ruleName': !(0, runtime_1.exists)(json, 'ruleName') ? undefined : json['ruleName'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : json['updatedAt'],
    };
}
exports.PrivilegeFromJSONTyped = PrivilegeFromJSONTyped;
function PrivilegeToJSON(value) {
    return PrivilegeToJSONTyped(value, false);
}
exports.PrivilegeToJSON = PrivilegeToJSON;
function PrivilegeToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'name': value.name,
        'description': value.description,
        'ruleName': value.ruleName,
        'data': value.data,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.PrivilegeToJSONTyped = PrivilegeToJSONTyped;
