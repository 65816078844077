import { createLogger } from 'vuex'

export default function () {
  return createLogger({
    collapsed: true,
    logActions: true,
    logMutations: true,
    logger: console
  })
}
