
import { Options, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { Storage } from '../../utils'
import { MEASUREMENT_NAMESPACE } from '../../store'
import Close from './icons/Close.vue'
import MainLogo from './icons/MainLogo.vue'

@Options({
  name: 'AdblockConfirm',
  components: {
    Close,
    MainLogo
  }
})
export default class App extends Vue {
  @State('adoScriptLoaded', { namespace: MEASUREMENT_NAMESPACE })
  adoScriptLoaded!: boolean;

  show = false
  storageKey = 'adblock-modal-close'
  lifetime = 7 * 24 * 60 * 60 * 1000 // 7 days

  get isExpired () {
    const expire = Storage.get(this.storageKey)
    if (expire) {
      return Date.now() > expire + this.lifetime
    } else {
      return true
    }
  }

  setExpire () {
    Storage.set(this.storageKey, Date.now())
  }

  toggle (val?: boolean, action?: string) {
    this.show = val === undefined ? !this.show : val
    if (!this.show) {
      this.setExpire()
    }
    
    if (action) {
      this.measure(action)
    }
  }

  measure (action: string, extraParams?: Partial<{[key: string]: string}>) {
    if (this.$gtm && this.$gtm.enabled()) {
      this.$gtm.trackEvent({
        event: 'interaction',
        event_name: `${action}_promotion`,
        promotion_name: 'adblock',
        ...extraParams
      })
    }
  }

  @Watch('adoScriptLoaded')
  updateAdoScriptLoaded () {
    if (this.isExpired && !window.ado) {
      this.toggle(true, 'view')
    }
  }
}
