import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "recommended-video-list recommended-video-list--collapsed" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "recommended-video-list__main-title" }
const _hoisted_4 = {
  key: 1,
  class: "recommended-video-list__row"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.bigContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_video_player, {
            "video-src": _ctx.bigContent.params?.embedVideoUrl,
            title: _ctx.bigContent.title,
            autoplay: _ctx.autoplay,
            source: 'cikkoldali_videoajanlo',
            medium: _ctx.medium,
            campaign: _ctx.campaignNumber
          }, null, 8, ["video-src", "title", "autoplay", "medium", "campaign"]),
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.bigContent.title), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.secondColContents)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondColContents, (content, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: content.id,
              class: _normalizeClass(["recommended-video-list__col", `recommended-video-list__col--${_ctx.secondColContents.length}`]),
              onClick: ($event: any) => (_ctx.swapVideo(content.id, i + 2))
            }, [
              _createVNode(_component_content_card, _mergeProps(_ctx.decorateContent(content), {
                "show-border": false,
                size: 'shrink',
                "show-lead": false,
                navigation: false
              }), null, 16)
            ], 10, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}