
import { mixins, Options } from 'vue-property-decorator'

import Bell from '../icons/Bell.vue'
import Close from '../icons/Close.vue'
import MarketingToolMixin from './MarketingToolMixin'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let UA: Promise<any> | undefined

@Options({
  name: 'PushNotification',
  components: {
    Bell,
    Close
  }
})
export default class PushNotification extends mixins(MarketingToolMixin) {
  pipeKey = 'push-notification'
  storageKey = 'push-notification-close'
  sdk?: any | null = null
  isActivated?: boolean | null = null
  promotionName = 'web_notification_banner'

  get isEnabled (): boolean {
    return !this.isActivated && !!this.sdk?.isSupported && !!this.sdk?.canRegister
  }

  async activate () {
    if (this.sdk) {
      try {
        const result = await this.sdk.register()
        this.isActivated = true
        this.measure('select', this.promotionName)
        console.log('=== User registered successfully: %s', result.channel_id)
      } catch (e) {
        console.error(e)
      }
    }
  }

  async beforeMount () {
    if ('UA' in window) {
      this.sdk = await UA
      const { optedIn } = await this.sdk.getChannel()
      this.isActivated = optedIn
    } else {
      this.isShow = false
    }
  }

  async mounted () {
    this.$nextTick(() => {
      if (this.isAvailable) {
        this.measure('view', this.promotionName)
      }
    })
  }
}
