"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminChildFoldersDataToJSONTyped = exports.AdminChildFoldersDataToJSON = exports.AdminChildFoldersDataFromJSONTyped = exports.AdminChildFoldersDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminChildFoldersDataFromJSON(json) {
    return AdminChildFoldersDataFromJSONTyped(json, false);
}
exports.AdminChildFoldersDataFromJSON = AdminChildFoldersDataFromJSON;
function AdminChildFoldersDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, _1.AdminChildFoldersDataPayloadFromJSON)(json['payload']),
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.AdminFolderFromJSON)),
    };
}
exports.AdminChildFoldersDataFromJSONTyped = AdminChildFoldersDataFromJSONTyped;
function AdminChildFoldersDataToJSON(value) {
    return AdminChildFoldersDataToJSONTyped(value, false);
}
exports.AdminChildFoldersDataToJSON = AdminChildFoldersDataToJSON;
function AdminChildFoldersDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'payload': (0, _1.AdminChildFoldersDataPayloadToJSON)(value.payload),
        'items': value.items === undefined ? undefined : (value.items.map(_1.AdminFolderToJSON)),
    };
}
exports.AdminChildFoldersDataToJSONTyped = AdminChildFoldersDataToJSONTyped;
