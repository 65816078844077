import { ContentData, ContentType, Tag } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { formatSeconds, isExternalUrl, stripHtml } from '../../utils'
import ContentCardParamsMixin from './ContentCardParamsMixin'

@Options({
  name: 'ContentCardMixin',
  inject: { // Implement it natively, because @inject decorator not working across mixins
    urlPrefix: {
      from: 'contentUrlPrefix',
      default: ''
    }
  }
})
export default class ContentCardMixin extends mixins(ContentCardParamsMixin) {
  urlPrefix!: string

  @Prop({ type: Object, required: true })
  readonly content!: ContentData

  @Prop({ type: Boolean, default: true })
  readonly leadCropping!: boolean

  get isVideo (): boolean {
    return !!this.content.params?.withVideo
  }

  get videoDuration (): string {
    return formatSeconds(this.content.params?.duration || 0)
  }

  get imageUrl (): string | null {
    // TODO: this.content.leadImageUrls?.leadImageUrl_16-9
    return (this.content.leadImageUrls?.leadImageUrl169 || this.content.leadImageUrls?.leadImageUrl) || null
  }

  get title (): string | null {
    return (this.content.alternativeTitle || this.content.title || '').trim() || null
  }

  get lead (): string | null {
    return stripHtml(this.content.alternativeLead || this.content.lead) || null
  }

  get tag (): Tag | null {
    return this.content.headingTag || null
  }

  get url (): string {
    let url = this.content.outerUrl || this.content.url || ''
    if (!url || isExternalUrl(url)) {
      return url
    }
    if (!url.startsWith('/')) {
      url = `/${url}`
    }
    if (this.urlPrefix) {
      url = this.urlPrefix + url
    }

    return url
  }

  get releaseDate (): Date | null {
    return this.content.releaseDate || null
  }

  get isMinuteByMinute (): boolean {
    return this.content.contentType === ContentType.MinuteByMinute
  }

  get isLive (): boolean {
    return this.content.params?.isLiveFeed === 1
  }

  get isGallery (): boolean {
    return this.content.contentType === ContentType.Gallery ||
      !!this.content?.params?.hasAttachedGallery ||
      !!this.content?.params?.galleryImages?.length
  }
}
