
import { SiteListQueryOrderDirectionEnum, SiteListQueryOrderFieldEnum, SiteTypes } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { SiteListPayload } from '../../store'
import TvShowLogoCardList from '../components/tv-show/TvShowLogoCardList.vue'
import TvShowTotemList from '../components/tv-show/TvShowTotemList.vue'
import { FetchSiteListMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Műsor lista',
  fields: {
    ...widgetMetaFields,
    length: {
      type: Number,
      default: 4,
      props: {
        placeholder: 'Darabszám',
        min: 2
      }
    },
    totem: {
      type: Boolean,
      default: false,
      props: {
        label: 'Totem'
      }
    }
  }
}

@Options({
  name: 'TvShowLogoListWidget',
  components: {
    TvShowLogoCardList,
    TvShowTotemList
  }
})
export default class TvShowLogoListWidget extends mixins(WidgetMixin, FetchSiteListMixin) {
  @Prop({
    type: String,
    default: '/musorok/osszes'
  })
  declare readonly btnUrl: string

  @Prop({
    type: String,
    default: 'Összes műsor'
  })
  declare readonly btnLabel: string

  @Prop({
    type: Number,
    default: 4
  })
  readonly length!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly totem!: boolean

  @Prop({
    type: Object
  })
  readonly payload: Partial<SiteListPayload> | undefined

  get siteListPayload (): SiteListPayload {
    return {
      type: SiteTypes.Show,
      order: [{ field: SiteListQueryOrderFieldEnum.Sequence, direction: SiteListQueryOrderDirectionEnum.Asc }],
      limit: this.length,
      ...this.payload
    }
  }
}
