import { Options, Vue } from 'vue-property-decorator'

import { CacheRecord, PAGE_ACTION, PAGE_NAMESPACE, PagePayload, PageResult } from '../../store'

@Options({
  name: 'GetPageMixin'
})
export default class GetPageMixin extends Vue {
  get pageResult (): CacheRecord<PageResult> | null {
    if (this.pagePayload) {
      return this.$store.getters['$cache/record']([PAGE_NAMESPACE, PAGE_ACTION.ITEM], this.pagePayload) || null
    }
    return null
  }

  get page (): PageResult['data'] | null {
    // @ts-expect-error: RootApp' and 'this' have no overlap
    const rootPage = this.$root !== this ? this.$root.page : null
    return rootPage || this.pageResult?.result?.data || null
  }

  get pageError (): unknown {
    return this.pageResult?.error || null
  }

  get pageUrl () {
    return this.$route?.path
  }

  get pagePayload (): PagePayload | null {
    if (this.pageUrl) {
      const url = this.pageUrl.replace(/^\//g, '')
      return { url }
    }
    return null
  }

  get pageLoading (): boolean {
    return !this.page && !this.pageError
  }
}
