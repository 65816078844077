"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetDataToJSONTyped = exports.WidgetDataToJSON = exports.WidgetDataFromJSONTyped = exports.WidgetDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WidgetDataFromJSON(json) {
    return WidgetDataFromJSONTyped(json, false);
}
exports.WidgetDataFromJSON = WidgetDataFromJSON;
function WidgetDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'componentName': !(0, runtime_1.exists)(json, 'componentName') ? undefined : json['componentName'],
        'parameters': !(0, runtime_1.exists)(json, 'parameters') ? undefined : (0, _1.WidgetDataParametersFromJSON)(json['parameters']),
    };
}
exports.WidgetDataFromJSONTyped = WidgetDataFromJSONTyped;
function WidgetDataToJSON(value) {
    return WidgetDataToJSONTyped(value, false);
}
exports.WidgetDataToJSON = WidgetDataToJSON;
function WidgetDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'componentName': value.componentName,
        'parameters': (0, _1.WidgetDataParametersToJSON)(value.parameters),
    };
}
exports.WidgetDataToJSONTyped = WidgetDataToJSONTyped;
