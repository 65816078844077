
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentCardMixin from '../../mixins/ContentCardMixin'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'

@Options({
  name: 'CelebrityCard',
  components: {
    Image,
    NavLink
  }
})
export default class CelebrityCard extends mixins(ContentCardMixin) {
  @Prop({ type: Boolean, default: false })
  readonly lined!: boolean

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean

  get imageUrl (): string | null {
    if (this.small) {
      return this.content.leadImageUrls?.leadImageUrl11 || null
    } else {
      return this.content.leadImageUrls?.leadImageUrl34 || null
    }
  }
}
