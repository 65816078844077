
import { Options, Prop, Vue } from 'vue-property-decorator'

import Exclamation from '../icons/Exclamation.vue'

@Options({
  name: 'DateInput',
  components: {
    Exclamation
  }
})
export default class DateInput extends Vue {
  @Prop({ type: String, default: '' })
  readonly modelValue!: string

  @Prop({ type: String, required: true })
  readonly label!: string

  @Prop({ type: String, default: null })
  readonly errorMsg!: string | null
  
  @Prop({ type: String, default: '' })
  readonly className!: string
}
