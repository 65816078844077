import { GetCastingFormRequest, ListCastingFormsRequest, ListCastingFormGroupsRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export { CastingFormResult, CastingFormListResult, CastingFormGroupsListResult } from '@rtl/api'

export type FormPayload = ApiPayload<GetCastingFormRequest>
export type FormListPayload = ApiPayload<ListCastingFormsRequest>
export type FormGroupListPayload = ApiPayload<ListCastingFormGroupsRequest>

export interface FormState {}

export enum FORM_ACTION {
  ITEM = 'fetchForm',
  LIST = 'fetchFormList',
  GROUP_LIST = 'fetchFormGroupList'
}

export enum FORM_GETTER {
  API = 'api'
}

export const FORM_NAMESPACE = 'form'
