"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminChildFoldersResultToJSONTyped = exports.AdminChildFoldersResultToJSON = exports.AdminChildFoldersResultFromJSONTyped = exports.AdminChildFoldersResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminChildFoldersResultFromJSON(json) {
    return AdminChildFoldersResultFromJSONTyped(json, false);
}
exports.AdminChildFoldersResultFromJSON = AdminChildFoldersResultFromJSON;
function AdminChildFoldersResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : (0, _1.ErrorDataFromJSON)(json['error']),
        'result': (0, _1.ResultTypeFromJSON)(json['result']),
        'status': (0, _1.HTTPStatusCodeFromJSON)(json['status']),
        'data': (0, _1.AdminChildFoldersDataFromJSON)(json['data']),
    };
}
exports.AdminChildFoldersResultFromJSONTyped = AdminChildFoldersResultFromJSONTyped;
function AdminChildFoldersResultToJSON(value) {
    return AdminChildFoldersResultToJSONTyped(value, false);
}
exports.AdminChildFoldersResultToJSON = AdminChildFoldersResultToJSON;
function AdminChildFoldersResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'error': (0, _1.ErrorDataToJSON)(value.error),
        'result': (0, _1.ResultTypeToJSON)(value.result),
        'status': (0, _1.HTTPStatusCodeToJSON)(value.status),
        'data': (0, _1.AdminChildFoldersDataToJSON)(value.data),
    };
}
exports.AdminChildFoldersResultToJSONTyped = AdminChildFoldersResultToJSONTyped;
