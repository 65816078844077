import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "html-content-widget" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content && _ctx.content.styles)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.content.styles, (style) => {
          return (_openBlock(), _createElementBlock("link", {
            href: style,
            rel: "stylesheet",
            type: "text/css"
          }, null, 8, _hoisted_2))
        }), 256))
      : _createCommentVNode("", true),
    (_ctx.content && _ctx.content.template)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "html-content-widget__template",
          ref: "content",
          innerHTML: _ctx.content.template
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}