import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "top-show-widget__list" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_dragscroll = _resolveDirective("dragscroll")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.leadImageUrls)
          ? (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "top-show-widget__item"
            }, [
              _createElementVNode("img", {
                src: item.leadImageUrls.leadImageUrl
              }, null, 8, _hoisted_2),
              _createElementVNode("a", {
                "data-utm": "true",
                href: item.outerUrl,
                class: "top-show-widget__item__details"
              }, [
                (item.label)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                      _createElementVNode("li", null, [
                        _createElementVNode("small", null, _toDisplayString(item.label), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ])), [
    [_directive_dragscroll]
  ])
}