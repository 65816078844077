import { FormCategory, FormInputs } from './../types'

export const testFormId: string = '00D8E0000009egx'

export const testForm: { [key: string]: FormCategory } = {
  services: {
    type: "select",
    id: "00N8E00000DsPnT",
    name: "00N8E00000DsPnT",
    title: "Szolgáltatás megnevezése",
    label: "Szolgáltatás megnevezése",
    categoryName: "services",
    parentCategory: null,
    options: [
      { value: "RTL+", title: "RTL+ (új streaming csomag)", service: [], parent: null },
      { value: "RTL+ Light", title: "RTL+ Light (korábban RTL Most)", service: [], parent: null },
      { value: "RTL+ Active", title: "RTL+ Active (korábban RTL Most+)", service: [], parent: null },
      { value: "rtl.hu", title: "rtl.hu", service: [], parent: null },
      { value: "TV adás", title: "TV adás", service: [], parent: null }
    ],
  },
  mainCategory: {
    type: "select",
    id: "00N8E00000DsPn9",
    name: "00N8E00000DsPn9",
    title: "Főkategória",
    label: "Kategória kiválasztása",
    categoryName: "mainCategory",
    parentCategory: null,
    options: [
      {
        value: "Általános információk",
        title: "Általános információk",
        service: ["light", "active", "plus", "hu", "tv"],
        parent: null
      },
      {
        value: "Regisztráció",
        title: "Regisztráció",
        service: ["active", "plus"],
        parent: null
      },
      {
        value: "Bejelentkezési probléma",
        title: "Bejelentkezési probléma",
        service: ["light", "active", "plus", "hu"],
        parent: null
      },
      {
        value: "Felhasználói adat módosulás",
        title: "Felhasználói adat módosulás",
        service: ["light", "active", "plus"],
        parent: null
      },
      {
        value: "Előfizetéssel kapcsolatos kérdések",
        title: "Előfizetéssel kapcsolatos kérdések",
        service: ["plus"],
        parent: null
      },
      { value: "Casting", title: "Casting", service: ["tv"], parent: null },
      {
        value: "Szereplővel kapcsolatos kérdések",
        title: "Szereplővel kapcsolatos kérdések",
        service: ["tv"],
        parent: null
      },
      {
        value: "Technikai hiba",
        title: "Technikai hiba",
        service: ["light", "active", "plus", "hu"],
        parent: null
      },
      {
        value: "Egyéb",
        title: "Egyéb",
        service: ["light", "active", "plus", "hu", "tv"],
        parent: null
      },
    ],
  },
  subCategory: {
    type: "select",
    id: "00N8E00000DsPnX",
    name: "00N8E00000DsPnX",
    title: "Alkategória",
    label: "Segítségre van szükségem, ezzel kapcsolatban",
    categoryName: "subCategory",
    parentCategory: "mainCategory",
    options: [
      {
        value: "Szolgáltatáshoz kapcsolható eszközök",
        title: "Szolgáltatáshoz kapcsolható eszközök",
        service: ["light", "active", "plus"],
        parent: "Általános információk",
      },
      {
        value: "Navigációs kérdések",
        title: "Navigációs kérdések",
        service: ["light", "active", "plus"],
        parent: "Általános információk",
      },
      {
        value: "Kóddal kapcsolatos kérdés",
        title: "Kóddal kapcsolatos kérdés",
        service: ["active", "plus"],
        parent: "Általános információk",
      },
      {
        value: "Műsorokkal és filmekkel kapcsolatos kérdések",
        title: "Műsorokkal és filmekkel kapcsolatos kérdések",
        service: ["light", "active", "plus", "hu", "tv"],
        parent: "Általános információk",
      },
      {
        value: "Reklámokkal kapcsolatos kérdések",
        title: "Reklámokkal kapcsolatos kérdések",
        service: ["light", "active", "plus", "hu", "tv"],
        parent: "Általános információk",
      },
      {
        value: "Fiók létrehozás, összepárosítás",
        title: "Fiók létrehozás, összepárosítás",
        service: ["active", "plus"],
        parent: "Regisztráció",
      },
      {
        value: "Fizetési probléma",
        title: "Fizetési probléma",
        service: ["plus"],
        parent: "Regisztráció",
      },
      {
        value: "Ingyenes próbaidőszak",
        title: "Ingyenes próbaidőszak",
        service: ["plus"],
        parent: "Regisztráció",
      },
      {
        value: "Egyéb",
        title: "Egyéb",
        service: ["plus", "active"],
        parent: "Regisztráció",
      },
      {
        value: "Jelszó hiba",
        title: "Jelszó hiba",
        service: ["light", "active", "plus", "hu"],
        parent: "Bejelentkezési probléma",
      },
      {
        value: "Egyéb",
        title: "Egyéb",
        service: ["light", "active", "plus", "hu"],
        parent: "Bejelentkezési probléma",
      },
      {
        value: "Felhasználói adat módosulás",
        title: "Felhasználói adat módosulás",
        service: ["light", "active", "plus"],
        parent: "Felhasználói adat módosulás",
      },
      {
        value: "Előfizetés",
        title: "Előfizetés",
        service: ["plus"],
        parent: "Előfizetéssel kapcsolatos kérdések",
      },
      {
        value: "Számlázás",
        title: "Számlázás",
        service: ["plus"],
        parent: "Előfizetéssel kapcsolatos kérdések",
      },
      {
        value: "Lemondás",
        title: "Lemondás",
        service: ["plus"],
        parent: "Előfizetéssel kapcsolatos kérdések",
      },
      {
        value: "Módosítás",
        title: "Módosítás",
        service: ["plus"],
        parent: "Előfizetéssel kapcsolatos kérdések",
      },
      {
        value: "Casting",
        title: "Casting",
        service: ["tv"],
        parent: "Casting",
      },
      {
        value: "Szereplővel kapcsolatos kérdések",
        title: "Szereplővel kapcsolatos kérdések",
        service: ["tv"],
        parent: "Szereplővel kapcsolatos kérdések",
      },
      {
        value: "Bejelentkezési probléma",
        title: "Bejelentkezési probléma",
        service: ["light", "active", "plus", "hu"],
        parent: "Technikai hiba",
      },
      {
        value: "Streaming probléma",
        title: "Streaming probléma",
        service: ["light", "active", "plus", "hu"],
        parent: "Technikai hiba",
      },
      {
        value: "Adás probléma",
        title: "Adás probléma",
        service: ["tv"],
        parent: "Technikai hiba",
      },
      {
        value: "Nem elérhető tartalom",
        title: "Nem elérhető tartalom",
        service: ["light", "active", "plus", "hu"],
        parent: "Technikai hiba",
      },
      {
        value: "Külföldről regisztrálás",
        title: "Külföldről regisztrálás",
        service: ["light", "active", "plus"],
        parent: "Technikai hiba",
      },
      {
        value: "Egyéb",
        title: "Egyéb",
        service: ["light", "active", "plus", "hu"],
        parent: "Egyéb",
      },
    ],
  },
  moreCategory: {
    type: "select",
    id: "00N8E00000DsPnY",
    name: "00N8E00000DsPnY",
    title: "További alkategória",
    label: "Segítségre van szükségem, ezzel kapcsolatban",
    categoryName: "moreCategory",
    parentCategory: "subCategory",
    options: [
      {
        value: "Eszközökkel kapcsolatos probléma",
        title: "Eszközökkel kapcsolatos probléma",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Hány eszközön használható a szolgáltatás?",
        title: "Hány eszközön használható a szolgáltatás?",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Felbontás",
        title: "Felbontás",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Letöltés",
        title: "Letöltés",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Folytasd ahol abbahagytad funkció",
        title: "Folytasd ahol abbahagytad funkció",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Chromecast",
        title: "Chromecast",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Set top box",
        title: "Set top box",
        service: ["light", "active"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Okos TV",
        title: "Okos TV",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Profillal kapcsolatos kérdések",
        title: "Profillal kapcsolatos kérdések",
        service: ["light", "active", "plus"],
        parent: "",
      },
      {
        value: "Szülői felügyelet",
        title: "Szülői felügyelet",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Szülői felügyelet PIN kóddal kapcsolatos kérdések",
        title: "Szülői felügyelet PIN kóddal kapcsolatos kérdések",
        service: ["light", "active", "plus"],
        parent: "Szolgáltatáshoz kapcsolható eszközök",
      },
      {
        value: "Navigációs kérdések",
        title: "Navigációs kérdések",
        service: ["light", "active", "plus"],
        parent: "Navigációs kérdések",
      },
      {
        value: "Hogyan juthatok hozzá kódhoz?",
        title: "Hogyan juthatok hozzá kódhoz?",
        service: ["active", "plus"],
        parent: "Kóddal kapcsolatos kérdés",
      },
      {
        value: "Kód aktiválási probléma",
        title: "Kód aktiválási probléma",
        service: ["active", "plus"],
        parent: "Kóddal kapcsolatos kérdés",
      },
      {
        value: "Műsorokkal és filmekkel kapcsolatos kérdések",
        title: "Műsorokkal és filmekkel kapcsolatos kérdések",
        service: ["light", "active", "plus", "hu", "tv"],
        parent: "Műsorokkal és filmekkel kapcsolatos kérdések",
      },
      {
        value: "Reklámokkal kapcsolatos kérdések",
        title: "Reklámokkal kapcsolatos kérdések",
        service: ["light", "active", "plus", "hu", "tv"],
        parent: "Reklámokkal kapcsolatos kérdések",
      },
      {
        value: "Fiók létrehozás, összepárosítás",
        title: "Fiók létrehozás, összepárosítás",
        service: ["active", "plus"],
        parent: "Fiók létrehozás, összepárosítás",
      },
      {
        value: "Fizetési probléma",
        title: "Fizetési probléma",
        service: ["plus"],
        parent: "Fizetési probléma",
      },
      {
        value: "Ingyenes próbaidőszak",
        title: "Ingyenes próbaidőszak",
        service: ["plus"],
        parent: "Ingyenes próbaidőszak",
      },
      {
        value: "Egyéb",
        title: "Egyéb",
        service: ["plus", "active"],
        parent: "Egyéb",
      },
      {
        value: "Új jelszó igénylés",
        title: "Új jelszó igénylés",
        service: ["light", "active", "plus", "hu"],
        parent: "Jelszó hiba",
      },
      {
        value: "Egyéb",
        title: "Egyéb",
        service: ["light", "active", "plus", "hu"],
        parent: "Egyéb",
      },
      {
        value: "Felhasználói adat módosulás",
        title: "Felhasználói adat módosulás",
        service: ["light", "active", "plus"],
        parent: "Felhasználói adat módosulás",
      },
      {
        value: "Előfizetés",
        title: "Előfizetés",
        service: ["plus"],
        parent: "Előfizetés",
      },
      {
        value: "Számlázás",
        title: "Számlázás",
        service: ["plus"],
        parent: "Számlázás",
      },
      {
        value: "Lemondás",
        title: "Lemondás",
        service: ["plus"],
        parent: "Lemondás",
      },
      {
        value: "Módosítás",
        title: "Módosítás",
        service: ["plus"],
        parent: "Módosítás",
      },
      {
        value: "Casting",
        title: "Casting",
        service: ["tv"],
        parent: "Casting",
      },
      {
        value: "Szereplővel kapcsolatos kérdések",
        title: "Szereplővel kapcsolatos kérdések",
        service: ["tv"],
        parent: "Szereplővel kapcsolatos kérdések",
      },
      {
        value: "Bejelentkezési probléma",
        title: "Bejelentkezési probléma",
        service: ["light", "active", "plus", "hu"],
        parent: "Bejelentkezési probléma",
      },
      {
        value: "Streaming probléma",
        title: "Streaming probléma",
        service: ["light", "active", "plus", "hu"],
        parent: "Streaming probléma",
      },
      {
        value: "Adás probléma",
        title: "Adás probléma",
        service: ["tv"],
        parent: "Adás probléma",
      },
      {
        value: "Nem elérhető tartalom",
        title: "Nem elérhető tartalom",
        service: ["light", "active", "plus", "hu"],
        parent: "Nem elérhető tartalom",
      },
      {
        value: "Külföldről regisztrálás",
        title: "Külföldről regisztrálás",
        service: ["light", "active", "plus"],
        parent: "Külföldről regisztrálás",
      },
    ],
  },
};

export const testInputs: FormInputs = [
  {
    type: "input",
    title: "Eszköz típusa:",
    mandatory: [
      "Eszközökkel kapcsolatos probléma",
      "Okos TV",
      "Profillal kapcsolatos kérdések",
    ],
    attrs: {
      id: "00N8E00000DsPnC",
      maxLength: "255",
      name: "00N8E00000DsPnC",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Szoftver verziószáma:",
    mandatory: [
      "Eszközökkel kapcsolatos probléma",
      "Bejelentkezési probléma",
      "Streaming probléma",
    ],
    attrs: {
      id: "00N8E00000DsPnQ",
      maxLength: "255",
      name: "00N8E00000DsPnQ",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Applikáció verziója:",
    mandatory: ["Eszközökkel kapcsolatos probléma"],
    attrs: {
      id: "00N8E00000DsPn8",
      maxLength: "255",
      name: "00N8E00000DsPn8",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Kapcsolódó eszközök száma:",
    mandatory: ["Hány eszközön használható a szolgáltatás?"],
    attrs: {
      id: "00N8E00000DsPnG",
      maxLength: "255",
      name: "00N8E00000DsPnG",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Milyen eszközön nézi:",
    mandatory: ["Felbontás"],
    optional: ["Navigációs kérdések", "Bejelentkezési probléma", "Streaming probléma", "Nem elérhető tartalom"],
    attrs: {
      id: "00N8E00000DsPnJ",
      maxLength: "255",
      name: "00N8E00000DsPnJ",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Milyen applikáció:",
    mandatory: ["Letöltés"],
    attrs: {
      id: "00N8E00000DsPnI",
      maxLength: "255",
      name: "00N8E00000DsPnI",
      type: "text",
    },
  },
  {
    type: "input",
    title: "TV előfizetés megnevezése:",
    mandatory: ["Hogyan juthatok hozzá kódhoz?"],
    attrs: {
      id: "00N8E00000DsPnR",
      maxLength: "255",
      name: "00N8E00000DsPnR",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Műsor címe:",
    mandatory: [
      "Műsorokkal és filmekkel kapcsolatos kérdések",
      "Casting",
      "Szereplővel kapcsolatos kérdések",
      "Nem elérhető tartalom",
    ],
    attrs: {
      id: "00N8E00000DsPnL",
      maxLength: "255",
      name: "00N8E00000DsPnL",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Évad száma:",
    mandatory: [],
    optional: ["Műsorokkal és filmekkel kapcsolatos kérdések", "Szereplővel kapcsolatos kérdések", "Nem elérhető tartalom"],
    attrs: {
      id: "00N8E00000DsPnD",
      maxLength: "255",
      name: "00N8E00000DsPnD",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Epizód száma:",
    mandatory: [],
    optional: ["Műsorokkal és filmekkel kapcsolatos kérdések", "Szereplővel kapcsolatos kérdések", "Nem elérhető tartalom"],
    attrs: {
      id: "00N8E00000DsPnB",
      maxLength: "255",
      name: "00N8E00000DsPnB",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Melyik műsorban volt?:",
    mandatory: ["Reklámokkal kapcsolatos kérdések"],
    attrs: {
      id: "00N8E00000DsPnH",
      maxLength: "255",
      name: "00N8E00000DsPnH",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Használni kívánt eszköz típus:",
    mandatory: ["Fiók létrehozás, összepárosítás"],
    attrs: {
      id: "00N8E00000DsPnE",
      maxLength: "255",
      name: "00N8E00000DsPnE",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Módosítandó adat kategóriája:",
    mandatory: ["Felhasználói adat módosulás"],
    attrs: {
      id: "00N8E00000DsPnK",
      maxLength: "255",
      name: "00N8E00000DsPnK",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Csatorna megnevezése:",
    mandatory: ["Adás probléma"],
    attrs: {
      id: "00N8E00000DsPnA",
      maxLength: "255",
      name: "00N8E00000DsPnA",
      type: "text",
    },
  },
  {
    type: "input",
    title: "Ország megnevezése:",
    mandatory: [],
    attrs: {
      id: "00N8E00000DsPnN",
      maxLength: "255",
      name: "00N8E00000DsPnN",
      type: "text",
    }
  },
  {
    type: "input",
    title: "Tárgy",
    mandatory: ['*'],
    attrs: {
      id: "subject",
      maxLength: "80",
      name: "subject",
      type: "text",
    }
  },
  {
    type: "textarea",
    title: "Kérdésed/problémád részletes leírása",
    mandatory: ['*'],
    attrs: {
      id: "description",
      maxLength: "32000",
      name: "description",
      type: "text"
    }
  }
];
