import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "celebrity-card__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createBlock(_component_nav_link, {
    to: _ctx.url,
    class: _normalizeClass(["celebrity-card", {
      'celebrity-card--faded': _ctx.showFadeout,
      'celebrity-card--lined': _ctx.lined,
      'celebrity-card--small': _ctx.small
    }])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Image, {
        class: "celebrity-card__image",
        "image-url": _ctx.imageUrl,
        alt: _ctx.title,
        loading: "lazy"
      }, null, 8, ["image-url", "alt"]),
      (_ctx.showTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}