"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminTagListDataToJSONTyped = exports.AdminTagListDataToJSON = exports.AdminTagListDataFromJSONTyped = exports.AdminTagListDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminTagListDataFromJSON(json) {
    return AdminTagListDataFromJSONTyped(json, false);
}
exports.AdminTagListDataFromJSON = AdminTagListDataFromJSON;
function AdminTagListDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.TagFromJSON)),
        'payload': !(0, runtime_1.exists)(json, 'payload') ? undefined : (0, _1.AdminTagListDataPayloadFromJSON)(json['payload']),
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
    };
}
exports.AdminTagListDataFromJSONTyped = AdminTagListDataFromJSONTyped;
function AdminTagListDataToJSON(value) {
    return AdminTagListDataToJSONTyped(value, false);
}
exports.AdminTagListDataToJSON = AdminTagListDataToJSON;
function AdminTagListDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': value.items === undefined ? undefined : (value.items.map(_1.TagToJSON)),
        'payload': (0, _1.AdminTagListDataPayloadToJSON)(value.payload),
        'totalCount': value.totalCount,
    };
}
exports.AdminTagListDataToJSONTyped = AdminTagListDataToJSONTyped;
