import { GetAuthorBySlugRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export { AuthorResult } from '@rtl/api'

export type AuthorPayload = ApiPayload<GetAuthorBySlugRequest>

export interface AuthorState {}

export enum AUTHOR_ACTION {
  ITEM = 'fetchAuthor'
}

export enum AUTHOR_GETTER {
  API = 'api'
}

export const AUTHOR_NAMESPACE = 'author'
