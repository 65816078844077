"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminRelatedPagesToJSONTyped = exports.AdminRelatedPagesToJSON = exports.AdminRelatedPagesFromJSONTyped = exports.AdminRelatedPagesFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdminRelatedPagesFromJSON(json) {
    return AdminRelatedPagesFromJSONTyped(json, false);
}
exports.AdminRelatedPagesFromJSON = AdminRelatedPagesFromJSON;
function AdminRelatedPagesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'siteId': !(0, runtime_1.exists)(json, 'siteId') ? undefined : json['siteId'],
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'layoutId': !(0, runtime_1.exists)(json, 'layoutId') ? undefined : json['layoutId'],
        'urlFragment': !(0, runtime_1.exists)(json, 'urlFragment') ? undefined : json['urlFragment'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'label': json['label'],
        'sitemap': !(0, runtime_1.exists)(json, 'sitemap') ? undefined : json['sitemap'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'isConnectedWithLayout': !(0, runtime_1.exists)(json, 'isConnectedWithLayout') ? undefined : json['isConnectedWithLayout'],
    };
}
exports.AdminRelatedPagesFromJSONTyped = AdminRelatedPagesFromJSONTyped;
function AdminRelatedPagesToJSON(value) {
    return AdminRelatedPagesToJSONTyped(value, false);
}
exports.AdminRelatedPagesToJSON = AdminRelatedPagesToJSON;
function AdminRelatedPagesToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'siteId': value.siteId,
        'folderId': value.folderId,
        'layoutId': value.layoutId,
        'urlFragment': value.urlFragment,
        'url': value.url,
        'label': value.label,
        'sitemap': value.sitemap,
        'active': value.active,
        'isConnectedWithLayout': value.isConnectedWithLayout,
    };
}
exports.AdminRelatedPagesToJSONTyped = AdminRelatedPagesToJSONTyped;
