import { Options, Prop, Vue } from 'vue-property-decorator'
import { ContentData } from '@rtl/api'
import { ContentDecorator } from '../../store'

@Options({
  name: 'ContentDecoratorMixin'
})
export default class ContentDecoratorMixin extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly decorators!: Record<string, ContentDecorator>

  decorateContent (content: ContentData) {
    const decorator = this.decorators[content.id] || {}
    const { alternativeTitle, alternativeLead, ...cardProps } = decorator

    return {
      ...cardProps,
      leadCropping: !alternativeLead,
      content: {
        ...content,
        alternativeTitle: alternativeTitle ?? content.alternativeTitle,
        alternativeLead: alternativeLead ?? content.alternativeLead
      }
    }
  }
}
