
import { ContentData } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentDecoratorMixin from '../../mixins/ContentDecoratorMixin'
import BannerWidget from '../../widgets/BannerWidget.vue'
import ContentCard from '../content/ContentCard.vue'
import Image from '../Image.vue'
import WidgetBlock from '../widget/WidgetBlock.vue'

@Options({
  name: 'TopNewsWidgetBlock',
  components: {
    BannerWidget,
    WidgetBlock,
    ContentCard,
    Image
  }
})
export default class TopNewsWidgetBlock extends mixins(ContentDecoratorMixin) {
  @Prop({ type: Object })
  readonly bigContent!: ContentData | undefined

  @Prop({ type: Array, default: [] })
  readonly secondColContents!: Array<ContentData>

  @Prop({ type: Array, default: [] })
  readonly thirdColContents!: Array<ContentData>

  @Prop({ type: String, default: '' })
  readonly backgroundUrl!: string

  @Prop({ type: String, default: '' })
  readonly bannerZone!: string
}
