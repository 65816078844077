import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_mix_widget_block = _resolveComponent("content-mix-widget-block")!

  return (_ctx.firstContentList?.items.length && _ctx.secondContentList?.items.length && _ctx.thirdContentList?.items.length)
    ? (_openBlock(), _createBlock(_component_content_mix_widget_block, {
        key: 0,
        "first-contents": _ctx.firstContentList?.items,
        "first-title": _ctx.firstTitle,
        "first-btn-label": _ctx.firstBtnLabel,
        "first-btn-url": _ctx.firstBtnUrl,
        "second-contents": _ctx.secondContentList?.items,
        "second-title": _ctx.secondTitle,
        "second-btn-label": _ctx.secondBtnLabel,
        "second-btn-url": _ctx.secondBtnUrl,
        "third-contents": _ctx.thirdContentList?.items,
        "third-title": _ctx.thirdTitle,
        "third-btn-label": _ctx.thirdBtnLabel,
        "third-btn-url": _ctx.thirdBtnUrl
      }, null, 8, ["first-contents", "first-title", "first-btn-label", "first-btn-url", "second-contents", "second-title", "second-btn-label", "second-btn-url", "third-contents", "third-title", "third-btn-label", "third-btn-url"]))
    : _createCommentVNode("", true)
}