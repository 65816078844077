
import { ContextType } from '@rtl/ssr'
import {
  AdblockConfirm,
  AdStore,
  DynamicLayout,
  ErrorPage,
  EVENT_ACTION, EVENT_NAMESPACE, EventPayload, EventResult, FetchPageMixin, getMedia,
  HttpError, isExternalUrl, MEASUREMENT_ACTION, MEASUREMENT_NAMESPACE,
  MeasurementLogAdoPayload, MeasurementResult, PageResult,
  Storage
} from '@rtl/ui'
import { ssrContextKey } from 'vue'
import { Inject, mixins, Options, Provide, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let UA: Promise<any> | undefined

@Options({
  name: 'App',
  components: {
    AdblockConfirm,
    DynamicLayout,
    ErrorPage
  }
})
export default class App extends mixins(FetchPageMixin) {
  @State('adoScriptLoaded', { namespace: MEASUREMENT_NAMESPACE })
  adoScriptLoaded!: boolean;

  @Action(EVENT_ACTION.POST, { namespace: EVENT_NAMESPACE })
  storePostEvent!: (payload: EventPayload) => Promise<EventResult>

  @Action(MEASUREMENT_ACTION.LOG_ADO, { namespace: MEASUREMENT_NAMESPACE })
  logAdo!: (payload: MeasurementLogAdoPayload) => Promise<MeasurementResult | undefined>

  @Inject({ from: ssrContextKey })
  readonly ssrContext!: ContextType

  @Provide({ reactive: true })
  adStore: AdStore = {
    adoKeys: null,
    initialized: false,
    master: null,
    slaves: [],
    resetting: false
  }

  error: unknown | null = null
  layout?: string | null = null
  mock = false

  @Watch('$route.fullPath', { immediate: true })
  updatePath () {
    this.$store.commit('setPath', this.$route.fullPath)
  }

  errorCaptured (error: unknown) {
    if (this.onError(error) === false) {
      this.error = error
      return false
    }
  }

  @Watch('pageResult.status', { immediate: true })
  handlePageError () {
    if (this.pageResult?.result?.status && this.pageResult?.result?.status >= 400) {
      this.error = new HttpError(this.pageResult.result.status)
    }
  }

  @Watch('$route.fullPath')
  resetError () {
    this.error = null
    this.logAdo({
      referer: this.$routeReferer?.fullPath ?? '',
      url: window.location.href
    })
  }

  @Watch('adoScriptLoaded')
  updateAdoScriptLoaded () {
    this.logAdo({
      referer: window.document.referrer,
      url: window.location.href
    })
  }

  onError (error: unknown) {
    if (error instanceof HttpError) {
      if (this.ssrContext) {
        this.ssrContext.statusCode = error.statusCode
      }
      this.error = error
      return false
    }
  }

  async onFetchPage (req: Promise<PageResult>) {
    let result

    try {
      result = await req
      if (result.status && result.status >= 400) {
        throw new HttpError(result.status)
      }
    } catch (error) {
      if (this.onError(error) !== false) {
        throw error
      }
    }

    if (result) {
      if (this.ssrContext) {
        if (result.status) {
          this.ssrContext.statusCode = result.status
        }
        if (result.cache) {
          this.ssrContext.req.res?.append('Cache-Control', result.cache)
        }
      }

      if (result.data.redirectUrl) {
        if (isExternalUrl(result.data.redirectUrl)) {
          if (this.ssrContext) {
            this.ssrContext.redirectUrl = result.data.redirectUrl
          } else {
            window.location.href = result.data.redirectUrl
          }
        } else {
          this.$router.replace(result.data.redirectUrl)
        }
      } else if (!this.ssrContext) {
        if (this.$gtm && this.$gtm.enabled() && result.data.trackingVariables?.trackingEnabled) {
          this.$gtm.trackEvent({
            event: 'content-view',
            ...result.data.trackingVariables,
            ...result.data.trackingVariables?.pubDate !== undefined
              ? {
                  pubDate: result.data.trackingVariables.pubDate.split(' ')[0],
                  pubDateTime: result.data.trackingVariables.pubDate
                }
              : null,
            status: result.status.toString(),
            device: getMedia(window)
          })
        }
        if (result.data.contentId) {
          try {
            await this.storePostEvent({ contentId: result.data.contentId })
          } catch (error) {
            console.warn(error) // eslint-disable-line no-console
          }
        }
      }
    }
  }

  @Watch('page.adoKeys', { immediate: true, deep: true })
  refreshAdoKeys () {
    this.adStore.adoKeys = this.page?.adoKeys
  }

  async mounted () {
    if ('feat[bannerzones]' in this.$route.query) {
      Storage.set('features', { ...Storage.get('features'), bannerzones: this.$route.query['feat[bannerzones]'] === 'true' })
    }

    this.mock = !!(Storage.get('features')?.bannerzones ?? this.mock)

    if ('UA' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      UA?.then(async (sdk: any) => {
        const log = console.log // eslint-disable-line no-console

        log('==== AirShip Web SDK loaded.')

        if (sdk.isSupported && sdk.canRegister) {
          // Service worker Javascript registration code
          // Will trigger a pop-up
          const result = await sdk.register()
          log('=== User registered successfully: %s', result.channel_id)
        }

        const { id, optedIn } = await sdk.getChannel()
        log('=== [%s] - Channel ID: %s', optedIn ? 'Feliratkozva' : 'Nincs Feliratkozva', id)

        sdk.addEventListener('push', (ev: Event) => {
          // ev.push is the push payload object
          log('==== Browser receives a push: ', ev)
        })
      })
    }
  }
}
