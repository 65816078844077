import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "celebrity-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CelebrityCard = _resolveComponent("CelebrityCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "data-slider-pane": "",
      class: _normalizeClass(["celebrity-list__row", { 'celebrity-list__row--slider': _ctx.showSlider }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["celebrity-list__col", {
          'celebrity-list__col--2': _ctx.cols === 2,
          'celebrity-list__col--3': _ctx.cols === 3,
          'celebrity-list__col--4': _ctx.cols === 4,
          'celebrity-list__col--5': _ctx.cols === 5,
          'celebrity-list__col--6': _ctx.cols >= 6,
        }])
        }, [
          _createVNode(_component_CelebrityCard, {
            content: content,
            lined: _ctx.lined,
            small: _ctx.small,
            "show-title": _ctx.showTitle,
            "show-fadeout": _ctx.showFadeout
          }, null, 8, ["content", "lined", "small", "show-title", "show-fadeout"])
        ], 2))
      }), 128))
    ], 2)
  ]))
}