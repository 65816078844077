
import { mixins, Options } from 'vue-property-decorator'

import TvShowCardMixin from '../../mixins/TvShowCardMixin'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'

@Options({
  name: 'TvShowLogoCard',
  components: {
    Image,
    NavLink
  }
})
export default class TvShowLogoCard extends mixins(TvShowCardMixin) {
  get imageUrl (): string | null {
    const params = this.tvShow.parameters

    if (params && params.design.tvshowImageUrls) {
      const imageUrls = params.design.tvshowImageUrls as unknown as { 'tvshowImageUrl': string }
      return imageUrls.tvshowImageUrl
    }

    return null
  }
}
