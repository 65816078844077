import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_push_notification = _resolveComponent("push-notification")!
  const _component_banner_widget = _resolveComponent("banner-widget")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_extra_insert = _resolveComponent("extra-insert")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_facebook_modal = _resolveComponent("facebook-modal")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_push_notification, { class: "header__push-notification" }),
    (_ctx.page?.responseType !== 'news')
      ? (_openBlock(), _createBlock(_component_banner_widget, {
          key: 0,
          class: "layout-banner",
          zone: { tablet: 't_leaderboard_1', mobile: 'm_leaderboard_1', app: 'app_leaderboard_1' },
          lazy: false,
          "show-background": false,
          "label-position": "left"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_main_header, {
      class: _normalizeClass({ 'main-header--loading': _ctx.logoLoading })
    }, null, 8, ["class"]),
    (_ctx.page?.responseType !== 'news')
      ? (_openBlock(), _createBlock(_component_banner_widget, {
          key: 1,
          zone: { desktop: 'leaderboard_1' },
          lazy: false,
          "label-position": "left"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_1, [
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_extra_insert, { url: _ctx.extraHeaderUrl }, null, 8, ["url"]),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_router_view)
      ])
    ]),
    _createVNode(_component_facebook_modal),
    _createVNode(_component_main_footer),
    _createVNode(_component_banner_widget, {
      class: "layout-banner",
      zone: { desktop: 'special', tablet: 't_special' },
      lazy: false,
      "label-position": "",
      "show-background": false,
      "show-placeholder": false
    }),
    _createVNode(_component_banner_widget, {
      class: "layout-banner",
      zone: "loading_page",
      lazy: false,
      "label-position": "",
      "show-background": false,
      "show-placeholder": false
    })
  ], 64))
}