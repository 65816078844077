import { Module } from 'vuex'
import { PageApi } from '@rtl/api'
import { PAGE_GETTER, PAGE_ACTION, PageState, PageResult, PagePayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createPageModule<RootState> (namespaced = false): Module<PageState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [PAGE_GETTER.API] (state, getters, rootState, rootGetters) {
        return new PageApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [PAGE_ACTION.ITEM] ({ getters, rootGetters }, payload: PagePayload): Promise<PageResult> {
        const response = await errorHandler(async (): Promise<PageResult> => {
          const rawResponse = await getters[PAGE_GETTER.API].getPageByUrlRaw({
            ...rootGetters[API_GETTER.PARAMS],
            hostname: 'rtl.hu',
            ...payload
          })
          return {
            ...await rawResponse.value(),
            cache: rawResponse.raw.headers.get('cache-control')
          }
        })

        return response
      }
    }
  }
}
