import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pagination"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slider_left = _resolveComponent("slider-left")!
  const _component_nav_link = _resolveComponent("nav-link")!
  const _component_slider_right = _resolveComponent("slider-right")!

  return (_ctx.maxPage > 1)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        _createElementVNode("li", {
          class: _normalizeClass(["pagination__item pagination__item--prev", { 'pagination__item--disabled': _ctx.prevItem.disabled }])
        }, [
          _createVNode(_component_nav_link, {
            to: { query: _ctx.getQueryParams(_ctx.prevItem.page) },
            class: "pagination__link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_slider_left)
            ]),
            _: 1
          }, 8, ["to"])
        ], 2),
        _createElementVNode("li", null, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageItems, (pageItem, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: _normalizeClass(["pagination__item pagination__item--page", {
            'pagination__item--page': pageItem.page,
            'pagination__item--dots': !pageItem.page,
            'pagination__item--active': pageItem.active,
            'pagination__item--hidden': pageItem.hidden,
          }])
              }, [
                (pageItem.page)
                  ? (_openBlock(), _createBlock(_component_nav_link, {
                      key: 0,
                      to: { query: _ctx.getQueryParams(pageItem.page) },
                      class: "pagination__link"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(pageItem.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(pageItem.label), 1))
              ], 2))
            }), 128))
          ])
        ]),
        _createElementVNode("li", {
          class: _normalizeClass(["pagination__item pagination__item--next", { 'pagination__item--disabled': _ctx.nextItem.disabled }])
        }, [
          _createVNode(_component_nav_link, {
            to: { query: _ctx.getQueryParams(_ctx.nextItem.page) },
            class: "pagination__link"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_slider_right)
            ]),
            _: 1
          }, 8, ["to"])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}