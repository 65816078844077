
import { Options, Prop, Ref, Vue } from 'vue-property-decorator'

import Exclamation from '../icons/Exclamation.vue'
import Eye from '../icons/Eye.vue'

@Options({
  name: 'PasswordInput',
  components: {
    Exclamation,
    Eye
  }
})
export default class PasswordInput extends Vue {
  @Prop({ type: String, default: '' })
  readonly modelValue!: string

  @Prop({ type: String, required: true })
  readonly label!: string

  @Prop({ type: String, default: null })
  readonly errorMsg!: string | null

  @Ref('passwordField')
  passwordField!: HTMLInputElement

  togglePassword (): void {
    this.passwordField.type = this.passwordField.type === 'password' ? 'text' : 'password'
  }
}
