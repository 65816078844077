import { GetTagBySlugRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export { TagResult } from '@rtl/api'

export type TagPayload = ApiPayload<GetTagBySlugRequest>

export interface TagState {}

export enum TAG_ACTION {
  ITEM = 'fetchTag'
}

export enum TAG_GETTER {
  API = 'api'
}

export const TAG_NAMESPACE = 'tag'
