import { AdoKeysMedia } from '@rtl/api'

import { copyToClipboard } from './dom.util'

export function shuffle <T> (src: Array<T>): Array<T> {
  const items: Array<T> = [...src]
  let currentIndex = items.length

  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    [items[currentIndex], items[randomIndex]] = [items[randomIndex], items[currentIndex]]
  }

  return items
}

export enum MediaEnum {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
  APP = 'app'
}

export type AdStore = {
  media?: MediaEnum,
  adoKeys?: { [key: string]: AdoKeysMedia } | null,
  initialized: boolean,
  master?: string | null,
  slaves: Array<string>,
  resetting: boolean
}

export function getMedia (win?: Window): MediaEnum {
  const map = [
    { query: '(max-width: 768px)', type: MediaEnum.MOBILE },
    { query: '(max-width: 1200px)', type: MediaEnum.TABLET }
  ]

  return map.find(m => (win || window).matchMedia(m.query).matches)?.type ||
    MediaEnum.DESKTOP
}

export function stripTags (text: string | undefined): string | undefined {
  return (text || '').replace(/<[^>]*>?/gm, '').trim() || undefined
}

export class CopyHelper {
  static DEFAULT_TIMEOUT = 1300

  static create (timeout = this.DEFAULT_TIMEOUT) {
    return new CopyHelper(timeout)
  }

  isCopied: number | null = null;
  timeout = CopyHelper.DEFAULT_TIMEOUT;

  constructor (timeout = CopyHelper.DEFAULT_TIMEOUT) {
    this.timeout = timeout
  }

  async copy (value: string): Promise<void> {
    await copyToClipboard(value)

    this.isCopied = setTimeout(() => {
      if (this.isCopied) {
        clearTimeout(this.isCopied)
        this.isCopied = null
      }
    }, this.timeout) as unknown as number
  }
}
