export enum Browser {
  SAFARI = "Safari",
  IOS_WEBVIEW = "iOS webview",
  ANDROID_WEBVIEW = "Android webview",
  CHROME = "Chrome",
}

export enum Platform {
  IOS = "ios",
  ANDROID = "android",
  WEB = "web",
}

export const androidTypes = [
  /Android/i,
  /webOS/i,
  /BlackBerry/i,
  /Windows Phone/i
]

export const iosTypes = [
  /iPhone/i,
  /iPad/i,
  /iPod/i
]

export function browser() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (safari) {
      return Browser.SAFARI;
    } else if (!safari) {
      return Browser.IOS_WEBVIEW;
    }
  } else {
    if (userAgent.includes("wv")) {
      return Browser.ANDROID_WEBVIEW;
    } else {
      return Browser.CHROME;
    }
  }
}

// ios app / andorid app / browser (mobile or desktop)
export function platform() {
  switch (browser()) {
    case Browser.IOS_WEBVIEW:
      return Platform.IOS;
    case Browser.ANDROID_WEBVIEW:
      return Platform.ANDROID;
    default:
      return Platform.WEB;
  }
}

// ios device / andorid device / desktop
export function detectDeviceType () {
  if (typeof navigator === 'undefined') {
    return 'web' // Possibly NodeJs SSR
  }
  const isAndroid = androidTypes.some((androidType) => navigator.userAgent.match(androidType))
  const isIos = iosTypes.some((iosType) => navigator.userAgent.match(iosType))

  return isAndroid ? 'android' : isIos ? 'ios' : 'web'
}

export function isMobile (): boolean {
  return !(detectDeviceType() === 'web')
}