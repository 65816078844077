"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class FormApi extends runtime.BaseAPI {
    /**
     * Get a CastingForm data
     */
    async getCastingFormRaw(requestParameters, initOverrides) {
        if (requestParameters.formCode === null || requestParameters.formCode === undefined) {
            throw new runtime.RequiredError('formCode', 'Required parameter requestParameters.formCode was null or undefined when calling getCastingForm.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/casting-form/{formCode}`.replace(`{${"formCode"}}`, encodeURIComponent(String(requestParameters.formCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CastingFormResultFromJSON)(jsonValue));
    }
    /**
     * Get a CastingForm data
     */
    async getCastingForm(requestParameters, initOverrides) {
        const response = await this.getCastingFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * List available casting form groups
     */
    async listCastingFormGroupsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/casting-form/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CastingFormGroupsListResultFromJSON)(jsonValue));
    }
    /**
     * List available casting form groups
     */
    async listCastingFormGroups(requestParameters, initOverrides) {
        const response = await this.listCastingFormGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * List available Casting Forms
     */
    async listCastingFormsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/casting-form`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CastingFormListResultFromJSON)(jsonValue));
    }
    /**
     * List available Casting Forms
     */
    async listCastingForms(requestParameters, initOverrides) {
        const response = await this.listCastingFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Post a CastingForm data
     */
    async postCastingFormRaw(requestParameters, initOverrides) {
        if (requestParameters.formCode === null || requestParameters.formCode === undefined) {
            throw new runtime.RequiredError('formCode', 'Required parameter requestParameters.formCode was null or undefined when calling postCastingForm.');
        }
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling postCastingForm.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.formCode !== undefined) {
            formParams.append('formCode', requestParameters.formCode);
        }
        if (requestParameters.data !== undefined) {
            formParams.append('data', requestParameters.data);
        }
        if (requestParameters.upload !== undefined) {
            formParams.append('upload', requestParameters.upload);
        }
        if (requestParameters.upload1 !== undefined) {
            formParams.append('upload1', requestParameters.upload1);
        }
        if (requestParameters.upload2 !== undefined) {
            formParams.append('upload2', requestParameters.upload2);
        }
        if (requestParameters.upload3 !== undefined) {
            formParams.append('upload3', requestParameters.upload3);
        }
        if (requestParameters.upload4 !== undefined) {
            formParams.append('upload4', requestParameters.upload4);
        }
        if (requestParameters.upload5 !== undefined) {
            formParams.append('upload5', requestParameters.upload5);
        }
        const response = await this.request({
            path: `/casting-form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CastingFormResultFromJSON)(jsonValue));
    }
    /**
     * Post a CastingForm data
     */
    async postCastingForm(requestParameters, initOverrides) {
        const response = await this.postCastingFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Post UploadForm data
     */
    async postUploadFormDataRaw(requestParameters, initOverrides) {
        if (requestParameters.uploadForm === null || requestParameters.uploadForm === undefined) {
            throw new runtime.RequiredError('uploadForm', 'Required parameter requestParameters.uploadForm was null or undefined when calling postUploadFormData.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.uploadForm !== undefined) {
            formParams.append('UploadForm', new Blob([JSON.stringify((0, models_1.UploadFormDataToJSON)(requestParameters.uploadForm))], { type: "application/json", }));
        }
        if (requestParameters.siteId !== undefined) {
            formParams.append('siteId', requestParameters.siteId);
        }
        const response = await this.request({
            path: `/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.UploadFormResultFromJSON)(jsonValue));
    }
    /**
     * Post UploadForm data
     */
    async postUploadFormData(requestParameters, initOverrides) {
        const response = await this.postUploadFormDataRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.FormApi = FormApi;
