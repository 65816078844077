import {
  AccountRequest,
  AccountUpdateRequest,
  DeleteAccountRequest,
  FinalizeRegistrationRequest,
  GetJWTResponse,
  Gigya,
  IsAvailableRequst,
  LinkAccountRequest,
  LoginError,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  Response,
  SocialRequest,
  UserData,
  UserInfoRequest,
  UserResponse,
  VerificationRequest
} from '../../../gigya'
import { ActionContext, Module } from 'vuex'
import { USER_ACTION, USER_GETTER, USER_MUTATION, UserState } from './types'

import { ENV_GETTER } from '../env'

export interface PersistTokenRequest {
  token: string
  type: 'lToken' | 'rToken'
}

export function createUserModule<RootState> (namespaced = false): Module<UserState, RootState> {
  return {
    namespaced,
    state () {
      return {
        user: null
      }
    },
    getters: {
      [USER_GETTER.API] (state, getters, rootState, rootGetters) {
        const dataCenter = rootGetters[ENV_GETTER.ENV]?.('GIGYA_DATA_CENTER') || 'eu1'
        const apiKey = rootGetters[ENV_GETTER.ENV]?.('GIGYA_API_KEY') || ''

        return new Gigya(dataCenter, apiKey)
      },
      [USER_GETTER.USER] (state): UserData | null {
        return state.user
      }
    },
    mutations: {
      [USER_MUTATION.USER] (state: UserState, payload: UserData | null) {
        state.user = payload
      }
    },
    actions: {
      async [USER_ACTION.LOGIN] ({ getters, commit }: ActionContext<UserState, RootState>, payload: LoginRequest): Promise<UserResponse|LoginError> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.login(payload)
        /*if (payload.context == 'fiok') {
          if (response.data.RTLHU_Consent === false && response.data.RTLMOST_Consent === false) {
            throw new LoginError()
          }
        }*/
        const expireSeconds = payload.sessionExpiration || 0
        const expire = expireSeconds ? new Date(Date.now() + (expireSeconds * 1000)).getTime() : 0
        delete response.profile.age
        const userData = await userApi.setUser({
          UID: response.UID,
          profile: response.profile,
          data: response.data,
          sessionExpiration: expire
        })
        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.LOGOUT] ({ getters, commit }: ActionContext<UserState, RootState>): Promise<Response> {
        const userApi = getters[USER_GETTER.API]

        const response = await userApi.accounts.logout({ UID: getters[USER_GETTER.USER]?.UID || '' })
        commit(USER_MUTATION.USER, await userApi.setUser(null))

        return response
      },
      async [USER_ACTION.REGISTER] ({ getters, commit }: ActionContext<UserState, RootState>, payload: RegisterRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]

        if (payload?.data?.RTLHU_Consent) {
          payload.data.RTLHU_Consent_created_at = new Date()
        }
        if (payload?.data?.RTLHU_Newsletter) {
          payload.data.RTLHU_Newsletter_created_at = new Date()
        }

        await userApi.accounts.initRegistration()
        const response = await userApi.accounts.register({ finalizeRegistration: true, ...payload })

        const userData = await userApi.setUser({
          UID: response.UID,
          profile: response.profile,
          data: response.data,
          sessionExpiration: 0
        })
        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.RESET_PASSWORD] ({ state, getters, commit }: ActionContext<UserState, RootState>, payload: ResetPasswordRequest): Promise<ResetPasswordResponse> {
        const userApi = getters[USER_GETTER.API]

        commit(USER_MUTATION.USER, await userApi.setUser(null))

        return userApi.accounts.resetPassword(payload)
      },
      [USER_ACTION.SOCIAL] ({ getters, commit }: ActionContext<UserState, RootState>, payload: SocialRequest): String {
        const userApi = getters[USER_GETTER.API]
        const response = userApi.accounts.social(payload)

        return response
      },
      async [USER_ACTION.ACCOUNT_INFO] ({ getters, commit }: ActionContext<UserState, RootState>, payload: AccountRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.getAccountInfo(payload)
        const expire = 0
        delete response.profile.age
        const userData = await userApi.setUser({
          UID: response.UID,
          profile: response.profile,
          data: response.data,
          sessionExpiration: expire
        })
        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.USER_INFO] ({ getters, commit }: ActionContext<UserState, RootState>, payload: UserInfoRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.getUserInfo(payload)
        const userData = await userApi.setUser({
          UID: response.UID,
          profile: response.profile,
          data: response.data,
          // sessionExpiration: expire
        })
        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.UPDATE_ACCOUNT] ({ state, getters, commit }: ActionContext<UserState, RootState>, payload: AccountUpdateRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]

        if (payload?.data) {
          payload.data.RTLHU_Consent_created_at = payload.data?.RTLHU_Consent ? new Date() : null;
          payload.data.RTLHU_Newsletter_created_at = payload.data?.RTLHU_Newsletter ? new Date() : null;
        }

        const response = await userApi.accounts.updateAccount(payload)
        const userData = await userApi.setUser({
          ...state.user,
          profile: {
            ...(state?.user?.profile || {}),
            ...payload.profile
          },
          data: {
            ...(state?.user?.data || {}),
            ...payload.data
          },
          // sessionExpiration: expire
        })
        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.NOTIFY_LOGIN] ({ getters, commit }: ActionContext<UserState, RootState>, payload: UserInfoRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.notifyLogin(payload)

        return response
      },
      async [USER_ACTION.PERSIST_TOKEN] ({ getters }: ActionContext<UserState, RootState>, payload: PersistTokenRequest): Promise<void> {
        const userApi = getters[USER_GETTER.API]
        await userApi.storage.set(payload.type, payload.token)
      },
      async [USER_ACTION.JWT] ({ state, getters }: ActionContext<UserState, RootState>): Promise<GetJWTResponse> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.getJWT({ UID: state.user?.UID })

        return response
      },
      async [USER_ACTION.DELETE_ACCOUNT]({ getters, commit }: ActionContext<UserState, RootState>, payload: DeleteAccountRequest): Promise<Response> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.deleteAccount(payload)
        const userData = await userApi.setUser(null)
        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.IS_AVAILABLE]({ getters }: ActionContext<UserState, RootState>, payload: IsAvailableRequst): Promise<Response> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.isAvailableLogin(payload)

        return response
      },
      async [USER_ACTION.SEND_VERIFICATION]({ getters }: ActionContext<UserState, RootState>, payload: VerificationRequest): Promise<Response> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.sendVerification(payload)

        return response
      },
      async [USER_ACTION.LINK_ACCOUNT]({ getters, commit }: ActionContext<UserState, RootState>, payload: LinkAccountRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]
        const response = await userApi.accounts.linkAccount(payload)
        delete response.profile.age
        const userData = await userApi.setUser({
          UID: response.UID,
          profile: response.profile,
          data: response.data,
        })

        commit(USER_MUTATION.USER, userData)

        return response
      },
      async [USER_ACTION.FINALIZE_REGISTRATION]({ getters, commit }: ActionContext<UserState, RootState>, payload: FinalizeRegistrationRequest): Promise<UserResponse> {
        const userApi = getters[USER_GETTER.API]
        payload.include = 'profile,data'
        const response = await userApi.accounts.finalizeRegistration(payload)
        delete response.profile.age
        const userData = await userApi.setUser({
          UID: response.UID,
          profile: response.profile,
          data: response.data,
        })

        commit(USER_MUTATION.USER, userData)

        return response
      },
      [USER_ACTION.FORCED_LOGOUT]({ getters, commit }: ActionContext<UserState, RootState>): void {
        const userApi = getters[USER_GETTER.API]
        userApi.setUser(null)
        return commit(USER_MUTATION.USER, null)
      },
    }
  }
}
