"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminContentToJSONTyped = exports.AdminContentToJSON = exports.AdminContentFromJSONTyped = exports.AdminContentFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdminContentFromJSON(json) {
    return AdminContentFromJSONTyped(json, false);
}
exports.AdminContentFromJSON = AdminContentFromJSON;
function AdminContentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idHash': !(0, runtime_1.exists)(json, 'idHash') ? undefined : json['idHash'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'siteName': !(0, runtime_1.exists)(json, 'siteName') ? undefined : json['siteName'],
        'timeFrom': !(0, runtime_1.exists)(json, 'timeFrom') ? undefined : (new Date(json['timeFrom'])),
        'timeFromRaw': !(0, runtime_1.exists)(json, 'timeFrom') ? undefined : (json['timeFrom']),
    };
}
exports.AdminContentFromJSONTyped = AdminContentFromJSONTyped;
function AdminContentToJSON(value) {
    return AdminContentToJSONTyped(value, false);
}
exports.AdminContentToJSON = AdminContentToJSON;
function AdminContentToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'idHash': value.idHash,
        'id': value.id,
        'title': value.title,
        'siteName': value.siteName,
        'timeFrom': value.timeFrom === undefined ? undefined : (value.timeFrom.toISOString()),
    };
}
exports.AdminContentToJSONTyped = AdminContentToJSONTyped;
