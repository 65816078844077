import { Options, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { SiteTypes } from '@rtl/api'
import { SITE_ACTION, SITE_NAMESPACE, SiteListPayload, SiteListResult } from '../../store'

@Options({
  name: 'FetchSiteListMixin'
})
export default class FetchSiteListMixin extends Vue {
  @Action(SITE_ACTION.LIST, { namespace: SITE_NAMESPACE })
  storeFetchSiteList!: (payload: SiteListPayload) => Promise<SiteListResult>

  get siteList (): SiteListResult['data'] | null {
    return this.$store.getters['$cache/result']([SITE_NAMESPACE, SITE_ACTION.LIST], this.siteListPayload)?.data || null
  }

  get siteListPayload (): SiteListPayload {
    return {
      type: SiteTypes.Show
    }
  }

  @Watch('siteListPayload', { deep: true })
  async fetchSiteList () {
    return this.storeFetchSiteList(this.siteListPayload)
  }

  serverPrefetch () {
    return this.fetchSiteList()
  }

  mounted () {
    return this.fetchSiteList()
  }
}
