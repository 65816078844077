import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, mergeProps as _mergeProps, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_card_list = _resolveComponent("content-card-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return _withDirectives((_openBlock(), _createBlock(_component_widget_block, _mergeProps({
    title: _ctx.title,
    "show-divider": !!_ctx.title,
    "show-slider": _ctx.showSlider && !!_ctx.contents.length,
    "btn-label": _ctx.btnLabel,
    "btn-url": _ctx.btnUrl,
    "show-button": _ctx.showButton
  }, _ctx.widgetStyleBinding), {
    default: _withCtx(() => [
      _createVNode(_component_content_card_list, {
        contents: _ctx.contents,
        decorators: _ctx.payload?.decorators,
        "show-title": _ctx.showTitle,
        "show-lead": _ctx.showLead,
        "show-release-date": _ctx.showReleaseDate,
        "show-tag": _ctx.showTag,
        "show-duration": _ctx.showDuration,
        "show-gallery-badge": _ctx.showGalleryBadge,
        "show-image": _ctx.showImage,
        "show-border": _ctx.showBorder,
        "show-slider": _ctx.showSlider,
        cols: _ctx.cols,
        size: _ctx.size,
        "card-highlighted": _ctx.cardHighlighted,
        "title-highlighted": _ctx.titleHighlighted
      }, null, 8, ["contents", "decorators", "show-title", "show-lead", "show-release-date", "show-tag", "show-duration", "show-gallery-badge", "show-image", "show-border", "show-slider", "cols", "size", "card-highlighted", "title-highlighted"])
    ]),
    _: 1
  }, 16, ["title", "show-divider", "show-slider", "btn-label", "btn-url", "show-button"])), [
    [_vShow, _ctx.contents.length]
  ])
}