
import { Options, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { LinkAccountRequest, UserResponse } from '../../gigya'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import PasswordInput from '../components/auth/PasswordInput.vue'
import TextInput from '../components/auth/TextInput.vue'
import Modal from '../components/Modal.vue'

@Options({
  name: 'SocialLinkAccount',
  components: {
    Modal,
    TextInput,
    PasswordInput
  }
})

export default class SocialLinkAccount extends Vue {
  @Prop({ type: String, default: '' })
  readonly regToken!: string

  @Prop({ type: String, default: '' })
  readonly loginID!: string

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'password_reset' })
  })
  readonly passwordUrl!: object

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'index' })
  })
  readonly successUrl!: object

  @Action(USER_ACTION.LINK_ACCOUNT, { namespace: USER_NAMESPACE })
  linkAccount!: (params: LinkAccountRequest) => Promise<UserResponse>

  linkData: LinkAccountRequest = {
    regToken: this.regToken,
    loginID: this.loginID,
    password: '',
    include: 'profile,data'
  }

  formErrors: { field: string, msg: string }[] = []

  modalIsOpen = true

  link () {
    if (this.validateForm()) {
      this.linkAccount({ password: this.linkData.password, regToken: this.regToken, loginID: this.loginID, include: this.linkData.include })
        .then(() => {
          this.modalIsOpen = false
          this.$router.push(this.successUrl)
        })
        .catch((e) => {
          if (e.errorCode === 403042) {
            this.addFormError({ field: 'error', msg: 'Helytelen email vagy jelszó!' })
          } else {
            this.addFormError({ field: 'error', msg: 'Hiba történt az összekapcsolás során. Kérjük próbáld meg újra!' })
          }
          this.scrollUp()
        })
    } else {
      this.scrollUp()
    }
  }

  scrollUp (): void {
    window?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  validatePassword (passwordValue: string, fieldName: string, checkStrength = false) {
    const value = passwordValue.trim()

    if (!value.length) {
      this.addFormError({ field: fieldName, msg: 'A jelszó kitöltése kötelező' })
      return
    }

    if (checkStrength && value.length < 8) {
      this.addFormError({ field: fieldName, msg: 'A jelszónak legalább 8 karaktert szükséges tartalmaznia' })
      return
    }

    if (checkStrength && (!value.match(/[a-zA-Z]+/u) || !value.match(/[0-9]+/u) || !value.match(/[^a-zA-Z0-9]+/u))) {
      this.addFormError({ field: fieldName, msg: 'A jelszónak legalább egy betűt, egy számot és egy speciális karaktert kell tartalmaznia' })
    }
  }

  clearFormErrors (): void {
    this.formErrors = []
  }

  hasFormError (): boolean {
    return !!this.formErrors.length
  }

  addFormError (error: { field: string, msg: string }): void {
    this.formErrors.push(error)
  }

  validateForm (): boolean {
    this.clearFormErrors()
    this.validatePassword(this.linkData.password, 'password')
    return !this.hasFormError()
  }

  hasFieldError (field: string): boolean {
    return !!this.formErrors.find(e => e.field === field)
  }

  getFieldError (field: string): { field: string, msg: string } | null {
    return this.formErrors.find(e => e.field === field) || null
  }

  getFieldErrorValue (field: string): string | null {
    return this.getFieldError(field)?.msg || null
  }
}
