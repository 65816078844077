
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'

@Options({
  name: 'ExtraInsert'
})
export default class ExtraInsert extends Vue {
  @Prop({ type: String, default: '' })
  readonly url!: string

  isMounted = false

  mounted () {
    this.isMounted = true // for lazy loading
    this.handler()
  }

  @Watch('url')
  handler () {
    if (this.url) {
      window.addEventListener('message', this.messageHandler, false)
    } else {
      window.removeEventListener('message', this.messageHandler, false)
    }
  }

  messageHandler (event: MessageEvent) {
    const heightKey = 'height:'
    const linkKey = 'link:'
    try {
      const data = typeof event.data === 'object' ? event.data : { action: event.data }
      this.$el.querySelectorAll('iframe').forEach((frame: HTMLIFrameElement) => {
        if (frame.contentWindow === event.source) {
          if (data.action?.startsWith(heightKey)) {
            const height = data.action.substring(heightKey.length)
            frame.style.height = height + 'px'
          } else if (data.action?.startsWith(linkKey)) {
            this.$router.push(data.action.substring(linkKey.length))
          }
        }
      })
    } catch (_e) {}
  }
}
