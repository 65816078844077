import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.adStore?.resetting)
      ? (_openBlock(), _createBlock(_component_banner, {
          key: 0,
          zoneId: _ctx.slaveId,
          zoneKey: _ctx.zoneKey,
          "label-position": _ctx.labelPosition,
          "show-background": _ctx.showBackground,
          "show-placeholder": !!_ctx.$root.mock
        }, null, 8, ["zoneId", "zoneKey", "label-position", "show-background", "show-placeholder"]))
      : _createCommentVNode("", true)
  ]))
}