import { Router } from 'vue-router'
import { VueGtmUseOptions } from '@gtm-support/vue-gtm'

export const getGtmConfig = (id: string, router?: Router, auth?: string): VueGtmUseOptions => {
  const queryParams = auth
    ? {
        gtm_auth: auth,
        gtm_preview: 'env-4',
        gtm_cookies_win: 'x'
      }
    : undefined

  return {
    id,
    queryParams,
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: true
  }
}
