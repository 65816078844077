import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "recommended-video-widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_recommended_video_list = _resolveComponent("recommended-video-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.videoTagList && _ctx.topNList)
      ? (_openBlock(), _createBlock(_component_widget_block, {
          key: 0,
          title: 'RTL.hu videóajánló',
          "show-divider": true,
          showSlider: false
        }, {
          default: _withCtx(() => [
            (_ctx.contents && _ctx.contents.length)
              ? (_openBlock(), _createBlock(_component_recommended_video_list, {
                  key: 0,
                  contents: _ctx.contents,
                  length: _ctx.length
                }, null, 8, ["contents", "length"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}