
import { Options, Prop, Vue } from 'vue-property-decorator'

import Close from './icons/Close.vue'

@Options({
  name: 'Modal',
  components: {
    Close
  }
})
export default class Modal extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  readonly closeable!: boolean
}
