import { Module } from 'vuex'
import { AuthorApi } from '@rtl/api'
import { AUTHOR_GETTER, AUTHOR_ACTION, AuthorState, AuthorResult, AuthorPayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createAuthorModule<RootState> (namespaced = false): Module<AuthorState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [AUTHOR_GETTER.API]: (state, getters, rootState, rootGetters) => {
        return new AuthorApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [AUTHOR_ACTION.ITEM] ({ getters, rootGetters }, payload: AuthorPayload): Promise<AuthorResult> {
        const response = await errorHandler(() => getters[AUTHOR_GETTER.API].getAuthorBySlug({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
