import { GetPageByUrlRequest, PageResult as ApiPageResult } from '@rtl/api'
import { ApiPayload } from '../api'

export type PageResult = ApiPageResult & { cache?: string }

export type PagePayload = ApiPayload<GetPageByUrlRequest, 'hostname'>

export interface PageState {}

export enum PAGE_ACTION {
  ITEM = 'fetchPage'
}

export enum PAGE_GETTER {
  API = 'api'
}

export const PAGE_NAMESPACE = 'page'
