"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingFormListDataFieldsToJSONTyped = exports.CastingFormListDataFieldsToJSON = exports.CastingFormListDataFieldsFromJSONTyped = exports.CastingFormListDataFieldsFromJSON = void 0;
const _1 = require("./");
function CastingFormListDataFieldsFromJSON(json) {
    return CastingFormListDataFieldsFromJSONTyped(json, false);
}
exports.CastingFormListDataFieldsFromJSON = CastingFormListDataFieldsFromJSON;
function CastingFormListDataFieldsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...(0, _1.CastingFormListDataFieldsGroupFromJSONTyped)(json, true), ...(0, _1.CastingFormListDataFieldsSingleFromJSONTyped)(json, true) };
}
exports.CastingFormListDataFieldsFromJSONTyped = CastingFormListDataFieldsFromJSONTyped;
function CastingFormListDataFieldsToJSON(value) {
    return CastingFormListDataFieldsToJSONTyped(value, false);
}
exports.CastingFormListDataFieldsToJSON = CastingFormListDataFieldsToJSON;
function CastingFormListDataFieldsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return { ...(0, _1.CastingFormListDataFieldsGroupToJSON)(value), ...(0, _1.CastingFormListDataFieldsSingleToJSON)(value) };
}
exports.CastingFormListDataFieldsToJSONTyped = CastingFormListDataFieldsToJSONTyped;
