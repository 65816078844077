
import { ContentData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import ContentCard from '../content/ContentCard.vue'
import ContentCardList from '../content/ContentCardList.vue'
import WidgetBlock from '../widget/WidgetBlock.vue'

@Options({
  name: 'ContentMixWidgetBlock',
  components: {
    WidgetBlock,
    ContentCardList,
    ContentCard
  }
})
export default class ContentMixWidgetBlock extends Vue {
  @Prop({ type: Array, default: [] })
  readonly firstContents!: ContentData[] | undefined

  @Prop({ type: Array, default: [] })
  readonly secondContents!: ContentData[]

  @Prop({ type: Array, default: [] })
  readonly thirdContents!: ContentData[]

  @Prop({ type: String, default: '' })
  readonly firstTitle!: string

  @Prop({ type: String, default: '' })
  readonly firstBtnLabel!: string

  @Prop({ type: String, default: '' })
  readonly firstBtnUrl!: string

  @Prop({ type: String, default: '' })
  readonly secondTitle!: string

  @Prop({ type: String, default: '' })
  readonly secondBtnLabel!: string

  @Prop({ type: String, default: '' })
  readonly secondBtnUrl!: string

  @Prop({ type: String, default: '' })
  readonly thirdTitle!: string

  @Prop({ type: String, default: '' })
  readonly thirdBtnLabel!: string

  @Prop({ type: String, default: '' })
  readonly thirdBtnUrl!: string
}
