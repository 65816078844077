import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tv-show-logo-card-list" }
const _hoisted_2 = { class: "tv-show-logo-card-list__all-icon" }
const _hoisted_3 = { class: "tv-show-logo-card-list__all-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_logo_card = _resolveComponent("tv-show-logo-card")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createBlock(_component_tv_show_logo_card, {
        key: i,
        "tv-show": item
      }, null, 8, ["tv-show"]))
    }), 128)),
    (_ctx.btnLabel && _ctx.btnUrl)
      ? (_openBlock(), _createBlock(_component_nav_link, {
          key: 0,
          to: _ctx.btnUrl,
          class: "tv-show-logo-card-list__all"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_ArrowRight)
            ]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.btnLabel), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true)
  ]))
}