import { Module } from 'vuex'
import { FormApi } from '@rtl/api'
import {
  FORM_GETTER,
  FORM_ACTION,
  FormState,
  CastingFormResult,
  CastingFormListResult,
  FormPayload,
  FormListPayload,
  FormGroupListPayload,
  CastingFormGroupsListResult
} from './types'
import { API_GETTER, errorHandler } from '../api'

export function createFormModule<RootState> (namespaced = false): Module<FormState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [FORM_GETTER.API]: (state, getters, rootState, rootGetters) => {
        return new FormApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [FORM_ACTION.ITEM] ({ getters, rootGetters }, payload: FormPayload): Promise<CastingFormResult> {
        const response = await errorHandler(() => getters[FORM_GETTER.API].getCastingForm({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      },
      async [FORM_ACTION.LIST] ({ getters, rootGetters }, payload: FormListPayload): Promise<CastingFormListResult> {
        const response = await errorHandler(() => getters[FORM_GETTER.API].listCastingForms({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      },
      async [FORM_ACTION.GROUP_LIST] ({ getters, rootGetters }, payload: FormGroupListPayload): Promise<CastingFormGroupsListResult> {
        const response = await errorHandler(() => getters[FORM_GETTER.API].listCastingFormGroups({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
