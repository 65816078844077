
import { Options, Prop, Vue } from 'vue-property-decorator'

import WidgetBlock from './widget/WidgetBlock.vue'

@Options({
  name: 'Banner',
  components: {
    WidgetBlock
  }
})
export default class Banner extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  readonly zoneId!: string

  @Prop({
    type: String,
    default: ''
  })
  readonly zoneKey!: string

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showBackground!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly showPlaceholder!: boolean

  @Prop({
    type: String,
    default: 'bottom'
  })
  readonly labelPosition!: 'bottom' | 'left' | ''

  isFullfilled = false
  observer: MutationObserver | null = null
  banner: HTMLDivElement | null = null

  initObserve () {
    this.banner = this.$refs.banner as HTMLDivElement | null
    if (this.banner) {
      this.observer?.observe(this.banner, { attributeFilter: ['style'] })
      this.updateStatus()
    }
  }

  updateStatus () {
    this.isFullfilled = this.banner?.style.display === 'block'
  }

  updated () {
    this.$nextTick(() => {
      this.initObserve()
    })
  }

  mounted () {
    this.observer = new MutationObserver(() => {
      this.updateStatus()
    })
    this.initObserve()
  }

  unmounted () {
    this.observer?.disconnect()
  }
}
