import { Module } from 'vuex'
import { ENV_GETTER, ENV_MUTATION, EnvState } from './types'

export function createEnvModule<RootState> (namespaced = false): Module<EnvState, RootState> {
  return {
    namespaced,
    state () {
      return {
        env: {}
      }
    },
    getters: {
      [ENV_GETTER.ENV] (state) {
        return (name: string) => {
          const keys: Array<string> = [name]

          if (typeof process !== 'undefined') {
            keys.unshift(`${name}_SSR`)
          }

          for (const i in keys) {
            const prefix = process.env.ENV_PREFIX || 'VUE_APP'
            const key = `${prefix}_${keys[i]}`
            const val = state.env[key] ?? process.env[key]

            if (val !== null && val !== undefined) {
              return val
            }
          }
        }
      }
    },
    mutations: {
      [ENV_MUTATION.ENV] (state, payload: Record<string, unknown>) {
        state.env = {
          ...state.env,
          ...payload
        }
      }
    }
  }
}
