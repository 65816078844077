import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tv-program-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TvProgramCard = _resolveComponent("TvProgramCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "data-slider-pane": "",
      class: _normalizeClass(["tv-program-list__row", {
        'tv-program-list__row--slider': _ctx.showSlider
      }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tvPrograms, (tvProgram, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["tv-program-list__col", {
          'tv-program-list__col--2': _ctx.cols === 2,
          'tv-program-list__col--3': _ctx.cols === 3,
          'tv-program-list__col--4': _ctx.cols === 4
        }])
        }, [
          _createVNode(_component_TvProgramCard, {
            "tv-program": tvProgram,
            "item-length": _ctx.itemLength
          }, null, 8, ["tv-program", "item-length"])
        ], 2))
      }), 128))
    ], 2)
  ]))
}