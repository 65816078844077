
import { ContentListQueryOrderDirectionEnum, ContentListQueryOrderFieldEnum } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import DragScrollDirective from '../../directives/DragScrollDirective'
import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentListPayload, ContentListResult } from '../../store'

const ContentListQueryFilterTagSets: Record<string, number[]> = {
  rtl_plus: [411983],
  rtl_plus_light: [411973],
  rtl_plus_active: [411978],
  rtl_plus_tiers: [411970],
  rtl_plus_prize_game: [411983]
}

@Options({
  name: 'TopShowBlockWidget',
  directives: {
    dragscroll: DragScrollDirective
  }
})
export default class TopShowBlockWidget extends Vue {
  @Action(CONTENT_ACTION.LIST, { namespace: CONTENT_NAMESPACE })
  storeFetchContentList!: (payload: ContentListPayload) => Promise<ContentListResult>

  @Prop({ type: String, default: '' })
  site!: string;

  items: ContentListResult['data']['items'] = []

  created () {
    this.fetchItems()
  }

  async fetchItems () {
    const response = await this.storeFetchContentList({
      filterTags: ContentListQueryFilterTagSets[this.site],
      order: [
        { field: ContentListQueryOrderFieldEnum.ReleaseDate, direction: ContentListQueryOrderDirectionEnum.Desc }
      ]
    })
    this.items = response?.data?.items || []
  }
}
