"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayLoadSortByToJSONTyped = exports.PayLoadSortByToJSON = exports.PayLoadSortByFromJSONTyped = exports.PayLoadSortByFromJSON = exports.PayLoadSortBy = void 0;
/**
 * Sort by this field for PayLoad
 * @export
 * @enum {string}
 */
var PayLoadSortBy;
(function (PayLoadSortBy) {
    PayLoadSortBy["Id"] = "id";
    PayLoadSortBy["Title"] = "title";
    PayLoadSortBy["CreatedTs"] = "created_ts";
    PayLoadSortBy["ModifiedTs"] = "modified_ts";
    PayLoadSortBy["TimeFrom"] = "time_from";
    PayLoadSortBy["TimeUntil"] = "time_until";
})(PayLoadSortBy = exports.PayLoadSortBy || (exports.PayLoadSortBy = {}));
function PayLoadSortByFromJSON(json) {
    return PayLoadSortByFromJSONTyped(json, false);
}
exports.PayLoadSortByFromJSON = PayLoadSortByFromJSON;
function PayLoadSortByFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PayLoadSortByFromJSONTyped = PayLoadSortByFromJSONTyped;
function PayLoadSortByToJSON(value) {
    return PayLoadSortByToJSONTyped(value, false);
}
exports.PayLoadSortByToJSON = PayLoadSortByToJSON;
function PayLoadSortByToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.PayLoadSortByToJSONTyped = PayLoadSortByToJSONTyped;
