import { Box, ContentData } from '@rtl/api'
import { cloneDeep } from 'lodash'
import toSource from 'tosource'

const EOL = '\r\n'

export type SkeletonItem = Box

export function camelToKebab (str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1).replace(/[A-Z]/g, s => '-' + s.toLowerCase())
}

export function stripHtml (str?: string) {
  return str?.replace(/<[^>]*>?/gm, '').trim()
}

export function parseHtmlString (src: string): NodeListOf<ChildNode> {
  return findElement(parseFromString(`<body>${src}</body>`), 'body')!.childNodes
}


export function parseFromString (src: string): NodeListOf<ChildNode> {
  const domParser = new DOMParser()
  return domParser.parseFromString(src, 'text/html').documentElement.childNodes
}

export function renderHtmlString (tag: string, attrs: Record<string, unknown> = {}, content = '') {
  const options = {
    indent: '  ',
    eol: EOL,
    attrsFn (attrs: Record<string, unknown>) {
      return Object.keys(attrs).map(key => {
        const name = camelToKebab(key)
        const value = key.startsWith(':')
          ? toSource(attrs[key], undefined, false).replaceAll("'", "\\'" ).replaceAll('"', "'")
          : attrs[key]

        return value === undefined || value === null ? name : `${name}="${value}"`
      })
    }
  }

  const open = `<${[tag, ...options.attrsFn(attrs)].join(' ')}>`
  const close = `</${tag}>`
  const children = content ? options.eol + content.replace(/^./gm, s => options.indent + s) + options.eol : ''

  return open + children + close
}

export function timingFilter (items: Array<Box>) {
  return items.reduce<Array<Box>>((acc, item) => {
    const now = Date.now()
    const timeFrom = !item.timeFrom || item.timeFrom.getTime() < now
    const timeUntil = !item.timeUntil || item.timeUntil.getTime() > now
    if (timeFrom && timeUntil) {
      if (item.containedBoxes) {
        item.containedBoxes = timingFilter(item.containedBoxes)
      }
      acc.push(item)
    }

    return acc
  }, [])
}

export function htmlSerializer (items: Array<Box> = [], timing = true): string {
  const serialize = (items: Array<Box>): string => items.map(item => {
    const attrs: Record<string, unknown> = {}
    const params = item.parameters

    if (item.timeFrom) {
      const timeFrom = new Date(item.timeFrom)
      if (timeFrom.getUTCFullYear() > 2001) {
        params.timeFrom = timeFrom.toISOString()
      }
    }
    if (item.timeUntil) {
      const timeUntil = new Date(item.timeUntil)
      if (timeUntil.getUTCFullYear() < 2099) {
        params.timeUntil = timeUntil.toISOString()
      }
    }

    for (const key in params) {
      if (params[key] !== undefined) {
        attrs[key] = params[key]
      }
    }

    const children = item.containedBoxes ? serialize(item.containedBoxes) : ''

    return renderHtmlString(item.componentName, attrs, children)
  }).join(EOL)

  const src = timing ? timingFilter(cloneDeep(items)) : cloneDeep(items)

  return serialize(src)
}

export const decorateWithBanners = (cb: (index: number) => Box, skeleton?: Array<Box>, n = 1) => {
  let index = 0
  return skeleton?.reduce<Array<Box>>((res, item, i) => {
    res.push(item)
    if ((i + 1) % n === 0) {
      res.push(cb(index))
      index += 1
    }
    return res
  }, [])
}

export function renderContentWidgets (widgets: ContentData['widgets'], targetHtml: string): string {
  for (const widgetId in widgets) {
    const widget = widgets[widgetId]
    const html = htmlSerializer([widget])
    targetHtml = targetHtml.replace(`<span data-widget-id="${widgetId}"></span>`, html)
  }

  return targetHtml
}

export function findElement (nodes: NodeList, tagName: string): HTMLElement | undefined {
  return Array.from(nodes).find(node => (node as any).tagName?.toLowerCase() === tagName.toLowerCase()) as HTMLElement | undefined
}

export function findElements (nodes: NodeList, tagName: string): Array<HTMLElement> {
  return Array.from(nodes).filter(node => (node as any).tagName?.toLowerCase() === tagName.toLowerCase()) as Array<HTMLElement>
}

export function getInnerHtml (el?: HTMLElement) {
  return el?.innerHTML ?? ''
}
