import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_card_grid = _resolveComponent("content-card-grid")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.contentList?.items.length)
    ? (_openBlock(), _createBlock(_component_widget_block, _mergeProps({
        key: 0,
        title: _ctx.title,
        "show-divider": !!_ctx.title,
        "btn-label": _ctx.btnLabel,
        "btn-url": _ctx.btnUrl
      }, _ctx.widgetStyleBinding), {
        default: _withCtx(() => [
          _createVNode(_component_content_card_grid, {
            contents: _ctx.contentList?.items,
            decorators: _ctx.payload?.decorators,
            "show-title": _ctx.showTitle,
            "show-lead": _ctx.showLead,
            "show-release-date": _ctx.showReleaseDate,
            "show-tag": _ctx.showTag,
            "show-duration": _ctx.showDuration,
            "show-gallery-badge": _ctx.showGalleryBadge,
            compact: _ctx.compact,
            "compact-inverse": _ctx.compactInverse
          }, null, 8, ["contents", "decorators", "show-title", "show-lead", "show-release-date", "show-tag", "show-duration", "show-gallery-badge", "compact", "compact-inverse"])
        ]),
        _: 1
      }, 16, ["title", "show-divider", "btn-label", "btn-url"]))
    : _createCommentVNode("", true)
}