import { Gigya, Request, Response } from "../";

export interface ProfileData {
  email?: string;
  firstName?: string;
  lastName?: string;
  birthDay?: number;
  birthMonth?: number;
  birthYear?: number;
  gender?: "m" | "f" | "u" | "";
  zip?: string;
}

export interface UserData {
  UID: string;
  profile: ProfileData;
  data: UserDataObject;
  sessionExpiration: number;
}

export interface UserDataObject {
  RTLHU_Consent?: boolean;
  RTLHU_Consent_created_at?: null | Date;
  RTLHU_Newsletter?: boolean;
  RTLHU_Newsletter_created_at?: null | Date;
  RTLMOST_Consent?: boolean;
  RTLMOST_Newsletter?: boolean;
}

export interface EmailsData {
  verified: Array<string>;
  unverified: Array<string>;
}

export interface LoginRequest extends Request {
  loginId: string;
  password: string;
  sessionExpiration?: number;
  include?: string;
}

export interface ForgotPasswordRequest extends Request {
  loginId: string;
}

export interface SocialRequest extends Request {
  x_provider: string;
  redirect_uri: string;
  response_type: string;
}

export interface UserRequest extends Request {
  UID?: string;
  login_token?: string;
}

export interface NotifyLoginRequest extends Request {
  siteUID: string;
  UIDSig: string;
  UIDTimestamp: string;
}

export interface AccountRequest extends Request {
  UID?: string;
}

export interface AccountInfoResponse extends Request {
  UID: string;
  UIDSignature: string;
  signatureTimestamp: string;
  loginProvider: string;
  lastLoginTimestamp: number;
  registeredTimestamp: number;
}

export interface UserInfoRequest extends Request {
  login_token: string;
  includeAllIdentities: boolean
}

export interface AccountUpdateRequest extends Request {
  UID: string;
  profile?: ProfileData;
  password?: string;
  newPassword?: string;
  data?: UserDataObject;
}

export interface RegisterRequest extends Request {
  email: string;
  password: string;
  profile: ProfileData;
  finalizeRegistration?: boolean;
  data?: UserDataObject;
  terms?: boolean;
  newsletter?: boolean;
  include?: string;
}

export interface ResetPasswordRequest extends Request {
  loginId?: string;
  passwordResetToken?: string;
  newPassword?: string;
  newPasswordConfirm?: string;
}

export interface AuthResponse extends Response {
  regToken?: string;
  sessionInfo?: { cookieName: string; cookieValue: string };
}

export interface SocialResponse extends Response {
  access_token?: string;
}

export interface DeleteAccountRequest extends Request {
  UID: string;
}

export interface IsAvailableRequst extends Request {
  loginID: string;
}
export interface VerificationRequest extends Request {
  email: string;
  UID: string;
}

export interface UserResponse extends AuthResponse {
  UID: string;
  UIDSignature?: string;
  isActive?: boolean;
  isRegistered?: boolean;
  isVerified?: boolean;
  newUser?: boolean;
  emails?: EmailsData;
  profile?: ProfileData;
  data?: UserDataObject;
  preferences?: { [key: string]: unknown };
  socialProviders?: string;
  created?: string;
  createdTimestamp?: number;
  lastUpdated?: string;
  lastUpdatedTimestamp?: number;
  oldestDataUpdated?: string;
  oldestDataUpdatedTimestamp?: number;
  lastLogin?: string;
  lastLoginTimestamp?: number;
  registered?: string;
  registeredTimestamp?: number;
  signatureTimestamp?: number;
}

export interface ResetPasswordResponse extends Response {}

export interface LinkAccountRequest extends Request {
  regToken: string;
  loginID: string;
  password: string;
  include: string;
}

export interface GetJWTRequest extends Request {
  UID: string
}

export interface GetJWTResponse extends Response {
  id_token: string
}

export interface FinalizeRegistrationRequest extends Request {}

export class Accounts {
  private gigya: Gigya;

  constructor(gigya: Gigya) {
    this.gigya = gigya;
  }

  public login(params: LoginRequest): Promise<UserResponse> {
    return this.gigya.post<UserResponse>("accounts.login", params);
  }

  public logout(params: UserRequest): Promise<Response> {
    return this.gigya.post<Response>("accounts.logout", params);
  }

  public initRegistration(): Promise<AuthResponse> {
    return this.gigya.post<AuthResponse>("accounts.initRegistration");
  }

  public register(params: RegisterRequest): Promise<UserResponse> {
    return this.gigya.post<UserResponse>("accounts.register", params);
  }

  public updateAccount(params: AccountUpdateRequest): Promise<UserResponse> {
    return this.gigya.post<UserResponse>("accounts.setAccountInfo", params);
  }

  public getAccountInfo(params: AccountRequest): Promise<UserResponse> {
    return this.gigya.get<UserResponse>("accounts.getAccountInfo", params);
  }

  public notifyLogin(params: NotifyLoginRequest): Promise<UserResponse> {
    return this.gigya.get<UserResponse>("accounts.notifyLogin", params);
  }

  public getUserInfo(params: UserInfoRequest): Promise<UserResponse> {
    return this.gigya.get<UserResponse>("socialize.getUserInfo", params);
  }

  public deleteAccount(params: DeleteAccountRequest): Promise<Response> {
    return this.gigya.post<Response>("accounts.deleteAccount", params);
  }

  public isAvailableLogin(params: IsAvailableRequst): Promise<Response> {
    return this.gigya.get<Response>("accounts.isAvailableLoginID", params);
  }

  public sendVerification(params: VerificationRequest): Promise<Response> {
    return this.gigya.get<Response>("accounts.resendVerificationCode", params);
  }

  public linkAccount(params: LinkAccountRequest): Promise<UserResponse> {
    return this.gigya.get<UserResponse>("accounts.linkAccounts", params);
  }

  public finalizeRegistration(params: FinalizeRegistrationRequest): Promise<UserResponse> {
    return this.gigya.get<UserResponse>("accounts.finalizeRegistration", params);
  }

  public resetPassword(
    params: ResetPasswordRequest
  ): Promise<ResetPasswordResponse> {
    return this.gigya.post<ResetPasswordResponse>(
      "accounts.resetPassword",
      params
    );
  }

  public social(params: SocialRequest): String {
    return this.gigya.buildSocialLogin("accounts.socialLogin", params);
  }

  public getJWT (params: GetJWTRequest): Promise<GetJWTResponse> {
    return this.gigya.post<GetJWTResponse>('accounts.getJWT', params)
  }
}

export default Accounts;
