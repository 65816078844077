import { createI18n as vueI18n } from 'vue-i18n'
import en from './locales/en'
import hu from './locales/hu'

const DEFAULT_LOCALE = process.env.VUE_APP_DEFAULT_LOCALE

export function createI18n () {
  return vueI18n({
    datetimeFormats: {
      hu: {
        short: {
          year: 'numeric', month: 'short', day: 'numeric'
        },
        medium: {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          // weekday: 'short',
          hour: 'numeric',
          minute: 'numeric'
        },
        time: {
          hour: 'numeric',
          minute: 'numeric'
        }
      }
    },
    legacy: false,
    globalInjection: true,
    locale: 'hu',
    fallbackLocale: DEFAULT_LOCALE,
    messages: { en, hu }
  })
}
