
import { TvProgramByProgDays } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import TvProgramCard from './TvProgramCard.vue'

@Options({
  name: 'TvProgramCardList',
  components: {
    TvProgramCard
  }
})
export default class TvProgramCardList extends Vue {
  @Prop({ type: Array, required: true })
  private tvPrograms!: TvProgramByProgDays[]

  @Prop({ type: Boolean, default: false })
  private showSlider!: boolean

  @Prop({ type: Number, default: 0 })
  private cols!: number

  @Prop({ type: Number, default: 3 })
  readonly itemLength!: number
}
