import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tv-show-logo-card" }
const _hoisted_2 = { class: "tv-show-logo-card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createVNode(_component_nav_link, { to: _ctx.url }, {
      default: _withCtx(() => [
        _createVNode(_component_Image, {
          class: "tv-show-logo-card__image",
          "image-url": _ctx.imageUrl,
          alt: _ctx.title
        }, null, 8, ["image-url", "alt"]),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}