
import { TvProgramItem } from '@rtl/api'
import { format, isToday } from 'date-fns'
import { Options, Prop, Vue } from 'vue-property-decorator'

import AgeLimit from '../AgeLimit.vue'
import Image from '../Image.vue'

@Options({
  name: 'TvProgramItemCard',
  components: {
    Image,
    AgeLimit
  }
})
export default class TvProgramItemCard extends Vue {
  @Prop({ type: Object, required: true })
  private item!: TvProgramItem

  get title (): string | null {
    return this.item.title || null
  }

  get time (): string {
    const programDate = new Date(this.item.progDate || '')
    const programLength = parseInt(this.item.progLength || '0')
    let result = isToday(programDate) ? 'Ma, ' : ''

    result += format(programDate, 'HH:mm')

    if (programLength > 0) {
      programDate.setMinutes(programDate.getMinutes() + programLength)
      result += ' - ' + format(programDate, 'HH:mm')
    }

    return result
  }

  get ageLimit (): number {
    return parseInt(this.item.ageLimit || '0')
  }
}
