
import { Options, Prop, Vue } from 'vue-property-decorator'

import { fixProtocoll } from '../../utils'
import Close from '../components/icons/Close.vue'

@Options({
  name: 'VideoPlayer',
  components: {
    Close
  }
})
export default class VideoPlayer extends Vue {
  @Prop({ type: String, default: '' })
  readonly title!: string

  @Prop({ type: String, default: '' })
  readonly videoSrc!: string

  @Prop({ type: Boolean, default: false })
  readonly pip!: boolean

  @Prop({ type: String, default: '100%' })
  readonly height!: string

  @Prop({ type: String, default: '100%' })
  readonly width!: string

  @Prop({ type: Boolean, default: false })
  readonly autoplay!: boolean

  @Prop({ type: String, default: null })
  readonly campaign!: string | null

  @Prop({ type: String, default: null })
  readonly medium!: string | null

  @Prop({ type: String, default: null })
  readonly source!: string | null

  isInViewPort = true
  isBottomDirection = false
  matchBelowTablet!: MediaQueryList
  isDesktop = true
  isClosed = false
  withUTM = false


  get fullVideoSrc () {
    let src = this.getUtmSrc(fixProtocoll(this.videoSrc))
    let char = this.withUTM ? '&' : '?'
    src += (this.autoplay ? `${char}autoplay=true` : '')
    return src
  }

  getUtmSrc (src: string) {
    if (this.medium && this.source && this.campaign) {
      this.withUTM = true
      return `${src}?medium=${this.medium}&source=${this.source}&campaign=${this.campaign}`
    } else {
      return src
    } 
  }

  onChangeVisibility (visible: boolean, isBottomDirection: boolean) {
    this.isInViewPort = visible
    this.isBottomDirection = isBottomDirection
  }

  onMediaQueryChange (e: MediaQueryListEvent) {
    this.isDesktop = !e.matches
  }

  get isPip (): boolean {
    return (this.isDesktop && !this.isInViewPort && this.pip && !this.isClosed && this.isBottomDirection)
  }

  mounted () {
    this.matchBelowTablet = window.matchMedia('(max-width: 1200px)')
    this.matchBelowTablet.addEventListener('change', this.onMediaQueryChange)
    this.isDesktop = !this.matchBelowTablet.matches
  }

  unmounted () {
    this.matchBelowTablet.removeEventListener('change', this.onMediaQueryChange)
  }
}
