"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminFolderToJSONTyped = exports.AdminFolderToJSON = exports.AdminFolderFromJSONTyped = exports.AdminFolderFromJSON = void 0;
function AdminFolderFromJSON(json) {
    return AdminFolderFromJSONTyped(json, false);
}
exports.AdminFolderFromJSON = AdminFolderFromJSON;
function AdminFolderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'parentFolderId': json['parentFolderId'],
        'folderType': json['folderType'],
        'label': json['label'],
        'childCount': json['childCount'],
    };
}
exports.AdminFolderFromJSONTyped = AdminFolderFromJSONTyped;
function AdminFolderToJSON(value) {
    return AdminFolderToJSONTyped(value, false);
}
exports.AdminFolderToJSON = AdminFolderToJSON;
function AdminFolderToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'parentFolderId': value.parentFolderId,
        'folderType': value.folderType,
        'label': value.label,
        'childCount': value.childCount,
    };
}
exports.AdminFolderToJSONTyped = AdminFolderToJSONTyped;
