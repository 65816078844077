import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentListPayload, ContentListResult } from '../../store'

@Options({
  name: 'FetchRecommendedVideoListMixin'
})
export default class FetchRecommendedVideoListMixin extends Vue {
  @Action(CONTENT_ACTION.LIST, { namespace: CONTENT_NAMESPACE })
  storeFetchContentList!: (payload: ContentListPayload) => Promise<ContentListResult>

  @Prop({
    type: Boolean,
    default: false
  })
  readonly lazy!: boolean

  videoTagListLength = 0
  limit = 50

  get topNList (): ContentListResult['data'] | null {
    if (this.videoTagList && this.topNListPayload) {
      return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], {...this.topNListPayload, limit: this.limit - this.videoTagListLength})?.data || null 
    } else {
      return null
    }
  }

  get videoTagList (): ContentListResult['data'] | null {
    if ((this.videoTagPayload)) {
      const res = this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], this.videoTagPayload)?.data || null 
      this.videoTagListLength = res?.items.length || 0
      return res
    }
    return null
  }

  @Watch('videoTagList', { deep: true})
  async fetchTopNList () {
    if (this.topNListPayload) {
      return this.storeFetchContentList({...this.topNListPayload, limit: this.limit - this.videoTagListLength})
    }
  }

  @Watch('videoTagPayload', { deep: true })
  async fetchVideoTagList () {
    if (this.videoTagPayload) {
      return this.storeFetchContentList(this.videoTagPayload)
    }
  }

  get videoTagPayload (): ContentListPayload  {
    return {}
  }

  get topNListPayload (): ContentListPayload {
    return {}
  }

  async serverPrefetch () {
    if (!this.lazy) {
      return this.fetchVideoTagList()
    }
  }

  mounted () {
    return this.fetchVideoTagList()
  }
}
