"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingFormListDataFieldsGroupToJSONTyped = exports.CastingFormListDataFieldsGroupToJSON = exports.CastingFormListDataFieldsGroupFromJSONTyped = exports.CastingFormListDataFieldsGroupFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CastingFormListDataFieldsGroupFromJSON(json) {
    return CastingFormListDataFieldsGroupFromJSONTyped(json, false);
}
exports.CastingFormListDataFieldsGroupFromJSON = CastingFormListDataFieldsGroupFromJSON;
function CastingFormListDataFieldsGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groupId': json['groupId'],
        'imageUrls': (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'type': json['type'],
        'title': json['title'],
        'appTitle': !(0, runtime_1.exists)(json, 'appTitle') ? undefined : json['appTitle'],
    };
}
exports.CastingFormListDataFieldsGroupFromJSONTyped = CastingFormListDataFieldsGroupFromJSONTyped;
function CastingFormListDataFieldsGroupToJSON(value) {
    return CastingFormListDataFieldsGroupToJSONTyped(value, false);
}
exports.CastingFormListDataFieldsGroupToJSON = CastingFormListDataFieldsGroupToJSON;
function CastingFormListDataFieldsGroupToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groupId': value.groupId,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'type': value.type,
        'title': value.title,
        'appTitle': value.appTitle,
    };
}
exports.CastingFormListDataFieldsGroupToJSONTyped = CastingFormListDataFieldsGroupToJSONTyped;
