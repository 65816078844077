"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GalleryImageToJSONTyped = exports.GalleryImageToJSON = exports.GalleryImageFromJSONTyped = exports.GalleryImageFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function GalleryImageFromJSON(json) {
    return GalleryImageFromJSONTyped(json, false);
}
exports.GalleryImageFromJSON = GalleryImageFromJSON;
function GalleryImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'caption': !(0, runtime_1.exists)(json, 'caption') ? undefined : json['caption'],
        'source': !(0, runtime_1.exists)(json, 'source') ? undefined : json['source'],
        'sequenceNr': !(0, runtime_1.exists)(json, 'sequenceNr') ? undefined : json['sequenceNr'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'type': json['type'],
        'embedHtml': !(0, runtime_1.exists)(json, 'embedHtml') ? undefined : json['embedHtml'],
    };
}
exports.GalleryImageFromJSONTyped = GalleryImageFromJSONTyped;
function GalleryImageToJSON(value) {
    return GalleryImageToJSONTyped(value, false);
}
exports.GalleryImageToJSON = GalleryImageToJSON;
function GalleryImageToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'caption': value.caption,
        'source': value.source,
        'sequenceNr': value.sequenceNr,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'type': value.type,
        'embedHtml': value.embedHtml,
    };
}
exports.GalleryImageToJSONTyped = GalleryImageToJSONTyped;
