import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createBlock(_component_nav_link, { to: _ctx.url }, {
    default: _withCtx(() => [
      _createVNode(_component_Image, {
        "image-url": _ctx.totemUrl,
        alt: _ctx.title
      }, null, 8, ["image-url", "alt"])
    ]),
    _: 1
  }, 8, ["to"]))
}