import './hooks'

import { getRoutes, scrollBehavior } from '@rtl/ui'
import { createMemoryHistory, createRouter as createVueRouter, createWebHistory } from 'vue-router'

import { appGuard } from './guard'

export function createRouter (isSSR = false) {
  const router = createVueRouter({
    history: isSSR ? createMemoryHistory() : createWebHistory(),
    routes: getRoutes(),
    scrollBehavior
  })

  router.beforeEach(appGuard)

  return router
}
