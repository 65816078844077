import { createStore as createVuexStore } from 'vuex'
import { loggerPlugin, cachePlugin } from './plugins'
import {
  HttpError,
  createEnvModule,
  createApiModule,
  createAuthorModule,
  createContentModule,
  createEventModule,
  createPageModule,
  createSiteModule,
  createTvProgramModule,
  createSearchModule,
  createTagModule,
  createFormModule,
  createUserModule,
  createRssModule,
  createMeasurementModule,
  createFaqModule,
  createContactFormModule,
  createHtmlContentModule,
  API_NAMESPACE,
  AUTHOR_NAMESPACE,
  CONTENT_NAMESPACE,
  ENV_NAMESPACE,
  EVENT_NAMESPACE,
  PAGE_NAMESPACE,
  SITE_NAMESPACE,
  TV_PROGRAM_NAMESPACE,
  SEARCH_NAMESPACE,
  TAG_NAMESPACE,
  FORM_NAMESPACE,
  USER_NAMESPACE,
  RSS_NAMESPACE,
  MEASUREMENT_NAMESPACE,
  FAQ_NAMESPACE,
  CONTACT_FORM_NAMESPACE,
  HTML_CONTENT_NAMESPACE
} from './modules'

export * from './modules'
export * from './plugins'

export interface AppState {
  appVersion: string | null
}

export function createStore (preserveState = false) {
  const DEBUG_MODE = process.env.NODE_ENV === 'development'
  const APP_VERSION = process.env.VUE_APP_VERSION || null

  return createVuexStore<AppState>({
    strict: true,
    devtools: DEBUG_MODE ? undefined : false,
    state () {
      return {
        appVersion: APP_VERSION
      }
    },
    modules: {
      [ENV_NAMESPACE]: createEnvModule<AppState>(false),
      [API_NAMESPACE]: createApiModule<AppState>(false),
      [PAGE_NAMESPACE]: createPageModule<AppState>(true),
      [CONTENT_NAMESPACE]: createContentModule<AppState>(true),
      [EVENT_NAMESPACE]: createEventModule<AppState>(true),
      [SITE_NAMESPACE]: createSiteModule<AppState>(true),
      [TV_PROGRAM_NAMESPACE]: createTvProgramModule<AppState>(true),
      [SEARCH_NAMESPACE]: createSearchModule<AppState>(true),
      [TAG_NAMESPACE]: createTagModule<AppState>(true),
      [AUTHOR_NAMESPACE]: createAuthorModule<AppState>(true),
      [FORM_NAMESPACE]: createFormModule<AppState>(true),
      [USER_NAMESPACE]: createUserModule<AppState>(true),
      [RSS_NAMESPACE]: createRssModule<AppState>(true),
      [MEASUREMENT_NAMESPACE]: createMeasurementModule<AppState>(true),
      [FAQ_NAMESPACE]: createFaqModule<AppState>(true),
      [CONTACT_FORM_NAMESPACE]: createContactFormModule<AppState>(true),
      [HTML_CONTENT_NAMESPACE]: createHtmlContentModule<AppState>(true)
    },
    plugins: [
      ...(DEBUG_MODE ? [loggerPlugin()] : []),
      cachePlugin({
        preserveState,
        filter: type => [PAGE_NAMESPACE, CONTENT_NAMESPACE, SITE_NAMESPACE, TV_PROGRAM_NAMESPACE, TAG_NAMESPACE, AUTHOR_NAMESPACE, FORM_NAMESPACE, RSS_NAMESPACE, FAQ_NAMESPACE, CONTACT_FORM_NAMESPACE, HTML_CONTENT_NAMESPACE].some(ns => type.startsWith(`${ns}/`)),
        error: error => {
          switch (error.name) {
            case 'HttpError':
              return new HttpError(error.statusCode)
          }
          return error
        }
      })
    ]
  })
}
