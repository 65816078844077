import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "vote-banner__container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "vote-banner__text" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!

  return (_ctx.isAvailable)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "vote-banner",
        href: _ctx.link,
        onClickCapture: _cache[1] || (_cache[1] = ($event: any) => (_ctx.measure('select', _ctx.promotionName)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.logo)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "vote-banner__logo",
                src: _ctx.logo
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_Close, {
            class: "vote-banner__close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.close(_ctx.promotionName, {voteId: _ctx.voteId, voteGroupId: _ctx.voteGroupId})), ["prevent"]))
          })
        ]),
        _createElementVNode("div", {
          class: "vote-banner__bg-wrapper",
          style: _normalizeStyle({ backgroundColor: _ctx.color })
        }, [
          (_ctx.background)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "vote-banner__background",
                src: _ctx.background
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ], 4)
      ], 40, _hoisted_1))
    : _createCommentVNode("", true)
}