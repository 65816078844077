
import { ContentData } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentDecorator } from '../../../store'
import WidgetStyleMixin from '../../widgets/WidgetStyleMixin'
import ContentCardList from '../content/ContentCardList.vue'
import RtlNowBackground from '../icons/RtlNowBackground.vue'
import WidgetBlock from '../widget/WidgetBlock.vue'

@Options({
  name: 'LongVideoWidgetBlock',
  components: {
    WidgetBlock,
    ContentCardList,
    RtlNowBackground
  }
})
export default class LongVideoWidgetBlock extends mixins(WidgetStyleMixin) {
  @Prop({ type: Array, default: [] })
  readonly contents!: Array<ContentData>

  @Prop({ type: Object, default: () => ({}) })
  readonly decorators!: Record<string, ContentDecorator>

  @Prop({ type: String })
  readonly title!: string | undefined

  @Prop({ type: String })
  readonly btnLabel!: string | undefined

  @Prop({ type: String })
  readonly btnUrl!: string | undefined
}
