"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPageResetDataToJSONTyped = exports.AdminPageResetDataToJSON = exports.AdminPageResetDataFromJSONTyped = exports.AdminPageResetDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminPageResetDataFromJSON(json) {
    return AdminPageResetDataFromJSONTyped(json, false);
}
exports.AdminPageResetDataFromJSON = AdminPageResetDataFromJSON;
function AdminPageResetDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'siteId': !(0, runtime_1.exists)(json, 'siteId') ? undefined : json['siteId'],
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'layoutId': !(0, runtime_1.exists)(json, 'layoutId') ? undefined : json['layoutId'],
        'urlFragment': !(0, runtime_1.exists)(json, 'urlFragment') ? undefined : json['urlFragment'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'sitemap': !(0, runtime_1.exists)(json, 'sitemap') ? undefined : json['sitemap'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'createdBy': !(0, runtime_1.exists)(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTs': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (new Date(json['createdTs'])),
        'createdTsRaw': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (json['createdTs']),
        'modifiedBy': !(0, runtime_1.exists)(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedTs': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (new Date(json['modifiedTs'])),
        'modifiedTsRaw': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (json['modifiedTs']),
        'layoutFile': !(0, runtime_1.exists)(json, 'layoutFile') ? undefined : json['layoutFile'],
        'lockedBy': !(0, runtime_1.exists)(json, 'lockedBy') ? undefined : json['lockedBy'],
        'lockedEnd': !(0, runtime_1.exists)(json, 'lockedEnd') ? undefined : (new Date(json['lockedEnd'])),
        'lockedEndRaw': !(0, runtime_1.exists)(json, 'lockedEnd') ? undefined : (json['lockedEnd']),
        'isConnectedWithLayout': !(0, runtime_1.exists)(json, 'isConnectedWithLayout') ? undefined : json['isConnectedWithLayout'],
        'folderParameters': !(0, runtime_1.exists)(json, 'folderParameters') ? undefined : json['folderParameters'],
        'menu': !(0, runtime_1.exists)(json, 'menu') ? undefined : (0, _1.MenusFromJSON)(json['menu']),
        'skeleton': !(0, runtime_1.exists)(json, 'skeleton') ? undefined : (json['skeleton'].map(_1.BoxFromJSON)),
    };
}
exports.AdminPageResetDataFromJSONTyped = AdminPageResetDataFromJSONTyped;
function AdminPageResetDataToJSON(value) {
    return AdminPageResetDataToJSONTyped(value, false);
}
exports.AdminPageResetDataToJSON = AdminPageResetDataToJSON;
function AdminPageResetDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'siteId': value.siteId,
        'folderId': value.folderId,
        'layoutId': value.layoutId,
        'urlFragment': value.urlFragment,
        'url': value.url,
        'label': value.label,
        'title': value.title,
        'sitemap': value.sitemap,
        'active': value.active,
        'createdBy': value.createdBy,
        'createdTs': value.createdTs === undefined ? undefined : (value.createdTs.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedTs': value.modifiedTs === undefined ? undefined : (value.modifiedTs.toISOString()),
        'layoutFile': value.layoutFile,
        'lockedBy': value.lockedBy,
        'lockedEnd': value.lockedEnd === undefined ? undefined : (value.lockedEnd.toISOString()),
        'isConnectedWithLayout': value.isConnectedWithLayout,
        'folderParameters': value.folderParameters,
        'menu': (0, _1.MenusToJSON)(value.menu),
        'skeleton': value.skeleton === undefined ? undefined : (value.skeleton.map(_1.BoxToJSON)),
    };
}
exports.AdminPageResetDataToJSONTyped = AdminPageResetDataToJSONTyped;
