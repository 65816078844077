import { ListSitesRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export { SiteListResult } from '@rtl/api'

export type SiteListPayload = ApiPayload<ListSitesRequest>

export interface SiteState {}

export enum SITE_ACTION {
  LIST = 'fetchSiteList'
}

export enum SITE_GETTER {
  API = 'api'
}

export const SITE_NAMESPACE = 'site'
