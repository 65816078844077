
import { ListDefaultContentsContentTypesEnum } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentListPayload } from '../../store'
import CelebrityCardList from '../components/celebrity/CelebrityCardList.vue'
import { FetchContentListMixin, GetPageMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Celeb lista',
  groups: {
    style: 'Beállítás',
    data: 'Adatok'
  },
  defaultGroup: 'style',
  fields: {
    ...widgetMetaFields,
    length: {
      type: Number,
      default: 12,
      props: {
        placeholder: 'Darabszám',
        min: 1
      }
    },
    showSlider: {
      type: Boolean,
      default: true,
      props: {
        label: 'Lapozó'
      }
    },
    showTitle: {
      type: Boolean,
      default: true,
      props: {
        label: 'Cím'
      }
    },
    showFadeout: {
      type: Boolean,
      default: true,
      props: {
        label: 'Sötétítés'
      }
    },
    payload: {
      group: 'data',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.Celeb, ListDefaultContentsContentTypesEnum.Character]
      }
    }
  }
}

@Options({
  name: 'CelebrityListWidget',
  components: {
    CelebrityCardList
  }
})
export default class CelebrityListWidget extends mixins(GetPageMixin, WidgetMixin, FetchContentListMixin) {
  @Prop({ type: Number, default: 12 })
  readonly length!: number

  @Prop({ type: Boolean, default: false })
  readonly lined!: boolean

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean

  @Prop({ type: Number, default: 4 })
  readonly defaultCol!: number

  @Prop({ type: Boolean, default: false })
  readonly autoCols!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showSlider!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showTitle!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showFadeout!: boolean

  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly payload!: Partial<ContentListPayload>

  get cols () {
    if (this.autoCols) {
      return [6, 5, 4, 3, 2].sort((a, b) => {
        const aDiff = Math.ceil(this.length / a) * a - this.length
        const bDiff = Math.ceil(this.length / b) * b - this.length
        return aDiff - bDiff
      })[0]
    }
    return this.defaultCol
  }

  get contentListPayload (): ContentListPayload {
    return {
      folderIds: !this.payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.Celeb, ListDefaultContentsContentTypesEnum.Character],
      limit: this.length,
      ...this.payload
    }
  }
}
