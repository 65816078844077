import { ValidationError } from './'

const MESSAGE_MAP: Record<number, string> = {
  1: 'Ismeretlen hiba',
  206001: 'Kérjük véglegesítse regisztrációját',
  400006: 'Érvénytelen paraméter érték',
  400009: 'Validációs hiba',
  403042: 'Hibás belépési adatok'
}

export class GigyaError extends Error {
  readonly name = 'GigyaError'
  readonly statusCode!: number
  readonly statusReason!: string
  readonly errorCode!: number
  readonly errorDetails!: string
  readonly errorMessage!: string
  readonly validationErrors!: Array<ValidationError>

  constructor (
    statusCode: number,
    statusReason: string,
    errorCode?: number,
    errorDetails?: string,
    errorMessage?: string,
    validationErrors?: Array<ValidationError>
  ) {
    super(MESSAGE_MAP[errorCode || 0] || `${errorMessage}: ${errorDetails}`)
    this.statusCode = statusCode
    this.statusReason = statusReason
    this.errorCode = errorCode || 1
    this.errorDetails = errorDetails || ''
    this.errorMessage = errorMessage || ''
    this.validationErrors = validationErrors || []
  }
}

export class LoginError extends Error {
  readonly errorMessage?: string

  constructor () {
    super()
    this.errorMessage = 'Hibás belépési adatok'
  }
}