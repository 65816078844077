import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_long_video_widget_block = _resolveComponent("long-video-widget-block")!

  return (_openBlock(), _createBlock(_component_long_video_widget_block, _mergeProps({
    contents: _ctx.contentList?.items,
    decorators: _ctx.payload?.decorators,
    title: _ctx.title,
    "btn-label": _ctx.btnLabel,
    "btn-url": _ctx.btnUrl
  }, _ctx.widgetStyleBinding), null, 16, ["contents", "decorators", "title", "btn-label", "btn-url"]))
}