import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_celebrity_card_list = _resolveComponent("celebrity-card-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.contentList?.items.length)
    ? (_openBlock(), _createBlock(_component_widget_block, {
        key: 0,
        title: _ctx.title,
        "show-divider": !!_ctx.title,
        "btn-label": _ctx.btnLabel,
        "btn-url": _ctx.btnUrl,
        "show-slider": _ctx.showSlider
      }, {
        default: _withCtx(() => [
          _createVNode(_component_celebrity_card_list, {
            contents: _ctx.contentList?.items,
            "show-slider": _ctx.showSlider,
            "show-title": _ctx.showTitle,
            "show-fadeout": _ctx.showFadeout,
            lined: _ctx.lined,
            small: _ctx.small,
            cols: _ctx.cols
          }, null, 8, ["contents", "show-slider", "show-title", "show-fadeout", "lined", "small", "cols"])
        ]),
        _: 1
      }, 8, ["title", "show-divider", "btn-label", "btn-url", "show-slider"]))
    : _createCommentVNode("", true)
}