import { Options, Prop, mixins } from 'vue-property-decorator'
import { ContentData } from '@rtl/api'
import ContentCardParamsMixin from './ContentCardParamsMixin'
import ContentDecoratorMixin from './ContentDecoratorMixin'

@Options({
  name: 'ContentCardListMixin'
})
export default class ContentCardMixin extends mixins(ContentDecoratorMixin, ContentCardParamsMixin) {
  @Prop({ type: Array, required: true })
  readonly contents!: Array<ContentData>

  @Prop({ type: Boolean, default: false })
  readonly showSlider!: boolean
}
