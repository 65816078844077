import { Directive } from '@vue/runtime-core'

const eventHandlerMap: Map<string, EventListenerOrEventListenerObject> = new Map()

const DragScrollDirective: Directive = {
  mounted: function (element: HTMLElement) {
    if (typeof process === 'undefined' || !(process as any).server) {
      let isMouseDown = false
      let isMouseMove = false
      let lastClientX = 0
      let mouseDownStart = 0
      const dragTolerance = 5

      element.style.cursor = 'grab'

      const mouseDownEventHandler = (event: any) => {
        isMouseDown = true
        event.preventDefault()
        lastClientX = event.clientX
        mouseDownStart = lastClientX
      }

      const mouseLeaveEventHandler = () => {
        isMouseDown = false
        isMouseMove = false
        setPointerEventVisibility(element, true)
      }

      const mouseUpEventHandler = () => {
        isMouseDown = false
        isMouseMove = false
        setPointerEventVisibility(element, true)
      }

      const mouseMoveEventHandler = (event: any) => {
        const dragSize = Math.abs(event.clientX - mouseDownStart)
        if (!isMouseDown) {
          return
        } else if (!isMouseMove) {
          if (dragSize > dragTolerance) {
            setPointerEventVisibility(element, false)
            isMouseMove = true
          }
        }
        element.scrollLeft -= -lastClientX + (lastClientX = event.clientX)
      }

      eventHandlerMap.set('mousedown', mouseDownEventHandler)
      eventHandlerMap.set('mouseleave', mouseLeaveEventHandler)
      eventHandlerMap.set('mouseup', mouseUpEventHandler)
      eventHandlerMap.set('mousemove', mouseMoveEventHandler)
      for (const [key, value] of eventHandlerMap) {
        element.addEventListener(key, value)
      }
    }
  },

  unmounted: function (element: HTMLElement) {
    for (const [key, value] of eventHandlerMap) {
      element.removeEventListener(key, value)
    }
  }
}

const setPointerEventVisibility = (parentElement: HTMLElement, isVisible: boolean) => {
  const elements = parentElement.getElementsByTagName('a')
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.pointerEvents = isVisible ? '' : 'none'
  }
}

export default DragScrollDirective
