
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentCardListMixin from '../../mixins/ContentCardListMixin'
import ContentCardImg from './ContentCardImg.vue'

@Options({
  name: 'ContentCardGrid',
  components: {
    ContentCardImg
  }
})
export default class ContentCardGrid extends mixins(ContentCardListMixin) {
  @Prop({ type: Boolean, default: false })
  private compact!: boolean

  @Prop({ type: Boolean, default: false })
  private compactInverse!: boolean
}
