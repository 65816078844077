
import { MenuItem, Menus } from '@rtl/api'
import { mixins, Options } from 'vue-property-decorator'

import { GetPageMixin } from '../mixins'

@Options({
  name: 'SocialMediaLinks'
})
export default class SocialMediaLinks extends mixins(GetPageMixin) {
  get menu (): Menus {
    return this.page?.menu || {}
  }

  get links () {
    const menu: Array<MenuItem> = this.menu?.socialMediaMenu?.menuItems || []
    return menu.reduce<Record<string, string>>((links, item) => {
      if (item.label && item.targetUrl) {
        links[item.label] = item.targetUrl
      }
      return links
    }, {})
  }
}
