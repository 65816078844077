/* eslint-disable */
import { Options, Vue } from 'vue-property-decorator'

@Options({
    name: 'FormValidateMixin'
})
export default class FormValidateMixin extends Vue {
    formErrors: { field: string, msg: string }[] = []

    addFormError(error: { field: string, msg: string }): void {
        this.formErrors.push(error)
    }

    hasFormError (): boolean {
        return !!this.formErrors.length
    }

    clearFormErrors (): void {
        this.formErrors = []
    }

    hasFieldError (field: string): boolean {
        return !!this.formErrors.find(e => e.field === field)
    }

    getFieldError (field: string): { field: string, msg: string } | null {
        return this.formErrors.find(e => e.field === field) || null
    }

    getFieldErrorValue (field: string): string | null {
        return this.getFieldError(field)?.msg || null
    }

    validateEmail(emailValue: string, fieldName: string) {
        const REGEX_EMAIL = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$'
        const value = emailValue.trim()

        if (!value.length) {
            this.addFormError({ field: fieldName, msg: 'Az e-mail cím kitöltése kötelező' })
            return
        }

        if (!value.match(new RegExp(REGEX_EMAIL, 'u'))) {
            this.addFormError({ field: fieldName, msg: 'Érvénytelen e-mail cím' })
        }
    }

    validatePassword(passwordValue: string, fieldName: string, checkStrength = false) {
        const value = passwordValue.trim()

        if (!value.length) {
            this.addFormError({ field: fieldName, msg: 'A jelszó kitöltése kötelező' })
            return
        }

        if (checkStrength && value.length < 8) {
            this.addFormError({ field: fieldName, msg: 'A jelszónak legalább 8 karaktert szükséges tartalmaznia' })
            return
        }

        if (checkStrength && (!value.match(/[a-zA-Z]+/u) || !value.match(/[0-9]+/u) || !value.match(/[^a-zA-Z0-9]+/u))) {
            this.addFormError({ field: fieldName, msg: 'A jelszónak legalább egy betűt, egy számot és egy speciális karaktert kell tartalmaznia' })
        }
    }

    validatePasswordConfirm(confirmValue: string, passwordValue: string, fieldName: string) {
        const value = confirmValue.trim()
        const pwValue = passwordValue.trim()

        if (!value.length) {
            this.addFormError({ field: fieldName, msg: 'A jelszó megerősítés kitöltése kötelező' })
            return
        }

        if (value !== pwValue) {
            this.addFormError({ field: fieldName, msg: 'A jelszó megerősítés nem egyezik a megadott jelszóval' })
        }
    }

    validateRequiredString(strValue: string, fieldName: string, msg: string) {
        const value = strValue.trim()
        if (!value.length) {
            this.addFormError({ field: fieldName, msg: msg })
        }
    }

    validateRequiredBoolean(value: boolean, fieldName: string, msg: string) {
        if (!value) {
            this.addFormError({ field: fieldName, msg: msg })
        }
    }

    checkProperties(obj: { [key: string]: never }, skipKeys?: string[]): boolean {
        const skippedKeys = skipKeys || []
        for (const key in obj) {
            if (skippedKeys.includes(key)) {
                continue
            }
            if (obj[key] === 0 || obj[key] === '' || obj[key] === false) {
                return false
            } else if (typeof obj[key] === 'object' && !this.checkProperties(obj[key])) {
                return false
            }
        }
        return true
    }
}
