import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgeLimit18 = _resolveComponent("AgeLimit18")!
  const _component_AgeLimit16 = _resolveComponent("AgeLimit16")!
  const _component_AgeLimit15 = _resolveComponent("AgeLimit15")!
  const _component_AgeLimit12 = _resolveComponent("AgeLimit12")!
  const _component_AgeLimit7 = _resolveComponent("AgeLimit7")!
  const _component_AgeLimit0 = _resolveComponent("AgeLimit0")!

  return (_ctx.ageLimit >= 18)
    ? (_openBlock(), _createBlock(_component_AgeLimit18, { key: 0 }))
    : (_ctx.ageLimit >= 16)
      ? (_openBlock(), _createBlock(_component_AgeLimit16, { key: 1 }))
      : (_ctx.ageLimit === 15)
        ? (_openBlock(), _createBlock(_component_AgeLimit15, { key: 2 }))
        : (_ctx.ageLimit >= 12)
          ? (_openBlock(), _createBlock(_component_AgeLimit12, { key: 3 }))
          : (_ctx.ageLimit >= 7)
            ? (_openBlock(), _createBlock(_component_AgeLimit7, { key: 4 }))
            : (_openBlock(), _createBlock(_component_AgeLimit0, { key: 5 }))
}