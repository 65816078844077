
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'

import MainLayout from './MainLayout.vue'

@Options({
  name: 'DynamicLayout',
  components: {
    rtl_hu: MainLayout
  }
})
export default class DynamicLayout extends Vue {
  @Prop({
    type: String,
    default: 'rtl_hu'
  })
  readonly defaultLayout!: string

  currentLayout?: string | null = null

  get layout () {
    return (this.currentLayout === '' ? 'div' : this.currentLayout) || this.defaultLayout
  }

  @Watch('$root.layout', { immediate: true })
  updateLayout () {
    this.currentLayout = (this.$root as any)?.layout || null
  }
}
