"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminLayoutPutDataToJSONTyped = exports.AdminLayoutPutDataToJSON = exports.AdminLayoutPutDataFromJSONTyped = exports.AdminLayoutPutDataFromJSON = void 0;
const _1 = require("./");
function AdminLayoutPutDataFromJSON(json) {
    return AdminLayoutPutDataFromJSONTyped(json, false);
}
exports.AdminLayoutPutDataFromJSON = AdminLayoutPutDataFromJSON;
function AdminLayoutPutDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (0, _1.AdminLayoutDataFromJSON)(json['data']),
    };
}
exports.AdminLayoutPutDataFromJSONTyped = AdminLayoutPutDataFromJSONTyped;
function AdminLayoutPutDataToJSON(value) {
    return AdminLayoutPutDataToJSONTyped(value, false);
}
exports.AdminLayoutPutDataToJSON = AdminLayoutPutDataToJSON;
function AdminLayoutPutDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (0, _1.AdminLayoutDataToJSON)(value.data),
    };
}
exports.AdminLayoutPutDataToJSONTyped = AdminLayoutPutDataToJSONTyped;
