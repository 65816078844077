import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "widget-block__featured-image-block"
}
const _hoisted_2 = { class: "lead" }
const _hoisted_3 = { class: "lead__row" }
const _hoisted_4 = { class: "lead__main" }
const _hoisted_5 = { class: "lead__col" }
const _hoisted_6 = { class: "lead__col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_content_card = _resolveComponent("content-card")!
  const _component_banner_widget = _resolveComponent("banner-widget")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_openBlock(), _createBlock(_component_widget_block, {
    "background-url": _ctx.backgroundUrl,
    class: _normalizeClass({ 'widget-block--featured': _ctx.backgroundUrl })
  }, {
    default: _withCtx(() => [
      (_ctx.backgroundUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Image, {
              class: "widget-block__featured-image",
              "image-url": _ctx.backgroundUrl
            }, null, 8, ["image-url"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.bigContent)
              ? (_openBlock(), _createBlock(_component_content_card, _mergeProps({ key: 0 }, _ctx.decorateContent(_ctx.bigContent), {
                  "lead-cropping": false,
                  size: 'large'
                }), null, 16))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.bannerZone)
              ? (_openBlock(), _createBlock(_component_banner_widget, {
                  key: 0,
                  zone: { mobile: `m_${_ctx.bannerZone}`, tablet: `t_${_ctx.bannerZone}`, app: `app_${_ctx.bannerZone}` },
                  "label-position": "bottom"
                }, null, 8, ["zone"]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondColContents, (content, i) => {
              return (_openBlock(), _createBlock(_component_content_card, _mergeProps({
                key: i,
                "show-image": false,
                "show-border": false,
                "show-tag": true,
                "show-duration": true,
                "show-gallery-badge": true,
                "show-lead": false,
                "show-fadeout": false
              }, _ctx.decorateContent(content)), null, 16))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thirdColContents, (content, i) => {
              return (_openBlock(), _createBlock(_component_content_card, _mergeProps({
                key: i,
                "show-image": true,
                "show-border": false,
                "show-tag": true,
                "show-duration": true,
                "show-gallery-badge": true,
                "show-lead": false,
                "show-fadeout": false
              }, _ctx.decorateContent(content)), null, 16))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["background-url", "class"]))
}