export default {
  site: {
    title: 'rtl'
  },

  page: {
    no_permission: '403',
    not_found: '404',
    index: 'Kezdőlap',
    login: 'Bejelentkezés'
  },

  locale: {
    en: 'Angol',
    hu: 'Magyar'
  },

  common: {
    label_locale: 'Nyelv',
    label_yes: 'Igen',
    label_no: 'Nem',
    label_loading: 'Betöltés..',
    label_search: 'Keresés..',
    title_confirm: 'Megerősítés..',
    field_id: '#',
    field_created_at: 'Létrehozva',
    field_updated_at: 'Utoljára frissítve',
    field_deleted_at: 'Törölve',
    field_actions: 'Műveletek',
    btn_create: 'Új',
    btn_edit: 'Szerkesztés',
    btn_delete: 'Törlés',
    btn_back: 'Vissza',
    btn_close: 'Bezárás',
    btn_save: 'Mentés',
    btn_confirm: 'Megerősítés',
    btn_show: 'Megtekintés',
    btn_details: 'Részletek',
    btn_actions: 'Műveletek',
    btn_filters: 'Szűrők',
    status_active: 'Aktív',
    status_inactive: 'Inaktív',
    msg_no_items: 'Nincs megjeleníthető elem.'
  },

  pagination: {
    label_prev: 'Előző',
    label_next: 'Következő',
    total: 'Összesen {total} bejegyzés'
  },

  auth: {
    label_not_found: 'A keresett oldal "/{path}" nem található.',
    label_no_permissions: 'Nincs jogosultságod a kért oldal betöltéséhez.',
    btn_login: 'Bejelentkezés',
    btn_logout: 'Kijelentkezés',
    btn_login_as_another: 'Bejelentkezés másik felhasználóval',
    role_guest: 'Vendég',
    role_user: 'Felhasználó'
  },

  user: {
    title_users: 'Felhasználók',
    title_create: 'Felhasználó létrehozása',
    title_update: 'Felhasználó szerkesztése',
    title_delete: 'Felhasználó törlése',
    field_full_name: 'Név',
    field_first_name: 'Keresztnév',
    field_last_name: 'Vezetéknév',
    field_email: 'E-mail cím',
    field_password: 'Jelszó',
    field_password_confirmation: 'Jelszó megerősítése',
    field_password_old: 'Jelenlegi jelszó',
    field_status: 'Státusz',
    field_role: 'Szerepkör',
    btn_create: 'Új felhasználó',
    msg_delete: 'Biztos, hogy törlöd a(z) "{email}" felhasználót?'
  }
}
