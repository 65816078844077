
import { TvProgramByProgDays, TvProgramItem } from '@rtl/api'
import { isAfter } from 'date-fns'
import { Options, Prop, Vue } from 'vue-property-decorator'

import Image from '../Image.vue'
import TvProgramItemCard from './TvProgramItemCard.vue'

@Options({
  name: 'TvProgramCard',
  components: {
    Image,
    TvProgramItemCard
  }
})
export default class TvProgramCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly tvProgram!: TvProgramByProgDays

  @Prop({ type: Number, default: 3 })
  readonly itemLength!: number

  get channelLogoUrl (): string | null {
    return this.tvProgram.logoUrl || null
  }

  get items (): TvProgramItem[] {
    if (this.tvProgram?.progDays && this.tvProgram.progDays[0].programs) {
      const items = this.tvProgram.progDays[0].programs || []

      return items.filter(item => {
        const programDate = item.progDate
        const programLength = parseInt(item.progLength || '0')

        if (!programDate) {
          return false
        }

        const programStart = new Date(programDate)
        const programEnd = new Date(programStart).setMinutes(programStart.getMinutes() + programLength)

        return isAfter(programStart, new Date()) || isAfter(programEnd, new Date())
      }).slice(0, this.itemLength)
    }

    return []
  }
}
