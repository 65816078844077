
import { mixins, Options } from 'vue-property-decorator'

import ContentCardMixin from '../../mixins/ContentCardMixin'
import GalleryFull from '../icons/GalleryFull.vue'
import Play from '../icons/Play.vue'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'
import Tag from '../Tag.vue'

@Options({
  name: 'ContentCardLeftImg',
  components: {
    Image,
    NavLink,
    Play,
    Tag,
    GalleryFull
  }
})
export default class ContentCardLeftImg extends mixins(ContentCardMixin) {}
