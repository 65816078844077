"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OembedResultToJSONTyped = exports.OembedResultToJSON = exports.OembedResultFromJSONTyped = exports.OembedResultFromJSON = exports.OembedResultTypeEnum = void 0;
/**
* @export
* @enum {string}
*/
var OembedResultTypeEnum;
(function (OembedResultTypeEnum) {
    OembedResultTypeEnum["Video"] = "video";
    OembedResultTypeEnum["Rich"] = "rich";
})(OembedResultTypeEnum = exports.OembedResultTypeEnum || (exports.OembedResultTypeEnum = {}));
function OembedResultFromJSON(json) {
    return OembedResultFromJSONTyped(json, false);
}
exports.OembedResultFromJSON = OembedResultFromJSON;
function OembedResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': json['version'],
        'type': json['type'],
        'thumbnailUrl': json['thumbnail_url'],
        'thumbnailWidth': json['thumbnail_width'],
        'thumbnailHeight': json['thumbnail_height'],
        'title': json['title'],
        'html': json['html'],
        'width': json['width'],
        'height': json['height'],
        'url': json['url'],
        'providerName': json['provider_name'],
        'provderUrl': json['provder_url'],
    };
}
exports.OembedResultFromJSONTyped = OembedResultFromJSONTyped;
function OembedResultToJSON(value) {
    return OembedResultToJSONTyped(value, false);
}
exports.OembedResultToJSON = OembedResultToJSON;
function OembedResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'type': value.type,
        'thumbnail_url': value.thumbnailUrl,
        'thumbnail_width': value.thumbnailWidth,
        'thumbnail_height': value.thumbnailHeight,
        'title': value.title,
        'html': value.html,
        'width': value.width,
        'height': value.height,
        'url': value.url,
        'provider_name': value.providerName,
        'provder_url': value.provderUrl,
    };
}
exports.OembedResultToJSONTyped = OembedResultToJSONTyped;
