import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_hero_widget_block = _resolveComponent("tv-show-hero-widget-block")!

  return (_openBlock(), _createBlock(_component_tv_show_hero_widget_block, {
    title: _ctx.site?.name,
    info: _ctx.info,
    "logo-url": _ctx.logo,
    "cover-img-url": _ctx.cover,
    "cta-btn-label": 'Teljes adások',
    "cta-btn-url": _ctx.fullBroadcasts ? _ctx.site?.rtlMostProgramUrl : null,
    "extra-btn-label": _ctx.extraBtnLabel,
    "extra-btn-url": _ctx.extraBtnUrl,
    menu: _ctx.menu
  }, null, 8, ["title", "info", "logo-url", "cover-img-url", "cta-btn-url", "extra-btn-label", "extra-btn-url", "menu"]))
}