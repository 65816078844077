import { mixins, Options, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import GetPageMixin from './GetPageMixin'
import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentPayload, ContentResult } from '../../store'

@Options({
  name: 'FetchContentItemMixin'
})
export default class FetchContentItemMixin extends mixins(GetPageMixin) {
  @Action(CONTENT_ACTION.ITEM, { namespace: CONTENT_NAMESPACE })
  storeFetchContent!: (payload: ContentPayload) => Promise<ContentResult>

  get content (): ContentResult['data'] | null {
    if (this.contentPayload) {
      return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.ITEM], this.contentPayload)?.data || null
    }
    return null
  }

  get contentPayload (): ContentPayload | null {
    const contentId = this.page?.contentId
    return contentId ? { contentId } : null
  }

  @Watch('contentPayload', { deep: true })
  async fetchContent () {
    return this.contentPayload ? this.storeFetchContent(this.contentPayload) : null
  }

  async serverPrefetch () {
    await this.fetchContent()
    return this.content // WTF? Without this line, the SSR not rendering properly on "StaticPage".
  }

  async mounted () {
    await this.fetchContent()
  }
}
