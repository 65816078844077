import { RouteLocationRaw } from 'vue-router'

export function isExternalUrl (url: RouteLocationRaw | string) {
  if (typeof url === 'string' || url instanceof String) {
    return /^mailto:|([a-zA-Z]+:)?\/\//.test(url as string)
  }

  return false
}

export function fixProtocoll<T extends RouteLocationRaw | string> (url: T) {
  if ((typeof url === 'string' || url instanceof String) && url.startsWith('//')) {
    return `https:${url as string}`
  }

  return url
}

export function fixLinkTarget(src: string) {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  return mobileAppRegexp.test(url) ? src.replaceAll('target="_blank"', '') : src
} 

export const isValidUrl = (str: string): boolean => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const mobileAppRegexp = /^(capacitor|https?):\/\/app.rtl.hu(:\d+)?\//

export function getPublicUrl () {
  if (typeof window === 'undefined') {
    return ''
  }
  const src = window.location.href
  const url = new URL(src.replace(mobileAppRegexp, 'https://rtl.hu/'))
  const params = url.searchParams
  for (const key of params.keys()) {
    if (key.toLowerCase().startsWith('utm_')) {
      params.delete(key)
    }
  }
  url.search = params.toString()

  return url.toString()
}
