import { GetTvprogramRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export { TvProgramResult } from '@rtl/api'

export type TvProgramPayload = ApiPayload<GetTvprogramRequest>

export interface TvProgramState {}

export enum TV_PROGRAM_ACTION {
  LIST = 'fetchTvProgramList'
}

export enum TV_PROGRAM_GETTER {
  API = 'api'
}

export const TV_PROGRAM_NAMESPACE = 'tvprogram'
