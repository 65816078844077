"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteDataToJSONTyped = exports.SiteDataToJSON = exports.SiteDataFromJSONTyped = exports.SiteDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SiteDataFromJSON(json) {
    return SiteDataFromJSONTyped(json, false);
}
exports.SiteDataFromJSON = SiteDataFromJSON;
function SiteDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, _1.SiteTypesFromJSON)(json['type']),
        'subtype': !(0, runtime_1.exists)(json, 'subtype') ? undefined : json['subtype'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'rtlMostProgramUrl': !(0, runtime_1.exists)(json, 'rtlMostProgramUrl') ? undefined : json['rtlMostProgramUrl'],
        'seasons': !(0, runtime_1.exists)(json, 'seasons') ? undefined : json['seasons'],
        'parameters': !(0, runtime_1.exists)(json, 'parameters') ? undefined : json['parameters'],
        'views': !(0, runtime_1.exists)(json, 'views') ? undefined : json['views'],
        'channel': !(0, runtime_1.exists)(json, 'channel') ? undefined : json['channel'],
        'searchMode': !(0, runtime_1.exists)(json, 'searchMode') ? undefined : json['searchMode'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
    };
}
exports.SiteDataFromJSONTyped = SiteDataFromJSONTyped;
function SiteDataToJSON(value) {
    return SiteDataToJSONTyped(value, false);
}
exports.SiteDataToJSON = SiteDataToJSON;
function SiteDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'folderId': value.folderId,
        'name': value.name,
        'type': (0, _1.SiteTypesToJSON)(value.type),
        'subtype': value.subtype,
        'url': value.url,
        'rtlMostProgramUrl': value.rtlMostProgramUrl,
        'seasons': value.seasons,
        'parameters': value.parameters,
        'views': value.views,
        'channel': value.channel,
        'searchMode': value.searchMode,
        'label': value.label,
    };
}
exports.SiteDataToJSONTyped = SiteDataToJSONTyped;
