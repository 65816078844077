"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentListUrlToJSONTyped = exports.ContentListUrlToJSON = exports.ContentListUrlFromJSONTyped = exports.ContentListUrlFromJSON = void 0;
const runtime_1 = require("../runtime");
function ContentListUrlFromJSON(json) {
    return ContentListUrlFromJSONTyped(json, false);
}
exports.ContentListUrlFromJSON = ContentListUrlFromJSON;
function ContentListUrlFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dataPayload': !(0, runtime_1.exists)(json, 'dataPayload') ? undefined : json['dataPayload'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
    };
}
exports.ContentListUrlFromJSONTyped = ContentListUrlFromJSONTyped;
function ContentListUrlToJSON(value) {
    return ContentListUrlToJSONTyped(value, false);
}
exports.ContentListUrlToJSON = ContentListUrlToJSON;
function ContentListUrlToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dataPayload': value.dataPayload,
        'url': value.url,
    };
}
exports.ContentListUrlToJSONTyped = ContentListUrlToJSONTyped;
