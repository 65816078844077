
import { Inject, mixins, Options, Provide, Watch } from 'vue-property-decorator'

import { AdStore } from '../../utils'
import FacebookModal from '../components/marketing/FacebookModal.vue'
import PushNotification from '../components/marketing/PushNotification.vue'
import Loader from '../components/Loader.vue'
import { GetPageMixin } from '../mixins'
import BannerWidget from '../widgets/BannerWidget.vue'
import ExtraInsert from './ExtraInsert.vue'
import MainFooter from './MainFooter.vue'
import MainHeader from './MainHeader.vue'

@Options({
  name: 'MainLayout',
  components: {
    MainHeader,
    MainFooter,
    BannerWidget,
    Loader,
    ExtraInsert,
    FacebookModal,
    PushNotification
  }
})
export default class MainLayout extends mixins(GetPageMixin) {
  @Provide({ reactive: true })
  marketingManager = {
    priority: [
      'vote-banner',
      'facebook-modal',
      'push-notification'
    ]
  }

  @Inject({ from: 'adStore' })
  readonly adStore!: AdStore

  extraHeaderUrl: string | null = null
  showLogoAnimation = false;

  get logoLoading (): boolean {
    return this.showLogoAnimation || this.pageLoading
  }

  @Watch('$route.fullPath')
  startLogoLoading () {
    this.showLogoAnimation = true

    setTimeout(() => {
      this.showLogoAnimation = false
    }, 1300)
  }

  mounted () {
    // For Cross-origin VideoPlayer
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data === 'getMedia') {
        event.source?.postMessage(this.adStore?.media || 'web', { targetOrigin: event.origin })
      }
    }, false)

    this.startLogoLoading()
  }

  @Watch('page', { immediate: true, deep: true })
  onPageUpdate () {
    const url = this.page?.folderParameters?.appearance?.extraHeaderUrl || null
    this.extraHeaderUrl = url ? `${url}?ts=${Math.round(Date.now() / (1000 * 60))}` : null
  }
}
