import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "minute-by-minute-lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_d = _resolveComponent("i18n-d")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedContents, (content, i) => {
      return (_openBlock(), _createElementBlock("li", {
        key: i,
        class: "minute-by-minute-lead__item"
      }, [
        _createVNode(_component_i18n_d, {
          tag: "span",
          value: _ctx.getContentReleaseDate(content),
          format: "time"
        }, null, 8, ["value"]),
        _createTextVNode(" – " + _toDisplayString(_ctx.getContentTitle(content)), 1)
      ]))
    }), 128))
  ]))
}