
import { mixins, Options, Prop } from 'vue-property-decorator'
import Close from '../icons/Close.vue'
import MarketingToolMixin from './MarketingToolMixin'

@Options({
  name: 'VoteBanner',
  components: {
    Close
  }
})
export default class VoteBanner extends mixins(MarketingToolMixin) {
  @Prop({
    type: String,
    required: true
  })
  readonly link!: string

  @Prop({
    type: String,
    required: true
  })
  readonly title!: string

  @Prop({
    type: String
  })
  readonly logo: string | undefined

  @Prop({
    type: String
  })
  readonly background: string | undefined

  @Prop({
    type: String
  })
  readonly color: string | undefined

  @Prop({
    type: String
  })
  readonly voteId: string | undefined

  @Prop({
    type: String
  })
  readonly voteGroupId: string | undefined

  pipeKey = 'vote-banner'
  storageKey = 'vote-banner-close'
  lifetime = 24 * 60 * 60 * 1000 // 1 day
  promotionName = 'szavazas-banner'

  async mounted () {
    this.$nextTick(() => {
      if (this.isAvailable) {
        this.measure('view', this.promotionName)
      }
    })
  }
}
