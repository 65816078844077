
import { TvProgramByProgDays } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import TvProgramCardList from '../components/tv-program/TvProgramCardList.vue'
import { FetchTvProgramListMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Műsorújság',
  fields: {
    ...widgetMetaFields,
    cols: {
      label: 'Csatorna darabszám',
      type: Number,
      default: 3,
      props: {
        min: 1
      }
    },
    itemLength: {
      label: 'Műsor darabszám',
      type: Number,
      default: 3,
      props: {
        min: 1
      }
    }
  }
}

@Options({
  name: 'TvProgramListWidget',
  components: {
    TvProgramCardList
  }
})
export default class TvProgramListWidget extends mixins(WidgetMixin, FetchTvProgramListMixin) {
  @Prop({
    type: Number,
    default: 3
  })
  readonly cols!: number

  @Prop({
    type: Number,
    default: 3
  })
  readonly itemLength!: number

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showSlider!: boolean

  get channels (): Array<TvProgramByProgDays> {
    return this.programList
      ? Object.keys(this.programList).map(channel => this.programList?.[channel])
      : []
  }
}
