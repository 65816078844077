import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_totem_list = _resolveComponent("tv-show-totem-list")!
  const _component_widget_block = _resolveComponent("widget-block")!
  const _component_tv_show_logo_card_list = _resolveComponent("tv-show-logo-card-list")!

  return (_ctx.totem)
    ? _withDirectives((_openBlock(), _createBlock(_component_widget_block, {
        key: 0,
        title: _ctx.title,
        "show-divider": !!_ctx.title,
        "show-slider": true && !!_ctx.siteList?.items.length,
        "btn-label": _ctx.btnLabel,
        "btn-url": _ctx.btnUrl,
        "show-button": _ctx.showButton
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tv_show_totem_list, {
            items: _ctx.siteList?.items,
            "show-slider": true && !!_ctx.siteList?.items.length
          }, null, 8, ["items", "show-slider"])
        ]),
        _: 1
      }, 8, ["title", "show-divider", "show-slider", "btn-label", "btn-url", "show-button"])), [
        [_vShow, _ctx.siteList?.items.length]
      ])
    : (_ctx.siteList?.items.length)
      ? (_openBlock(), _createBlock(_component_widget_block, {
          key: 1,
          title: _ctx.title
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tv_show_logo_card_list, {
              items: _ctx.siteList?.items,
              "btn-label": _ctx.btnLabel,
              "btn-url": _ctx.btnUrl
            }, null, 8, ["items", "btn-label", "btn-url"])
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true)
}