import { fixProtocoll } from './url.util'

export const copyToClipboard = (value: string): Promise<unknown> => {
  const textArea = document.createElement('textarea')
  textArea.value = value
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  const successful = document.execCommand('copy')
  document.body.removeChild(textArea)
  return new Promise((resolve, reject) => {
    if (successful) {
      resolve(true)
    } else {
      reject(new Error('Could not copy'))
    }
  })
}

export function runScripts (el: HTMLElement) {
  Array.from(el.getElementsByTagName('script')).forEach(code => {
    if (code.hasAttribute('src')) {
      const script = document.createElement('script')
      Array.from(code.attributes).forEach(attr => {
        if (attr.nodeName === 'src') {
          script.setAttribute(attr.nodeName, fixProtocoll(attr.nodeValue || ''))
        } else {
          script.setAttribute(attr.nodeName, attr.nodeValue || '')
        }
      })
      code.replaceWith(script)
    } else {
      // eslint-disable-next-line no-eval
      eval(code.text)
    }
  })
}

export function runWidgetScripts (el: HTMLElement) {
  Array.from(el.getElementsByTagName('script')).forEach(code => {
    const script = document.createElement('script')
    if (code.hasAttribute('src')) {
      Array.from(code.attributes).forEach(attr => {
        if (attr.nodeName === 'src') {
          script.setAttribute(attr.nodeName, fixProtocoll(attr.nodeValue || ''))
        } else {
          script.async = false
          script.defer = true
        }
      })
    } else {
      script.textContent = code.textContent ? code.textContent : ''
    }
    code.replaceWith(script)
  })
}
