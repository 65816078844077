import { createGtm, GtmPlugin } from '@gtm-support/vue-gtm'
import { createAppCb } from '@rtl/ssr'
import {
  AppState,
  createI18n,
  createStore,
  diversionGuard,
  getGtmConfig,
  RootApp,
  VisibilityObserverDirective,
  VueSocialSharing
} from '@rtl/ui'
import { createHead, VueHeadMixin } from '@unhead/vue'
import { createApp as createClientApp, createSSRApp } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { Store } from 'vuex'

import App from './App.vue'
import { createRouter } from './router'

export default <createAppCb> async function createApp (opts) {
  const DEV_MODE = process.env.NODE_ENV === 'development'
  const factory = opts?.isSSR || opts?.isPrerendered ? createSSRApp : createClientApp
  const router = createRouter(opts?.isSSR)
  const store = createStore(opts?.isPrerendered)

  if (opts?.isSSR) {
    store.commit('setEnv', opts?.ssrContext?.req.app.get('runtimeEnv'))
  }

  if (opts?.beforeApp) {
    await opts.beforeApp({ router, store })
  }

  const diversionUrl = store.getters.getEnv('DIVERSION_URL')
  if (diversionUrl) {
    router.beforeEach(diversionGuard(diversionUrl, false))
  }

  const app = factory(App)

  app.use(router)
  app.use(store)
  app.use(createHead()).mixin(VueHeadMixin)
  app.use(createI18n())
  app.use(VueSocialSharing)
  app.use({
    install: (app) => {
      router.afterEach((to, from) => {
        const referer = from.matched.length ? from : null
        app.config.globalProperties.$routeReferer = referer
      })
    }
  })

  const gtmId = store.getters.getEnv('GTM_ID')

  if (!DEV_MODE && gtmId) {
    app.use(createGtm(getGtmConfig(gtmId)))
  }

  if (!opts?.isSSR) {
    store.commit('user/setUser', await store.getters['user/api'].getUser())
  }

  app.directive('visibility', VisibilityObserverDirective)

  return app
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: Store<AppState>
    $gtm: GtmPlugin
    $root: RootApp | null
    $routeReferer: RouteLocationNormalized | null
  }
}
