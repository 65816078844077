import { Options, Prop, Vue } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'

import WidgetBlock from '../components/widget/WidgetBlock.vue'

export type WidgetMeta = {
  label: string
  groups?: {
    [key: string]: string
  },
  defaultGroup?: string
  fields: {
    [key: string]: {
      label?: string,
      group?: string,
      type: (new (...args: Array<unknown>) => unknown) | string,
      default?: unknown,
      props?: Record<string, unknown>
    }
  },
  timing?: boolean
}

@Options({
  name: 'WidgetMixin',
  components: {
    WidgetBlock
  }
})
export default class WidgetMixin extends Vue {
  @Prop({
    type: String
  })
  readonly title: string | undefined

  @Prop({
    type: [String, Object]
  })
  readonly btnUrl: RouteLocationRaw | undefined

  @Prop({
    type: String,
    default: 'Mutasd mind'
  })
  readonly btnLabel!: string
}

export const widgetMetaFields: WidgetMeta['fields'] = {
  title: {
    type: String,
    props: {
      placeholder: 'Címsor'
    }
  },
  btnUrl: {
    type: String,
    props: {
      placeholder: 'Tovább link (url)'
    }
  },
  btnLabel: {
    type: String,
    props: {
      placeholder: 'Tovább link (szöveg)'
    }
  }
}
