
import { ListDefaultContentsContentTypesEnum } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentListDecoratedPayload, ContentListPayload } from '../../store'
import { shuffle } from '../../utils'
import ContentCardList from '../components/content/ContentCardList.vue'
import { ContentCardParamsMixin, FetchContentListMixin, GetPageMixin } from '../mixins'
import WidgetMixin, { WidgetMeta, widgetMetaFields } from './WidgetMixin'
import WidgetStyleMixin, { widgetStyleFields } from './WidgetStyleMixin'

export const meta: WidgetMeta = {
  label: 'Content lista',
  groups: {
    style: 'Beállítás',
    data: 'Adatok'
  },
  defaultGroup: 'style',
  fields: {
    ...widgetMetaFields,
    length: {
      type: Number,
      default: 4,
      props: {
        placeholder: 'Darabszám',
        min: 2
      }
    },
    showImage: {
      type: Boolean,
      default: true,
      props: {
        label: 'Kép'
      }
    },
    showTitle: {
      type: Boolean,
      default: true,
      props: {
        label: 'Cím'
      }
    },
    showLead: {
      type: Boolean,
      default: true,
      props: {
        label: 'Leírás'
      }
    },
    showTag: {
      type: Boolean,
      default: true,
      props: {
        label: 'Címke'
      }
    },
    showDuration: {
      type: Boolean,
      default: true,
      props: {
        label: 'Video jel'
      }
    },
    showGalleryBadge: {
      type: Boolean,
      default: true,
      props: {
        label: 'Galéria jel'
      }
    },
    showReleaseDate: {
      type: Boolean,
      default: false,
      props: {
        label: 'Dátum'
      }
    },
    showBorder: {
      type: Boolean,
      default: false,
      props: {
        label: 'Elválasztó'
      }
    },
    showSlider: {
      type: Boolean,
      default: false,
      props: {
        label: 'Lapozó'
      }
    },
    payload: {
      group: 'data',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.News]
      }
    },
    ...widgetStyleFields
  }
}

@Options({
  name: 'ContentListWidget',
  components: {
    ContentCardList
  }
})
export default class ContentListWidget extends mixins(GetPageMixin, WidgetMixin, FetchContentListMixin, ContentCardParamsMixin, WidgetStyleMixin) {
  @Prop({
    type: Number,
    default: 4
  })
  readonly length!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly shuffle!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isVertical!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly showSlider!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showButton!: boolean

  @Prop({
    type: Object,
    default: () => ({})
  })
  readonly payload!: Partial<ContentListDecoratedPayload>

  get cols () {
    if (this.showSlider) {
      return 4
    }

    return this.isVertical
      ? 0
      : [4, 3, 2].sort((a, b) => {
          const aDiff = Math.ceil(this.length / a) * a - this.length
          const bDiff = Math.ceil(this.length / b) * b - this.length
          return aDiff - bDiff
        })[0]
  }

  get contents () {
    const items = this.contentList?.items || []

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this.shuffle && (this as any).$routeReferer ? shuffle(items) : items).slice(0, this.length)
  }

  get contentListPayload (): ContentListPayload {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { decorators, ...payload } = this.payload

    return {
      folderIds: !payload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      shuffle: this.shuffle,
      limit: this.length,
      ...payload
    }
  }
}
