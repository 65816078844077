import { Module } from 'vuex'
import { SiteApi } from '@rtl/api'
import { SITE_GETTER, SITE_ACTION, SiteState, SiteListResult, SiteListPayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createSiteModule<RootState> (namespaced = false): Module<SiteState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [SITE_GETTER.API]: (state, getters, rootState, rootGetters) => {
        return new SiteApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [SITE_ACTION.LIST] ({ getters, rootGetters }, payload: SiteListPayload): Promise<SiteListResult> {
        const response = await errorHandler(() => getters[SITE_GETTER.API].listSites({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
