
import { Options, Prop, Vue } from 'vue-property-decorator'

import Exclamation from '../icons/Exclamation.vue'

interface SelectOption {
  value: string
  label: string
  disabled?: boolean
}

@Options({
  name: 'Select',
  components: {
    Exclamation
  }
})
export default class Select extends Vue {
  @Prop({ type: String, default: '' })
  readonly modelValue!: string

  @Prop({ type: String, required: true })
  readonly label!: string

  @Prop({ type: String, default: null })
  readonly errorMsg!: string | null

  @Prop({ type: Array, default: [] })
  readonly options!: SelectOption[]
}
