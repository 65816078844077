
import { Options, Prop, Vue } from 'vue-property-decorator'

import Exclamation from '../icons/Exclamation.vue'

@Options({
  name: 'Checkbox',
  components: {
    Exclamation
  }
})
export default class Checkbox extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly modelValue!: boolean

  @Prop({ type: String, default: '' })
  readonly label!: string

  @Prop({ type: String, default: null })
  readonly errorMsg!: string | null
}
