export type HtmlContentPayload = string

export interface HtmlContent {
  templateUrl: string | null,
  textResponse: string | null,
}

export interface HtmlContentState {
  content: HtmlContent[]
}

export enum HTML_CONTENT_GETTERS {
  CONTENT_BY_TEMPLATE_URL = 'getContentByTemplateUrl'
}

export enum HTML_CONTENT_MUTATION {
  HTML_TEMPLATE= 'setHtmlContent'
}

export enum HTML_CONTENT_ACTION {
  FETCH = 'htmlContentFetch'
}

export const HTML_CONTENT_NAMESPACE = 'htmlContent'
