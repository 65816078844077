
import { Options, Prop, Vue } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'

import { isExternalUrl } from '../../utils'

@Options({
  name: 'NavLink',
  inheritAttrs: false
})
export default class NavLink extends Vue {
  @Prop({
    type: [String, Object],
    required: true
  })
  readonly to!: RouteLocationRaw

  get normalizedTo () {
    return typeof this.to === 'string' && !this.isExternal && !this.to.startsWith('/')
      ? `/${this.to}`
      : this.to
  }

  get isExternal (): boolean {
    return isExternalUrl(this.to)
  }

  get externalAttrs () {
    return this.$attrs.target === '_blank' && this.isUntrusted
      ? {
          rel: 'noopener noreferrer'
        }
      : {}
  }

  get isUntrusted () {
    const to = this.normalizedTo
    const pattern = /^(https?:)?\/\/(www\.)?(rtl\.hu|rtlmost\.hu)/
    return typeof to === 'string' && !(pattern.test(to))
  }

  get component () {
    return this.isExternal
      ? { tag: 'a', data: { href: this.normalizedTo, ...this.externalAttrs, ...this.$attrs } }
      : { tag: 'router-link', data: { ...this.$props, to: this.normalizedTo, ...this.$attrs } }
  }
}
