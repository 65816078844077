export interface EnvState {
  env: {
    [key: string]: unknown
  }
}

export enum ENV_GETTER {
  ENV = 'getEnv'
}

export enum ENV_MUTATION {
  ENV = 'setEnv'
}

export const ENV_NAMESPACE = 'env'
