import { Module } from 'vuex'
import { CONTACT_FORM_GETTERS, CONTACT_FORM_MUTATION, CONTACT_FORM_ACTION, ContactFormState, FormInputs, CategoryData} from './types'

import { prodForm, prodInputs, prodFormId } from './config/prod.config'
import { testForm, testInputs, testFormId } from './config/test.config'

export function createContactFormModule<RootState> (namespaced = false): Module<ContactFormState, RootState> {
  return {
    namespaced,
    state () {
      return {
        categoryData: prodForm,
        formInputs: prodInputs,
        step: 1,
        formUrl: '',
        formId: ''
      }
    },
    getters: { 
      [CONTACT_FORM_GETTERS.GET_DATA]: (state: ContactFormState) : CategoryData => { 
        return state.categoryData
      },
      [CONTACT_FORM_GETTERS.GET_INPUTS]: (state: ContactFormState) : FormInputs => {
        return state.formInputs
      },
      [CONTACT_FORM_GETTERS.GET_STEP]: (state: ContactFormState) : number => {
        return state.step
      },
      [CONTACT_FORM_GETTERS.GET_FORM_URL]: (state: ContactFormState) : string => {
        return state.formUrl
      },
      [CONTACT_FORM_GETTERS.GET_FORM_ID]: (state: ContactFormState) : string => {
        return state.formId
      }
    },
    mutations: {
      [CONTACT_FORM_MUTATION.SET_STEP] (state, payload: number) {
        state.step = payload
      },
      [CONTACT_FORM_MUTATION.SET_DATA] (state, payload: {categories: CategoryData, inputs: FormInputs, url: string, formId: string}) {
        state.categoryData = payload.categories
        state.formInputs = payload.inputs
        state.formUrl = payload.url
        state.formId = payload.formId
      }
    },
    actions: {
      [CONTACT_FORM_ACTION.SET_DATA] ({ commit, rootGetters }) {
        const isTest = (rootGetters.getEnv('CONTACT_FORM')) === 'test'

        const categories = isTest ? testForm : prodForm
        const inputs = isTest ? testInputs : prodInputs
        const formId = isTest ? testFormId : prodFormId
        const url = rootGetters.getEnv('CONTACT_FORM_URL')

        commit(CONTACT_FORM_MUTATION.SET_DATA, {categories: categories, inputs: inputs, url: url, formId: formId})
      }
    }
  }
}





