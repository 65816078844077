export class HttpError extends Error {
  name = 'HttpError'
  statusCode!: number

  constructor (code: number) {
    super(HttpError.messageMap[code])
    this.statusCode = code
  }

  static messageMap: Record<number, string> = {
    404: 'Az oldal nem található',
    500: 'Szerverhiba'
  }
}

export async function errorHandler<T extends { status?: number }> (request: () => T | Promise<T>): Promise<T> {
  let response: T

  try {
    response = await request()
  } catch (error) {
    if (error instanceof Response) {
      throw new HttpError(error.status)
    }
    throw error
  }

  return response
}
