import { Module } from 'vuex'
import { RSS_ACTION, RSS_MUTATION, RSS_GETTERS, RssState, RssFeedInterface, RssResponse } from './types'
import { rssParser } from './../../../utils'
import { errorHandler } from '../api'

export function createRssModule<RootState> (namespaced = false): Module<RssState, RootState> {
  return {
    namespaced,
    state () {
      return {
        rssFeeds: []
      }
    },
    getters: {
      [RSS_GETTERS.FEED_BY_URL]: (state: RssState) => (url: string) => {
        const item = state.rssFeeds.find((feed) => {
          return feed.rssFeedUrl === url
        })
        return item ? item.rssFeedData : []
      } 
    },
    mutations: {
      [RSS_MUTATION.RSS_FEED] (state: RssState, payload: RssFeedInterface) {
        state.rssFeeds.push(payload)
      }
    },
    actions: {
      async [RSS_ACTION.FETCH] ({ commit }, url: string): Promise<RssResponse> {
        const response = await errorHandler(async (): Promise<RssResponse> => {
          const rawResponse = await fetch( url, {method: 'GET'})
          const docString: string = await rawResponse.text()
          const rssFeedData = rssParser(docString)

          commit(RSS_MUTATION.RSS_FEED, {rssFeedUrl: url, rssFeedData: rssFeedData})
          return rawResponse
        })
        
        return response
      }
    }
  }
}



