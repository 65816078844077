import { Module } from 'vuex'
import { FAQ_MUTATION, FAQ_GETTERS, FaqState, FaqTopic } from './types'

import { faqData } from './data'

export function createFaqModule<RootState> (namespaced = false): Module<FaqState, RootState> {
  return {
    namespaced,
    state () {
      return {
        data: faqData,
        selectedTopic: null,
        selectedSubTopic: null,
        selectedQuestion: null
      }
    },
    getters: { 
      [FAQ_GETTERS.GET_DATA]: (state: FaqState) : Array<FaqTopic> => {
        return state.data
      },
      [FAQ_GETTERS.GET_TOPIC]: (state: FaqState) : FaqTopic | undefined => {
        return state.data.find((topic) => topic.tag === state.selectedTopic)
      },
      [FAQ_GETTERS.GET_TOPIC_NAMES]: (state: FaqState) : Array<{label: string, tag: string | undefined}> => state.data.map((item: FaqTopic): { label: string; tag: string}  => {
        return { label: item.label, tag: item.tag }
      }),
      [FAQ_GETTERS.GET_QUESTION]: (state: FaqState) : string | null => {
        return state.selectedQuestion
      }
    },
    mutations: {
      [FAQ_MUTATION.SET_SUBTOPIC] (state, payload: string) {
        if (payload) {
          state.selectedSubTopic = payload
        }
      },
      [FAQ_MUTATION.SET_TOPIC] (state, payload: string | string[]) {
        state.selectedTopic = payload
      },
      [FAQ_MUTATION.SET_QUESTION] (state, payload: string | null ) {
        state.selectedQuestion = payload
      }
    }
  }
}





