import "urlpattern-polyfill"

import type { NavigationGuard, RouterScrollBehavior, RouteRecordRaw } from 'vue-router'

import { Storage } from '../utils'

export enum ROUTES {
  INDEX = 'index',
  ARTICLE = 'article',
  AUTHOR = 'author',
  TAGS = 'tags',
  SEARCH = 'search',
  PROGRAMS = 'programs',
  PROGRAM_LIST = 'program_list',
  SHOW_INDEX = 'show_index',
  DYNAMIC = 'dynamic',
  SHOW_LIST = 'show_list',
  CELEBRITY = 'celebrity',
  RSS = 'rss',
  CASTING = 'casting',
  LOGIN = 'login',
  REGISTER = 'register',
  PASSWORD_RESET = 'password_reset',
  SOCIAL_PROCESS = 'social_process',
  SOCIAL_REDIRECT = 'social_redirect',
  FAQ = 'faq',
  FAQ_TOPIC = 'faq_topic',
  CONTACT_FORM = 'contact_form',
  FAQ_SEARCH = 'faq_search',
  FAQ_CHAT = 'faq_chat',
}

export function getRoutes (): Array<RouteRecordRaw> {
  const CastingListPage = () => import(/* webpackChunkName: "casting-list-page" */ '../views/pages/CastingListPage.vue')
  const CelebrityPage = () => import(/* webpackChunkName: "celebrity-page" */ '../views/pages/CelebrityPage.vue')
  const ContentPage = () => import(/* webpackChunkName: "content-page" */ '../views/pages/ContentPage.vue')
  const DynamicPage = () => import(/* webpackChunkName: "dynamic-page" */ '../views/pages/DynamicPage.vue')
  const ProgramListPage = () => import(/* webpackChunkName: "program-list-page" */ '../views/pages/ProgramListPage.vue')
  const RssPage = () => import(/* webpackChunkName: "rss-page" */ '../views/pages/RssPage.vue')
  const SearchPage = () => import(/* webpackChunkName: "search-page" */ '../views/pages/SearchPage.vue')
  const TagPage = () => import(/* webpackChunkName: "tag-list-page" */ '../views/pages/TagPage.vue')
  const AuthorPage = () => import(/* webpackChunkName: "author-page" */ '../views/pages/AuthorPage.vue')
  const TvShowListPage = () => import(/* webpackChunkName: "tv-show-list-page" */ '../views/pages/TvShowListPage.vue')
  const LoginPage = () => import(/* webpackChunkName: "login-page" */ '../views/pages/LoginPage.vue')
  const RegisterPage = () => import(/* webpackChunkName: "register-page" */ '../views/pages/RegisterPage.vue')
  const PasswordResetPage = () => import(/* webpackChunkName: "password-reset-page" */ '../views/pages/PasswordResetPage.vue')
  const SocialProcessPage = () => import(/* webpackChunkName: "social-process-page" */ '../views/pages/SocialProcessPage.vue')
  const SocialRedirectPage = () => import(/* webpackChunkName: "social-redirect-page" */ '../views/pages/SocialRedirectPage.vue')
  const FaqPage = () => import(/* webpackChunkName: "faq-page" */ '../views/pages/FaqPage.vue')
  const FaqTopicPage = () => import(/* webpackChunkName: "faq-topic-page" */ '../views/pages/FaqTopicPage.vue')
  const ContactFormPage = () => import(/* webpackChunkName: "faq-form-page" */ '../views/pages/ContactFormPage.vue')
  const FaqSearchPage = () => import(/* webpackChunkName: "faq-search-page" */ '../views/pages/FaqSearchPage.vue')
  const FaqChatPage = () => import(/* webpackChunkName: "faq-chat-page" */ '../views/pages/FaqChatPage.vue')

  return [
    {
      path: '/',
      name: ROUTES.INDEX,
      component: DynamicPage
    },
    {
      path: '/musorok',
      name: ROUTES.SHOW_INDEX,
      component: DynamicPage
    },
    {
      path: '/musorok/osszes',
      name: ROUTES.SHOW_LIST,
      component: TvShowListPage
    },
    {
      path: '/musorujsag',
      name: ROUTES.PROGRAM_LIST,
      component: ProgramListPage
    },
    {
      path: '/kereses',
      name: ROUTES.SEARCH,
      component: SearchPage
    },
    {
      path: '/legfrissebb',
      name: ROUTES.RSS,
      component: RssPage
    },
    {
      path: '/casting',
      name: ROUTES.CASTING,
      component: CastingListPage
    },
    {
      path: '/sztarok/:slug',
      name: ROUTES.CELEBRITY,
      component: CelebrityPage
    },
    {
      path: '/szerzo/:author',
      name: ROUTES.AUTHOR,
      component: AuthorPage
    },
    {
      path: '/cimke/:tag',
      name: ROUTES.TAGS,
      component: TagPage
    },
    {
      path: '/auth/belepes',
      name: ROUTES.LOGIN,
      component: LoginPage
    },
    {
      path: '/auth/regisztracio',
      name: ROUTES.REGISTER,
      component: RegisterPage
    },
    {
      path: '/auth/jelszo-emlekezteto',
      name: ROUTES.PASSWORD_RESET,
      component: PasswordResetPage
    },
    {
      path: '/auth/social-process',
      name: ROUTES.SOCIAL_PROCESS,
      component: SocialProcessPage
    },
    {
      path: '/auth/social-redirect',
      name: ROUTES.SOCIAL_REDIRECT,
      component: SocialRedirectPage
    },
    {
      path: '/:category/:year(\\d+)/:month(\\d+)/:day(\\d+)/:slug',
      name: ROUTES.ARTICLE,
      component: ContentPage
    },
    {
      path: '/:url+',
      name: ROUTES.DYNAMIC,
      component: DynamicPage
    },
    {
      path: '/ugyfelszolgalat',
      name: ROUTES.FAQ,
      component: FaqPage
    },
    {
      path: '/ugyfelszolgalat/:topic',
      name: ROUTES.FAQ_TOPIC,
      component: FaqTopicPage,
      meta: { faqScrollbehavior: true }
    },
    {
      path: '/hibabejelentes',
      name: ROUTES.CONTACT_FORM,
      component: ContactFormPage
    },
    {
      path: '/ugyfelszolgalat/kereses',
      name: ROUTES.FAQ_SEARCH,
      component: FaqSearchPage
    },
    {
      path: '/ugyfelszolgalat/chat',
      name: ROUTES.FAQ_CHAT,
      component: FaqChatPage
    }
  ]
}

export function scrollBehavior (
  to: Parameters<RouterScrollBehavior>[0],
  from: Parameters<RouterScrollBehavior>[1],
  savedPosition: Parameters<RouterScrollBehavior>[2]
): ReturnType<RouterScrollBehavior> {
  if (to.meta.faqScrollbehavior) {
    const selector = from.meta.question ? `.faq-topic-details__qa #${to.query.tema} #question-${from.meta.question}` : `.faq-topic-details__qa #${to.query.tema}`
    const mobileOffset = from.meta.question ? 50 : 75
    const desktopOffset = from.meta.question ? 0 : 50
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          el: selector,
          behavior: 'smooth',
          top: (window.innerWidth <= 1200) ? mobileOffset : desktopOffset
        })
      }, 10)
    })
  } else {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: savedPosition?.top ?? 0, behavior: 'smooth' })
      }, 10)
    })
  }
}

export function diversionGuard (url: string, isForceRedirectMode = false): NavigationGuard {
  return async (to) => {
    if (url) {
      try {
        const urlObj = new URL(url)
        const ttl = 5000
        urlObj.searchParams.set('ts', String(Math.floor(Date.now() / ttl) * ttl))

        const response = await fetch(urlObj.toString())
        const items: Array<{
          id: string,
          scope: string,
          redirect?: boolean,
          link: string,
          banner?: {
            title: string,
            logo?: string,
            bg_image?: string,
            bg_color?: string
          },
          vote?: {
            id: string,
            group_id?: string
          }
        }> | null = await response.json()

        if (items) {
          items.sort((a, b) => b.scope.length - a.scope.length)

          const item = items.find(item => {
            const pattern = new URLPattern({ pathname: item.scope })
            return pattern.test({ pathname: to.fullPath })
          })

          if (item) {
            if (isForceRedirectMode) {
              const key = `vote-diversion-${item.id}`
              const visited = Storage.get(key);
              if (!visited && item.link && item.redirect) {
                Storage.set(key, true);
                window.location.href = item.link
              } else {
                to.meta.votePromo = item
              }
            } else {
              to.meta.votePromo = item
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
