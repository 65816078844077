"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VoteAnswerToJSONTyped = exports.VoteAnswerToJSON = exports.VoteAnswerFromJSONTyped = exports.VoteAnswerFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function VoteAnswerFromJSON(json) {
    return VoteAnswerFromJSONTyped(json, false);
}
exports.VoteAnswerFromJSON = VoteAnswerFromJSON;
function VoteAnswerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'voteId': !(0, runtime_1.exists)(json, 'voteId') ? undefined : json['voteId'],
        'imageId': !(0, runtime_1.exists)(json, 'imageId') ? undefined : json['imageId'],
        'answer': !(0, runtime_1.exists)(json, 'answer') ? undefined : json['answer'],
        'counter': !(0, runtime_1.exists)(json, 'counter') ? undefined : json['counter'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
    };
}
exports.VoteAnswerFromJSONTyped = VoteAnswerFromJSONTyped;
function VoteAnswerToJSON(value) {
    return VoteAnswerToJSONTyped(value, false);
}
exports.VoteAnswerToJSON = VoteAnswerToJSON;
function VoteAnswerToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'voteId': value.voteId,
        'imageId': value.imageId,
        'answer': value.answer,
        'counter': value.counter,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
    };
}
exports.VoteAnswerToJSONTyped = VoteAnswerToJSONTyped;
