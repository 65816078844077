import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "faq__container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faq_item = _resolveComponent("faq-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
      return (_openBlock(), _createBlock(_component_faq_item, { key: index }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.title), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: item.content
          }, null, 8, _hoisted_2)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}