import { Vue, Options, Prop } from 'vue-property-decorator'
import { SiteData } from '@rtl/api'

@Options({
  name: 'TvShowCardMixin'
})
export default class TvShowCardMixin extends Vue {
  @Prop({ type: Object, required: true })
  readonly tvShow!: SiteData

  get title (): string | null {
    return this.tvShow.name || null
  }

  get url (): string {
    return this.tvShow.url || ''
  }

  get imageUrl (): string | null {
    const params = this.tvShow.parameters

    if (params && params.design.tvshowArtworkImageUrls) {
      const imageUrls = params.design.tvshowArtworkImageUrls as unknown as { 'tvshowArtworkImageUrl': string }
      return imageUrls['tvshowArtworkImageUrl']
    }

    return null
  }

  get totemUrl (): string | null {
    const params = this.tvShow.parameters

    if (params && params.design.tvshowTotemImageUrls) {
      const imageUrls = params.design.tvshowTotemImageUrls as unknown as { 'tvshowTotemImageUrl': string }
      return imageUrls['tvshowTotemImageUrl']
    }

    return null
  }
}
