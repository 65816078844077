
import { MinimalContentData } from '@rtl/api'
import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentFeedPayload, ContentListResult } from '../../../store'

@Options({
  name: 'MinuteByMinuteLead'
})
export default class MinuteByMinuteLead extends Vue {
  @Action(CONTENT_ACTION.FEED, { namespace: CONTENT_NAMESPACE })
  storeFetchContentFeed!: (payload: ContentFeedPayload) => Promise<ContentListResult>

  @Prop({
    type: String,
    required: true
  })
  readonly contentId!: string

  @Prop({
    type: Number,
    default: 3
  })
  readonly length!: number

  get feedContents (): Array<MinimalContentData> {
    return this.contentFeed?.items || []
  }

  get contentFeed (): ContentListResult['data'] | null {
    if (this.contentFeedPayload) {
      return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.FEED], this.contentFeedPayload)?.data || null
    }
    return null
  }

  get contentFeedPayload (): ContentFeedPayload | null {
    return {
      contentId: this.contentId,
      limit: this.length
    }
  }

  getContentTitle (content: MinimalContentData): string | null {
    return (content.alternativeTitle || content.title || '').trim() || null
  }

  getContentReleaseDate (content: MinimalContentData): Date | null {
    return content.releaseDate || null
  }

  @Watch('contentFeedPayload', { deep: true })
  async fetchContentFeed () {
    if (this.contentFeedPayload) {
      return this.storeFetchContentFeed(this.contentFeedPayload)
    }
  }

  async serverPrefetch () {
    return this.fetchContentFeed()
  }

  mounted () {
    return this.fetchContentFeed()
  }
}
