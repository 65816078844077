import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "error-page__box" }
const _hoisted_4 = { class: "error-page__code" }
const _hoisted_5 = { class: "error-page__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.error.statusCode || 'Error'), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.error.message), 1),
        _createElementVNode("button", {
          class: "error-page__button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
        }, _toDisplayString(_ctx.$t('common.btn_back')), 1)
      ])
    ])
  ]))
}