
import { Options, Vue } from 'vue-property-decorator'

import ContentListPage from '../pages/ContentListPage.vue'
import { WidgetMeta } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Content listaoldal',
  groups: {
    style: 'Beállítás',
    data: 'Adatok'
  },
  defaultGroup: 'style',
  fields: {
    title: {
      label: 'Címsor',
      type: String,
      default: ''
    },
    showHighlight: {
      type: Boolean,
      default: false,
      props: {
        label: 'Top kiemelés'
      }
    },
    showFilters: {
      type: Boolean,
      default: true,
      props: {
        label: 'Szűrők megjelenítése'
      }
    },
    payload: {
      group: 'data',
      type: 'payload',
      default: {}
    }
  }
}

@Options({
  name: 'ContentPageWidget',
  components: {
    ContentListPage
  }
})
export default class ContentPageWidget extends Vue {}
