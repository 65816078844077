import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "srcset", "onerror"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _mergeProps(_ctx.dimensions ? {..._ctx.getSize(), loading: 'lazy' } : {}, {
    src: _ctx.src1x || _ctx.placeholder,
    srcset: _ctx.srcset,
    onerror: `this.onerror=null; this.srcset=null; this.src='${_ctx.placeholder}';`
  }), null, 16, _hoisted_1))
}