"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OembedApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class OembedApi extends runtime.BaseAPI {
    /**
     * Get oembed code
     * Get oembed code
     */
    async getOembedCodeRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }
        if (requestParameters.contentId !== undefined) {
            queryParameters['contentId'] = requestParameters.contentId;
        }
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/oembed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.OembedResultFromJSON)(jsonValue));
    }
    /**
     * Get oembed code
     * Get oembed code
     */
    async getOembedCode(requestParameters, initOverrides) {
        const response = await this.getOembedCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.OembedApi = OembedApi;
