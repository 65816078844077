
import { ContentListQueryOrderDirectionEnum, ContentListQueryOrderFieldEnum, ListDefaultContentsContentTypesEnum, ListDefaultContentsIntervalEnum } from '@rtl/api'
import { mixins, Options, Prop, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { CONTENT_ACTION, CONTENT_NAMESPACE, ContentListPayload, ContentListResult } from '../../store'
import ContentMixWidgetBlock from '../components/widget/ContentMixWidgetBlock.vue'
import { GetPageMixin } from '../mixins'
import { WidgetMeta } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Content mix',
  groups: {
    style: 'Beállítás',
    data1: 'A) Adatok',
    data2: 'B) Adatok',
    data3: 'C) Adatok'
  },
  defaultGroup: 'style',
  fields: {
    firstTitle: {
      type: String,
      default: '',
      props: {
        placeholder: 'A) Címsor'
      }
    },
    firstBtnUrl: {
      type: String,
      props: {
        placeholder: 'A) Tovább link (url)'
      }
    },
    firstBtnLabel: {
      type: String,
      props: {
        placeholder: 'A) Tovább link (szöveg)'
      }
    },
    firstLength: {
      label: 'A) Darabszám',
      type: Number,
      default: 5,
      props: {
        min: 1
      }
    },
    secondTitle: {
      type: String,
      default: '',
      props: {
        placeholder: 'B) Címsor'
      }
    },
    secondBtnUrl: {
      type: String,
      props: {
        placeholder: 'B) Tovább link (url)'
      }
    },
    secondBtnLabel: {
      type: String,
      props: {
        placeholder: 'B) Tovább link (szöveg)'
      }
    },
    secondLength: {
      label: 'B) Darabszám',
      type: Number,
      default: 5,
      props: {
        min: 1
      }
    },
    thirdTitle: {
      type: String,
      default: '',
      props: {
        placeholder: 'C) Címsor'
      }
    },
    thirdBtnUrl: {
      type: String,
      props: {
        placeholder: 'C) Tovább link (url)'
      }
    },
    thirdBtnLabel: {
      type: String,
      props: {
        placeholder: 'C) Tovább link (szöveg)'
      }
    },
    thirdLength: {
      label: 'C) Darabszám',
      type: Number,
      default: 2,
      props: {
        min: 1
      }
    },
    firstPayload: {
      group: 'data1',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.News],
        order: [{ field: ContentListQueryOrderFieldEnum.ReleaseDate, direction: ContentListQueryOrderDirectionEnum.Desc }]
      }
    },
    secondPayload: {
      group: 'data2',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.News],
        order: [{ field: ContentListQueryOrderFieldEnum.Topn, direction: ContentListQueryOrderDirectionEnum.Desc }],
        interval: ListDefaultContentsIntervalEnum.LastDay
      }
    },
    thirdPayload: {
      group: 'data3',
      type: 'payload',
      default: {
        contentTypes: [ListDefaultContentsContentTypesEnum.News],
        withVideo: 1
      }
    }
  }
}

@Options({
  name: 'ContentMixWidget',
  components: {
    ContentMixWidgetBlock
  }
})
export default class ContentMixWidget extends mixins(GetPageMixin) {
  @Action(CONTENT_ACTION.LIST, { namespace: CONTENT_NAMESPACE })
  storeFetchContentList!: (payload: ContentListPayload) => Promise<ContentListResult>

  @Prop({ type: Number, default: 5 })
  readonly firstLength!: number

  @Prop({ type: Number, default: 5 })
  readonly secondLength!: number

  @Prop({ type: Number, default: 2 })
  readonly thirdLength!: number

  @Prop({ type: Object })
  readonly firstPayload: Partial<ContentListPayload> | undefined

  @Prop({ type: Object })
  readonly secondPayload: Partial<ContentListPayload> | undefined

  @Prop({ type: Object })
  readonly thirdPayload: Partial<ContentListPayload> | undefined

  @Prop({ type: String, default: '' })
  readonly firstTitle!: string

  @Prop({ type: String, default: 'Mutasd mind' })
  readonly firstBtnLabel!: string

  @Prop({ type: String, default: '' })
  readonly firstBtnUrl!: string

  @Prop({ type: String, default: '' })
  readonly secondTitle!: string

  @Prop({ type: String, default: 'Mutasd mind' })
  readonly secondBtnLabel!: string

  @Prop({ type: String, default: '' })
  readonly secondBtnUrl!: string

  @Prop({ type: String, default: '' })
  readonly thirdTitle!: string

  @Prop({ type: String, default: 'Mutasd mind' })
  readonly thirdBtnLabel!: string

  @Prop({ type: String, default: '' })
  readonly thirdBtnUrl!: string

  get firstContentList (): ContentListResult['data'] | null {
    return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], this.firstContentListPayload)?.data || null
  }

  get secondContentList (): ContentListResult['data'] | null {
    return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], this.secondContentListPayload)?.data || null
  }

  get thirdContentList (): ContentListResult['data'] | null {
    return this.$store.getters['$cache/result']([CONTENT_NAMESPACE, CONTENT_ACTION.LIST], this.thirdContentListPayload)?.data || null
  }

  get firstContentListPayload (): ContentListPayload {
    return {
      folderIds: !this.firstPayload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      order: [{ field: ContentListQueryOrderFieldEnum.ReleaseDate, direction: ContentListQueryOrderDirectionEnum.Desc }],
      limit: this.firstLength,
      ...this.firstPayload
    }
  }

  get secondContentListPayload (): ContentListPayload {
    return {
      folderIds: !this.secondPayload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      order: [{ field: ContentListQueryOrderFieldEnum.Topn, direction: ContentListQueryOrderDirectionEnum.Desc }],
      interval: ListDefaultContentsIntervalEnum.LastDay,
      limit: this.secondLength,
      ...this.secondPayload
    }
  }

  get thirdContentListPayload (): ContentListPayload {
    return {
      folderIds: !this.thirdPayload?.tags && this.page?.site?.folderId ? [this.page?.site?.folderId] : undefined,
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      withVideo: 1,
      limit: this.thirdLength,
      ...this.thirdPayload
    }
  }

  @Watch('firstContentListPayload', { deep: true })
  async fetchFirstContentList () {
    return this.storeFetchContentList(this.firstContentListPayload)
  }

  @Watch('secondContentListPayload', { deep: true })
  async fetchSecondContentList () {
    return this.storeFetchContentList(this.secondContentListPayload)
  }

  @Watch('thirdContentListPayload', { deep: true })
  async fetchThirdContentList () {
    return this.storeFetchContentList(this.thirdContentListPayload)
  }

  fetchAllContentList () {
    return Promise.all([
      this.fetchFirstContentList(),
      this.fetchSecondContentList(),
      this.fetchThirdContentList()
    ])
  }

  serverPrefetch () {
    return this.fetchAllContentList()
  }

  mounted () {
    return this.fetchAllContentList()
  }
}
