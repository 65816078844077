
import { Inject, mixins, Options, Prop } from 'vue-property-decorator'

import ContentCardMixin from '../../mixins/ContentCardMixin'
import GalleryFull from '../icons/GalleryFull.vue'
import Play from '../icons/Play.vue'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'
import Tag from '../Tag.vue'
import MinuteByMinuteLead from './MinuteByMinuteLead.vue'

@Options({
  name: 'ContentCard',
  components: {
    Image,
    NavLink,
    Play,
    GalleryFull,
    Tag,
    MinuteByMinuteLead
  }
})
export default class ContentCard extends mixins(ContentCardMixin) {
  @Inject({ from: 'target', default: '' })
  readonly target!: string

  @Inject({ from: 'utmMedium', default: null })
  readonly utmMedium!: string | null

  @Inject({ from: 'utmSource', default: null })
  readonly utmSource!: string | null

  @Inject({ from: 'utmCampaign', default: null })
  readonly utmCampaign!: string | null

  @Prop({ type: Boolean, default: true })
  readonly navigation!: boolean

  get _url () {
    if (this.utmCampaign && this.utmSource && this.utmMedium) {
      return `${this.url}?utm_source=${this.utmSource}&utm_campaign=${this.utmCampaign}&utm_medium=${this.utmMedium}`
    } else {
      return this.url
    }
  }
}
