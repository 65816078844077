export default {
  site: {
    title: 'rtl'
  },

  page: {
    no_permission: '403',
    not_found: '404',
    index: 'Home',
    login: 'Login'
  },

  locale: {
    en: 'English',
    hu: 'Hungarian'
  },

  common: {
    label_locale: 'Language',
    label_yes: 'Yes',
    label_no: 'No',
    label_loading: 'Loading..',
    label_search: 'Search..',
    title_confirm: 'Confirmation..',
    field_id: '#',
    field_created_at: 'Created at',
    field_updated_at: 'Last updated at',
    field_deleted_at: 'Deleted at',
    field_actions: 'Actions',
    btn_create: 'New',
    btn_edit: 'Edit',
    btn_delete: 'Delete',
    btn_back: 'Back',
    btn_close: 'Close',
    btn_save: 'Save',
    btn_confirm: 'Confirm',
    btn_show: 'Show',
    btn_details: 'Details',
    btn_actions: 'Actions',
    btn_filters: 'Filters',
    status_active: 'Active',
    status_inactive: 'Inactive',
    msg_no_items: 'There are no items.'
  },

  pagination: {
    label_prev: 'Previous',
    label_next: 'Next',
    total: '{total} total items'
  },

  auth: {
    label_not_found: 'The requested page "/{path}" not found.',
    label_no_permissions: 'You don\'t have permissions for the requested page.',
    btn_login: 'Login',
    btn_logout: 'Logout',
    btn_login_as_another: 'Login as another user',
    role_guest: 'Guest',
    role_user: 'User'
  },

  user: {
    title_users: 'Users',
    title_create: 'Create user',
    title_update: 'Edit user',
    title_delete: 'Delete user',
    field_full_name: 'Name',
    field_first_name: 'First name',
    field_last_name: 'Last name',
    field_email: 'E-mail address',
    field_password: 'Password',
    field_password_confirmation: 'Password confirm',
    field_password_old: 'Current password',
    field_status: 'Status',
    field_role: 'Role',
    btn_create: 'New user',
    msg_delete: 'Are you sure of deleting user "{email}"?'
  }
}
