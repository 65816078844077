import { Module } from 'vuex'
import { ContentApi } from '@rtl/api'
import { CONTENT_ACTION, CONTENT_GETTER, ContentResult, ContentPayload, ContentListResult, ContentListPayload, ContentRelatedListPayload, ContentFeedPayload } from './types'
import { API_GETTER, errorHandler } from '../api'
import { shuffle } from '../../../utils'

export function createContentModule<RootState> (namespaced = false): Module<Record<string, never>, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [CONTENT_GETTER.API] (state, getters, rootState, rootGetters) {
        return new ContentApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [CONTENT_ACTION.ITEM] ({ getters, rootGetters }, payload: ContentPayload): Promise<ContentResult> {
        const response = await errorHandler(() => getters[CONTENT_GETTER.API].getContentById({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      },
      async [CONTENT_ACTION.LIST] ({ getters, rootGetters }, payload: ContentListPayload): Promise<ContentListResult> {
        const response: ContentListResult = await errorHandler(() => getters[CONTENT_GETTER.API].listDefaultContents({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        if (response.data?.items && payload.shuffle) {
          response.data.items = shuffle(response.data.items)
        }

        return response
      },
      async [CONTENT_ACTION.RELATED] ({ getters, rootGetters }, payload: ContentRelatedListPayload): Promise<ContentListResult> {
        const response = await errorHandler(() => getters[CONTENT_GETTER.API].listRelatedContents({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      },
      async [CONTENT_ACTION.FEED] ({ getters, rootGetters }, payload: ContentFeedPayload): Promise<ContentListResult> {
        const response = await errorHandler(() => getters[CONTENT_GETTER.API].contentFeed({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
