export function formatSeconds (seconds: number): string {
  let h = Math.floor(seconds / 3600)
  let m = Math.floor(seconds % 3600 / 60)
  let s = Math.floor(seconds % 60)

  return (h ? h + ':' : '')
    + ((h ? m.toString().padStart(2, '0') : m) + ':')
    + s.toString().padStart(2, '0')
}

export function localISOString (date: Date): string {
  const tzoffset = (new Date()).getTimezoneOffset() * 60 * 1000
  return (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1)
}

export function toIsoString (date: Date): string {
  const tzo = -date.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = (num: number): string => {
    return (num < 10 ? '0' : '') + num
  }

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60)
}
