import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tv-show-hero__nav-list"
}
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = { class: "tv-show-hero__nav-link" }
const _hoisted_4 = { class: "tv-show-hero__dropdown" }
const _hoisted_5 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDown = _resolveComponent("ChevronDown")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "heroNav",
    class: _normalizeClass(["tv-show-hero__nav", {'tv-show-hero__nav--no-padding-left': _ctx.noPaddingLeft}])
  }, [
    (_ctx.menuItems)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (!menuItem.menuItems || !menuItem.menuItems.length)
                ? (_openBlock(), _createElementBlock("li", {
                    key: menuItem.id,
                    class: "tv-show-hero__nav-item"
                  }, [
                    _createElementVNode("a", {
                      href: menuItem.targetUrl,
                      target: menuItem.target,
                      class: "tv-show-hero__nav-link"
                    }, _toDisplayString(menuItem.label), 9, _hoisted_2)
                  ]))
                : (_openBlock(), _createElementBlock("li", {
                    key: menuItem.id,
                    class: "tv-show-hero__nav-item tv-show-hero__nav-item--dropdown",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dropdownClick && _ctx.dropdownClick(...args))),
                    tabindex: "0"
                  }, [
                    _createElementVNode("span", _hoisted_3, [
                      _createTextVNode(_toDisplayString(menuItem.label) + " ", 1),
                      _createVNode(_component_ChevronDown)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.menuItems, (dropdownItem) => {
                        return (_openBlock(), _createElementBlock("a", {
                          key: dropdownItem.id,
                          href: dropdownItem.targetUrl,
                          target: dropdownItem.target,
                          class: "tv-show-hero__nav-link"
                        }, _toDisplayString(dropdownItem.label), 9, _hoisted_5))
                      }), 128))
                    ])
                  ]))
            ], 64))
          }), 256)),
          _createElementVNode("li", {
            class: _normalizeClass(["tv-show-hero__nav-item tv-show-hero__nav-item", {
          'tv-show-hero__nav-item--hidden': !_ctx.showNavMore
        }])
          }, [
            _createElementVNode("a", {
              class: "tv-show-hero__nav-link",
              href: "",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showAllMenuItems && _ctx.showAllMenuItems(...args)), ["prevent"]))
            }, "Továbbiak +")
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}