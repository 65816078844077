import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container content-mix-block widget-block" }
const _hoisted_2 = { class: "content-list" }
const _hoisted_3 = { class: "content-list__row" }
const _hoisted_4 = { class: "content-list__col content-list__col--3" }
const _hoisted_5 = { class: "content-list__col content-list__col--3" }
const _hoisted_6 = { class: "content-list__col content-list__col--3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_card_list = _resolveComponent("content-card-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_widget_block, {
            title: _ctx.firstTitle,
            "show-divider": true,
            "btn-label": _ctx.firstBtnLabel,
            "btn-url": _ctx.firstBtnUrl
          }, {
            default: _withCtx(() => [
              _createVNode(_component_content_card_list, {
                contents: _ctx.firstContents,
                cols: 0,
                "show-lead": false,
                "show-image": false,
                "show-border": true,
                "show-tag": true
              }, null, 8, ["contents"])
            ]),
            _: 1
          }, 8, ["title", "btn-label", "btn-url"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_widget_block, {
            title: _ctx.secondTitle,
            "show-divider": true,
            "btn-label": _ctx.secondBtnLabel,
            "btn-url": _ctx.secondBtnUrl
          }, {
            default: _withCtx(() => [
              _createVNode(_component_content_card_list, {
                contents: _ctx.secondContents,
                cols: 0,
                "show-lead": false,
                "show-image": false,
                "show-border": true,
                "show-tag": true
              }, null, 8, ["contents"])
            ]),
            _: 1
          }, 8, ["title", "btn-label", "btn-url"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_widget_block, {
            class: "content-mix-block__cards",
            title: _ctx.thirdTitle,
            "show-divider": true,
            "btn-label": _ctx.thirdBtnLabel,
            "btn-url": _ctx.thirdBtnUrl
          }, {
            default: _withCtx(() => [
              _createVNode(_component_content_card_list, {
                contents: _ctx.thirdContents,
                cols: 2,
                "title-highlighted": false,
                "card-highlighted": false,
                "show-image": true,
                "show-tag": true,
                "show-duration": true,
                "show-lead": false,
                "show-border": false
              }, null, 8, ["contents"])
            ]),
            _: 1
          }, 8, ["title", "btn-label", "btn-url"])
        ])
      ])
    ])
  ]))
}