
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentCardListMixin from '../../mixins/ContentCardListMixin'
import CelebrityCard from './CelebrityCard.vue'

@Options({
  name: 'CelebrityCardList',
  components: {
    CelebrityCard
  }
})
export default class CelebrityCardList extends mixins(ContentCardListMixin) {
  @Prop({ type: Boolean, default: false })
  readonly lined!: boolean

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean

  @Prop({ type: Number, default: 0 })
  readonly cols!: number
}
