import { GetContentByIdRequest, ListDefaultContentsRequest, ListRelatedContentsRequest, ContentFeedRequest} from '@rtl/api'
import { ApiPayload } from '../api'

export { ContentResult, ContentListResult } from '@rtl/api'

export type ContentDecorator = {
  alternativeTitle?: string
  alternativeLead?: string
  cardHighlighted?: boolean
  titleHighlighted?: boolean
  showImage?: boolean
  showTitle?: boolean
  showLead?: boolean
  showTag?: boolean
  showDuration?: boolean
  showReleaseDate?: boolean
  showBorder?: boolean
  showGalleryBadge?: boolean
}

export type ContentPayload = ApiPayload<GetContentByIdRequest>
export type ContentListPayload = ApiPayload<ListDefaultContentsRequest> & { shuffle?: boolean }
export type ContentListDecoratedPayload = ContentListPayload & { decorators: Record<string, ContentDecorator> }
export type ContentRelatedListPayload = ApiPayload<ListRelatedContentsRequest>
export type ContentFeedPayload = ApiPayload<ContentFeedRequest>

export enum CONTENT_ACTION {
  ITEM = 'fetchContent',
  LIST = 'fetchContentList',
  RELATED = 'fetchContentRelatedList',
  FEED = 'fetchContentFeed',
}

export enum CONTENT_GETTER {
  API = 'api'
}

export const CONTENT_NAMESPACE = 'content'
