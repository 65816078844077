import { SearchRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export type SearchPayload = ApiPayload<SearchRequest>

export interface SearchState {}

export enum SEARCH_ACTION {
  SEARCH = 'fetchSearch'
}

export enum SEARCH_GETTER {
  API = 'api'
}

export const SEARCH_NAMESPACE = 'search'
