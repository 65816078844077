import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tv-program" }
const _hoisted_2 = { class: "tv-program__header" }
const _hoisted_3 = { class: "tv-program__items" }
const _hoisted_4 = {
  key: 0,
  class: "tv-program__no-items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_TvProgramItemCard = _resolveComponent("TvProgramItemCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Image, {
        class: "tv-program__channel-logo",
        "image-url": _ctx.channelLogoUrl,
        alt: _ctx.channelLogoUrl
      }, null, 8, ["image-url", "alt"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
        return (_openBlock(), _createBlock(_component_TvProgramItemCard, {
          key: i,
          item: item
        }, null, 8, ["item"]))
      }), 128)),
      (!_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Feltöltés alatt... Kérjük nézzen vissza később! Köszönjük! "))
        : _createCommentVNode("", true)
    ])
  ]))
}