
import { Options, Prop, Vue } from 'vue-property-decorator'

import FaqItem from '../widgets/components/FaqItem.vue'

interface FaqData {
  items: {
    site: string;
    title: string;
    content: string;
  }[];
}

function getFaqItems (data: FaqData, site?: string) {
  if (!data?.items) {
    return []
  }

  if (!site) {
    return data.items
  }

  return data.items.filter(item => item.site === site)
}

@Options({
  name: 'FaqBlockWidget',
  components: {
    FaqItem
  }
})
export default class FaqBlockWidget extends Vue {
  @Prop({ type: String, default: '' })
  site!: string;

  @Prop({ type: String, default: '' })
  items!: string;

  parsedItems: FaqData | null = null;

  created () {
    this.parsedItems = JSON.parse(decodeURI(this.items) || '{}')
  }

  get filteredItems () {
    if (!this.parsedItems) {
      return []
    }

    return getFaqItems(this.parsedItems, this.site)
  }
}
