
import { GalleryImage } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import { ContentPayload } from '../../store'
import { FetchContentItemMixin } from '../../views'
import GalleryOutline from '../components/icons/GalleryOutline.vue'

@Options({
  name: 'GalleryWidget',
  components: {
    GalleryOutline
  }
})
export default class GalleryWidget extends mixins(FetchContentItemMixin) {
  @Prop({
    type: String
  })
  readonly contentId!: string

  @Prop({
    type: [Number, String]
  })
  readonly imgIndex!: number

  get contentPayload (): ContentPayload | null {
    const contentId = this.contentId
    return contentId ? { contentId } : null
  }

  get leadImage () {
    return this.leadGalleryImage?.imageUrls?.imageUrl169
  }

  get leadText () {
    return this.leadGalleryImage?.caption ?? null
  }

  get source () {
    return this.leadGalleryImage?.source ?? null
  }

  get leadGalleryImage () {
    return this.gallery?.at(this.imgIndex - 1) ?? {} as GalleryImage
  }

  get gallery () {
    return this.content?.params?.galleryImages as GalleryImage[]
  }

  galleryClick ():void {
    this.$router.push({
      path: '/' + this.content?.url,
      query: {
        index: this.imgIndex
      }
    })
  }
}
