
import { mixins, Options, Prop } from 'vue-property-decorator'

import { generateMetaInfo } from '../../utils'
import { GetPageMixin } from '../mixins'

@Options({
  name: 'ErrorPage',
  head () {
    return generateMetaInfo(this.$route, this.$router, this.page, undefined, this.$store)
  }
})
export default class ErrorPage extends mixins(GetPageMixin) {
  @Prop({
    type: Error,
    required: true
  })
  readonly error!: Error

  back (): void {
    this.$router.back()
  }
}
