
import { SiteData } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import TvShowTotem from './TvShowTotem.vue'

@Options({
  name: 'TvShowTotemList',
  components: {
    TvShowTotem
  }
})
export default class TvShowTotemList extends Vue {
  @Prop({ type: Array, default: [] })
  readonly items!: SiteData[]

  @Prop({ type: Boolean, default: false })
  readonly showSlider!: boolean
}
