

import { Options, Prop, Vue } from 'vue-property-decorator'

@Options({
  name: 'FaqItem'
})
export default class FaqItem extends Vue {
  @Prop({ type: Boolean, default: false })
  isOpen!: boolean;

  openState = false;

  created () {
    this.openState = this.isOpen
  }
}
