
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentCardListMixin from '../../mixins/ContentCardListMixin'
import ContentCard from './ContentCard.vue'

@Options({
  name: 'ContentCardList',
  components: {
    ContentCard
  }
})
export default class ContentCardList extends mixins(ContentCardListMixin) {
  @Prop({ type: Number, default: 0 })
  readonly cols!: number
}
