export const CONTACT_FORM_NAMESPACE = 'contactForm'

export interface ContactFormState {
  categoryData: CategoryData,
  formInputs: FormInputs,
  step: number,
  formUrl: string,
  formId: string
}

export enum CONTACT_FORM_GETTERS {
  GET_DATA = 'getCategoryData',
  GET_INPUTS = 'getFormInputs',
  GET_STEP = 'getFormStep',
  GET_FORM_URL = 'getFormUrl',
  GET_FORM_ID = 'getFormId'
}

export enum CONTACT_FORM_MUTATION {
  SET_STEP = 'setFormStep',
  SET_DATA = 'setFormUrl'
}
export enum CONTACT_FORM_ACTION {
  SET_DATA = 'setFormData'
}

export type FormInputs = Array<FormInput>
export type CategoryData = { [key: string]: FormCategory }

export const FormFilters: Record<string, string> = {
  hu: 'rtl.hu',
  plus:  'RTL+',
  light: 'RTL+ Light',
  active: 'RTL+ Active',
  tv:  'TV adás'
}

export interface FormCategory {
  type: string
  id: string
  name: string
  title: string
  label: string
  categoryName: string
  parentCategory: string | null
  options: Array<FormOption>
}

export interface FormOption {
  value: string
  title: string
  service: Array<string>
  parent: string | null
  filtered?: Array<FormOption>
}

export interface FormInput {
  type: string
  title: string
  mandatory: Array<string>
  optional?: Array<string>
  attrs: InputAttrs
}

export interface InputAttrs {
  id: string,
  maxLength: string
  name: string
  type?: string,
  required?: boolean
}