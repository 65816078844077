import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentCardImg = _resolveComponent("ContentCardImg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["news-card-grid", {
      'news-card-grid--compact': _ctx.compact === true && _ctx.contents.length === 5,
      'news-card-grid--compact-inverse': _ctx.compactInverse === true && _ctx.contents.length === 5,
      'news-card-grid--1': _ctx.contents.length === 1,
      'news-card-grid--2': _ctx.contents.length === 2,
      'news-card-grid--3': _ctx.contents.length === 3,
      'news-card-grid--4': _ctx.contents.length === 4,
      'news-card-grid--5': _ctx.contents.length === 5,
      'news-card-grid--6': _ctx.contents.length === 6
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "news-card-grid__item"
      }, [
        _createVNode(_component_ContentCardImg, _mergeProps({
          "show-title": _ctx.showTitle,
          "show-lead": _ctx.showLead,
          "show-release-date": _ctx.showReleaseDate,
          "show-tag": _ctx.showTag,
          "show-duration": _ctx.showDuration,
          "show-gallery-badge": _ctx.showGalleryBadge,
          size: _ctx.size
        }, _ctx.decorateContent(content)), null, 16, ["show-title", "show-lead", "show-release-date", "show-tag", "show-duration", "show-gallery-badge", "size"])
      ]))
    }), 128))
  ], 2))
}