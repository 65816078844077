
import { ContentData } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'

import ContentDecoratorMixin from '../../mixins/ContentDecoratorMixin'
import ContentCard84 from '../content/ContentCard84.vue'
import Image from '../Image.vue'
import WidgetBlock from './WidgetBlock.vue'

@Options({
  name: 'TopNewsWidgetBlock84',
  components: {
    WidgetBlock,
    ContentCard84,
    Image
  }
})
export default class TopNewsWidgetBlock84 extends mixins(ContentDecoratorMixin) {
  @Prop({ type: Object })
  readonly bigContent!: ContentData | undefined

  @Prop({ type: Array, default: [] })
  readonly secondColContents!: Array<ContentData>

  @Prop({ type: String, default: '' })
  readonly backgroundUrl!: string
}
