import { PostEventRequest } from '@rtl/api'
import { ApiPayload } from '../api'

export { EventResult } from '@rtl/api'

export type EventPayload = ApiPayload<PostEventRequest>

export interface EventState {}

export enum EVENT_ACTION {
  POST = 'postEvent'
}

export enum EVENT_GETTER {
  API = 'api'
}

export const EVENT_NAMESPACE = 'event'
