
import { createApp } from 'vue'
import { Inject, Options, Prop, Provide, Vue } from 'vue-property-decorator'
import VRuntimeTemplate from 'vue3-runtime-template'

import { widgets } from './config'
import GalleryWidget from './GalleryWidget.vue'

@Options({
  name: 'Renderer',
  components: {
    VRuntimeTemplate,
    ...widgets,
    gallery: GalleryWidget
  }
})
export default class Renderer extends Vue {
  @Provide()
  @Inject() // Reinitialize for renderer scope
  contentUrlPrefix: string | undefined

  @Prop({
    type: String,
    default: ''
  })
  readonly template!: string

  @Prop({
    type: [String, Object]
  })
  readonly script!: string | Object

  get app () {
    if (this.script) {
      const options = typeof this.script === 'string'
        ? Function(`"use strict"; return (${this.script})`)()
        : this.script

      const self = this as any
      const vm = self.$

      const app = createApp({
        extends: {
          ...self.$options,
          render () {},
          beforeCreate () {
            this.$.parent = vm
          }
        },
        ...options
      })

      app.config.globalProperties = vm.appContext.config.globalProperties

      return app
    }
  }

  get instance () {
    if (typeof window !== 'undefined') {
      return this.app?.mount(window.document.createElement('div'))
    }
  }

  beforeUnmount () {
    if (typeof window !== 'undefined') {
      this.app?.unmount()
    }
  }
}
