type VisibilityDirectiveBindingParam = {
  value: {
    onChange: (visible: boolean, isBottomDirection: boolean) => void
    observerOptions: IntersectionObserverInit
  }
}

export type VisibilityDirectiveOptions = {
    mounted: (el: HTMLElement, binding: VisibilityDirectiveBindingParam) => void
    unmounted: (el: HTMLElement) => void
    observer: IntersectionObserver | null
}

export const VisibilityObserverDirective: Partial<VisibilityDirectiveOptions> = {
  observer: null,
  mounted (el: HTMLElement, binding) {
    const options = binding.value.observerOptions || { rootMargin: '10px', threshold: 1.0 }

    VisibilityObserverDirective.observer = new IntersectionObserver((p) => {
      const entry: IntersectionObserverEntry = p[0]
      binding.value.onChange(entry.isIntersecting, entry.intersectionRect.y === 0)
    }, options)

    VisibilityObserverDirective.observer?.observe(el)
  },
  unmounted (el) {
    VisibilityObserverDirective.observer?.unobserve(el)
    VisibilityObserverDirective.observer = null
  }
}
