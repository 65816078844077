import { Store, Module, Payload } from 'vuex'

export type CacheRecord<T = unknown> = {
  result?: T
  error?: unknown
}

export type CacheState = {
  records: {
    [key: string]: CacheRecord
  }
}

export type CacheHit = {
  type: string
  payload?: unknown
  record: CacheRecord
}

export type CacheOptions = {
  namespace: string
  preserveState: boolean
  filter: (type: string) => boolean
  error: (error: any) => unknown
}

export default function cachePlugin (options?: Partial<CacheOptions>) {
  const config: CacheOptions = {
    namespace: '$cache',
    preserveState: false,
    filter: () => true,
    error: error => error,
    ...options
  }

  const createKey = (type: string | Array<string>, payload?: unknown) => {
    const prefix: string = Array.isArray(type) ? type.join('/') : type
    return `${prefix}:${JSON.stringify(payload)}`
  }

  const pendingRequests: { [key: string]: Promise<unknown> } = {}

  return (store: Store<unknown>) => {
    store.registerModule(config.namespace, <Module<CacheState, unknown>>{
      namespaced: true,
      state () {
        return {
          records: {}
        }
      },
      getters: {
        record (state) {
          return (type: string | Array<string>, payload?: unknown) => {
            const record = state.records[createKey(type, payload)]
            if (record?.error) {
              return { error: config.error(record.error) }
            }
            return record
          }
        },
        result (state, getters) {
          return (type: string | Array<string>, payload?: unknown) => {
            return getters.record(type, payload)?.result
          }
        }
      },
      mutations: {
        record (state, payload: CacheHit) {
          state.records[createKey(payload.type, payload.payload)] = payload.record
        }
      }
    }, { preserveState: config.preserveState })

    const originalDispatch = store.dispatch
    store.dispatch = async function dispatch (t: any, p?: any) {
      const isObjectStyle = typeof t === 'object' && t.type
      const type: string = isObjectStyle ? t.type : t as string
      const payload: unknown | undefined = isObjectStyle ? t : p

      if (config.filter(type)) {
        const record: CacheRecord = {
          ...store.getters[`${config.namespace}/record`](type, payload)
        }

        if (!Object.hasOwnProperty.call(record, 'result') && !Object.hasOwnProperty.call(record, 'error')) {
          const key = createKey(type, payload)

          if (Object.hasOwnProperty.call(pendingRequests, key)) {
            return pendingRequests[key]
          }

          try {
            record.result = await (pendingRequests[key] = originalDispatch.call(store, t, p))
          } catch (error) {
            record.error = error
          }

          delete pendingRequests[key]
          store.commit(`${config.namespace}/record`, { type, payload, record })
        }

        if (record.error) {
          throw record.error
        }

        return record.result
      }

      return originalDispatch.call(store, t, p)
    }
  }
}
