import { Module } from 'vuex'
import { AdditionalApi } from '@rtl/api'
import { TV_PROGRAM_GETTER, TV_PROGRAM_ACTION, TvProgramState, TvProgramResult, TvProgramPayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createTvProgramModule<RootState> (namespaced = false): Module<TvProgramState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [TV_PROGRAM_GETTER.API]: (state, getters, rootState, rootGetters) => {
        return new AdditionalApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [TV_PROGRAM_ACTION.LIST] ({ getters, rootGetters }, payload: TvProgramPayload): Promise<TvProgramResult> {
        const response = await errorHandler(() => getters[TV_PROGRAM_GETTER.API].getTvprogram({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
