import { Module } from 'vuex'
import { ContentApi } from '@rtl/api'
import { SEARCH_GETTER, SEARCH_ACTION, SearchState, SearchPayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createSearchModule<RootState> (namespaced = false): Module<SearchState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [SEARCH_GETTER.API]: (state, getters, rootState, rootGetters) => {
        return new ContentApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [SEARCH_ACTION.SEARCH] ({ state, getters, rootGetters, commit }, payload: SearchPayload) {
        const response = await errorHandler(() => getters[SEARCH_GETTER.API].search({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
