
import { ContentListQueryOrderDirectionEnum, ContentListQueryOrderFieldEnum, ListDefaultContentsContentTypesEnum, ListDefaultContentsIntervalEnum, MinimalContentData } from '@rtl/api'
import { mixins, Options } from 'vue-property-decorator'

import { ContentListPayload } from '../../store'
import RecommendedVideoList from '../components/content/RecommendedVideoList.vue'
import WidgetBlock from '../components/widget/WidgetBlock.vue'
import { FetchRecommendedVideoListMixin } from '../mixins'
import { shuffle } from './../../utils'

@Options({
  name: 'RecommendedVideoWidget',
  components: {
    WidgetBlock,
    RecommendedVideoList
  }
})
export default class RecommendedVideoWidget extends mixins(FetchRecommendedVideoListMixin) {
  length = 4

  get contents () {
    if (this.videoTagList && this.topNList) {
      const videoTagList: Array<MinimalContentData> = this.videoTagList?.items || []
      const topNList : Array<MinimalContentData> = this.topNList?.items || []
      const list = videoTagList.concat(topNList)

      return shuffle(list).reduce<Array<MinimalContentData>>((picks, item) => {
        if (picks.length < this.length) {
          const key = item.headingTag?.id
          const isUnique = !key || !picks.some(pick => key === pick.headingTag?.id)
          if (isUnique) {
            picks.push(item)
          }
        }
        return picks
      }, [])
    } else {
      return null
    }
  }

  get videoTagPayload (): ContentListPayload {
    return {
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      shuffle: true,
      limit: 10,
      order: [
        {
          field: ContentListQueryOrderFieldEnum.ReleaseDate,
          direction: ContentListQueryOrderDirectionEnum.Desc
        }
      ],
      interval: ListDefaultContentsIntervalEnum.LastWeek,
      withVideo: 1,
      headingTags: ['video']
    }
  }

  get topNListPayload (): ContentListPayload {
    return {
      contentTypes: [ListDefaultContentsContentTypesEnum.News],
      shuffle: true,
      order: [
        {
          field: ContentListQueryOrderFieldEnum.Topn,
          direction: ContentListQueryOrderDirectionEnum.Desc
        }
      ],
      interval: ListDefaultContentsIntervalEnum.LastWeek,
      withVideo: 1
    }
  }
}
