import { Module } from 'vuex'
import { EventApi } from '@rtl/api'
import { EVENT_ACTION, EVENT_GETTER, EventState, EventResult, EventPayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createEventModule<RootState> (namespaced = false): Module<EventState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [EVENT_GETTER.API] (state, getters, rootState, rootGetters) {
        return new EventApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [EVENT_ACTION.POST] ({ getters, rootGetters }, payload: EventPayload): Promise<EventResult> {
        const response = await errorHandler(() => getters[EVENT_GETTER.API].postEvent({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
