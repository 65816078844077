"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedListQueryOrderToJSONTyped = exports.RelatedListQueryOrderToJSON = exports.RelatedListQueryOrderFromJSONTyped = exports.RelatedListQueryOrderFromJSON = exports.RelatedListQueryOrderDirectionEnum = exports.RelatedListQueryOrderFieldEnum = void 0;
/**
* @export
* @enum {string}
*/
var RelatedListQueryOrderFieldEnum;
(function (RelatedListQueryOrderFieldEnum) {
    RelatedListQueryOrderFieldEnum["ReleaseDate"] = "release_date";
    RelatedListQueryOrderFieldEnum["Views"] = "views";
    RelatedListQueryOrderFieldEnum["Priority"] = "priority";
    RelatedListQueryOrderFieldEnum["Topn"] = "topn";
})(RelatedListQueryOrderFieldEnum = exports.RelatedListQueryOrderFieldEnum || (exports.RelatedListQueryOrderFieldEnum = {})); /**
* @export
* @enum {string}
*/
var RelatedListQueryOrderDirectionEnum;
(function (RelatedListQueryOrderDirectionEnum) {
    RelatedListQueryOrderDirectionEnum["Asc"] = "asc";
    RelatedListQueryOrderDirectionEnum["Desc"] = "desc";
    RelatedListQueryOrderDirectionEnum["Random"] = "random";
})(RelatedListQueryOrderDirectionEnum = exports.RelatedListQueryOrderDirectionEnum || (exports.RelatedListQueryOrderDirectionEnum = {}));
function RelatedListQueryOrderFromJSON(json) {
    return RelatedListQueryOrderFromJSONTyped(json, false);
}
exports.RelatedListQueryOrderFromJSON = RelatedListQueryOrderFromJSON;
function RelatedListQueryOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'field': json['field'],
        'direction': json['direction'],
    };
}
exports.RelatedListQueryOrderFromJSONTyped = RelatedListQueryOrderFromJSONTyped;
function RelatedListQueryOrderToJSON(value) {
    return RelatedListQueryOrderToJSONTyped(value, false);
}
exports.RelatedListQueryOrderToJSON = RelatedListQueryOrderToJSON;
function RelatedListQueryOrderToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'field': value.field,
        'direction': value.direction,
    };
}
exports.RelatedListQueryOrderToJSONTyped = RelatedListQueryOrderToJSONTyped;
