import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "widget-block__logo-wrapper"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "widget-block__logo-text" }
const _hoisted_4 = {
  key: 1,
  class: "widget-block__divider"
}
const _hoisted_5 = {
  key: 2,
  class: "widget-block__title-arrow"
}
const _hoisted_6 = { class: "widget-block__title" }
const _hoisted_7 = {
  key: 0,
  class: "slider-arrows"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SliderLeft = _resolveComponent("SliderLeft")!
  const _component_SliderRight = _resolveComponent("SliderRight")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_nav_link = _resolveComponent("nav-link")!

  return (_openBlock(), _createElementBlock("section", {
    ref: "widget",
    class: _normalizeClass(["widget-block", {
      [_ctx.blockClass]: !!_ctx.blockClass,
      'widget-block--background': !!_ctx.bgColor,
      'widget-block--inverse': _ctx.whiteText,
    }])
  }, [
    _createElementVNode("div", {
      class: "container",
      style: _normalizeStyle({
        'background': _ctx.bgColor
      })
    }, [
      (_ctx.validLogoUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("img", {
              src: _ctx.validLogoUrl,
              alt: "",
              class: "widget-block__logo"
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.sponsorText), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showDivider || _ctx.validLogoUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.showTitle && _ctx.hasTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.title), 1),
            (_ctx.showSlider)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.showSliderArrows)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: _normalizeClass(["slider-arrows__arrow slider-arrows__arrow--left", {'slider-arrows__arrow--inactive': _ctx.maxLeft }]),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scroll('left'))),
                        "aria-label": "scroll left"
                      }, [
                        _createVNode(_component_SliderLeft)
                      ], 2))
                    : _createCommentVNode("", true),
                  (_ctx.showSliderArrows)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: _normalizeClass(["slider-arrows__arrow slider-arrows__arrow--right", {'slider-arrows__arrow--inactive': _ctx.maxRight }]),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scroll('right'))),
                        "aria-label": "scroll right"
                      }, [
                        _createVNode(_component_SliderRight)
                      ], 2))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.showButton && _ctx.hasButton)
        ? (_openBlock(), _createBlock(_component_nav_link, {
            key: 3,
            to: _ctx.btnUrl,
            class: "button-show-all",
            "data-content-id": "show_all"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.btnLabel) + " ", 1),
              _createVNode(_component_ArrowRight, { class: "button-show-all__icon" })
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ], 4)
  ], 2))
}