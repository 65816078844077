"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenusToJSONTyped = exports.MenusToJSON = exports.MenusFromJSONTyped = exports.MenusFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MenusFromJSON(json) {
    return MenusFromJSONTyped(json, false);
}
exports.MenusFromJSON = MenusFromJSON;
function MenusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'mainMenu': !(0, runtime_1.exists)(json, 'mainMenu') ? undefined : (0, _1.MenuFromJSON)(json['mainMenu']),
        'submenu': !(0, runtime_1.exists)(json, 'submenu') ? undefined : (0, _1.MenuFromJSON)(json['submenu']),
    };
}
exports.MenusFromJSONTyped = MenusFromJSONTyped;
function MenusToJSON(value) {
    return MenusToJSONTyped(value, false);
}
exports.MenusToJSON = MenusToJSON;
function MenusToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'mainMenu': (0, _1.MenuToJSON)(value.mainMenu),
        'submenu': (0, _1.MenuToJSON)(value.submenu),
    };
}
exports.MenusToJSONTyped = MenusToJSONTyped;
