import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_program_card_list = _resolveComponent("tv-program-card-list")!
  const _component_widget_block = _resolveComponent("widget-block")!

  return (_ctx.channels?.length)
    ? (_openBlock(), _createBlock(_component_widget_block, {
        key: 0,
        title: _ctx.title,
        "show-divider": !!_ctx.title,
        "btn-url": _ctx.btnUrl,
        "btn-label": _ctx.btnLabel,
        "show-slider": _ctx.showSlider
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tv_program_card_list, {
            "tv-programs": _ctx.channels,
            "show-slider": _ctx.showSlider,
            cols: _ctx.cols,
            "item-length": _ctx.itemLength
          }, null, 8, ["tv-programs", "show-slider", "cols", "item-length"])
        ]),
        _: 1
      }, 8, ["title", "show-divider", "btn-url", "btn-label", "show-slider"]))
    : _createCommentVNode("", true)
}