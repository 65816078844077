import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { HTML_CONTENT_ACTION, HTML_CONTENT_GETTERS, HTML_CONTENT_NAMESPACE, HtmlContent, HtmlContentPayload } from '../../store'

@Options({
  name: 'FetchHtmlContentMixin'
})
export default class FetchHtmlContentMixin extends Vue {
  @Action(HTML_CONTENT_ACTION.FETCH, { namespace: HTML_CONTENT_NAMESPACE })
  storeFetchHtmlContent!: (payload: HtmlContentPayload) => Promise<HtmlContent>

  @Getter(HTML_CONTENT_GETTERS.CONTENT_BY_TEMPLATE_URL, { namespace: HTML_CONTENT_NAMESPACE })
  getContentByTemplateUrl!: (id: string | null) => HtmlContent | null

  @Prop({
    type: String,
    default: 'https://storage-live.rtl.hu/asset/bank360/index.html',
  })
  readonly htmlTemplate!: string | null

  get htmlContentPayload (): HtmlContentPayload | null {
    return this.htmlTemplate ? this.htmlTemplate : null
  }

  @Watch('htmlContentPayload', { deep: true })
  async fetchHtmlContent () {
    return this.htmlContentPayload ? this.storeFetchHtmlContent(this.htmlContentPayload) : null
  }

  get htmlContentData () {
    return this.htmlContentPayload ? this.getContentByTemplateUrl(this.htmlContentPayload) || null : null
  }
}
