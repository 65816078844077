import { Options, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { RSS_ACTION, RSS_NAMESPACE, RssFeedInterface, RssPayload } from '../../store'
import { isValidUrl } from './../../utils'

@Options({
  name: 'FetchRssFeedMixin'
})
export default class FetchRssFeedMixin extends Vue {
  @Action(RSS_ACTION.FETCH, { namespace: RSS_NAMESPACE })
  storeFetchRssFeed!: (payload: RssPayload) => Promise<RssFeedInterface>

  @Prop({
    type: String,
    default: false
  })
  readonly rssFeedUrl!: string

  get rssFeedData () {
    if (this.rssPayload) {
      return this.rssFeedUrl? this.$store.getters['rss/getRssFeedByUrl'](this.rssPayload) : null
    }
    return null
  }

  get rssPayload (): RssPayload | null {
    return this.rssFeedUrl && isValidUrl(this.rssFeedUrl) ? this.rssFeedUrl  : null
  }

  @Watch('rssPayload', { deep: true })
  async fetchRssFeed () {
    return this.rssPayload ? this.storeFetchRssFeed(this.rssPayload) : null
  }

  async serverPrefetch () {
    return this.fetchRssFeed()
  }

  mounted () {
    return this.fetchRssFeed()
  }
}
