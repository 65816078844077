import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "auth__label" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value", "disabled"]
const _hoisted_4 = {
  key: 0,
  class: "auth__field-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Exclamation = _resolveComponent("Exclamation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("select", _mergeProps(_ctx.$attrs, {
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      class: ["auth__form-control", { 'auth__form-control--error': _ctx.errorMsg }]
    }), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: option.value,
          disabled: option.disabled
        }, _toDisplayString(option.label), 9, _hoisted_3))
      }), 128))
    ], 16, _hoisted_2),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createVNode(_component_Exclamation),
          _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}