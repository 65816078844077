"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminTagListDataPayloadToJSONTyped = exports.AdminTagListDataPayloadToJSON = exports.AdminTagListDataPayloadFromJSONTyped = exports.AdminTagListDataPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdminTagListDataPayloadFromJSON(json) {
    return AdminTagListDataPayloadFromJSONTyped(json, false);
}
exports.AdminTagListDataPayloadFromJSON = AdminTagListDataPayloadFromJSON;
function AdminTagListDataPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'searchText': !(0, runtime_1.exists)(json, 'searchText') ? undefined : json['searchText'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'offset': !(0, runtime_1.exists)(json, 'offset') ? undefined : json['offset'],
    };
}
exports.AdminTagListDataPayloadFromJSONTyped = AdminTagListDataPayloadFromJSONTyped;
function AdminTagListDataPayloadToJSON(value) {
    return AdminTagListDataPayloadToJSONTyped(value, false);
}
exports.AdminTagListDataPayloadToJSON = AdminTagListDataPayloadToJSON;
function AdminTagListDataPayloadToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'searchText': value.searchText,
        'limit': value.limit,
        'offset': value.offset,
    };
}
exports.AdminTagListDataPayloadToJSONTyped = AdminTagListDataPayloadToJSONTyped;
