import { mixins, Options, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { PAGE_ACTION, PAGE_NAMESPACE, PagePayload, PageResult } from '../../store'
import GetPageMixin from './GetPageMixin'

@Options({
  name: 'FetchPageMixin'
})
export default class FetchPageMixin extends mixins(GetPageMixin) {
  @Action(PAGE_ACTION.ITEM, { namespace: PAGE_NAMESPACE })
  storeFetchPage!: (payload: PagePayload) => Promise<PageResult>

  async onFetchPage (req: Promise<PageResult>) {}

  @Watch('pagePayload', { deep: true })
  async fetchPage () {
    return this.pagePayload ? this.onFetchPage(this.storeFetchPage(this.pagePayload)) : null
  }

  async serverPrefetch () {
    return this.fetchPage()
  }

  mounted () {
    return this.fetchPage()
  }
}
