import { Options, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { TV_PROGRAM_ACTION, TV_PROGRAM_NAMESPACE, TvProgramPayload, TvProgramResult } from '../../store'

@Options({
  name: 'FetchTvProgramListMixin'
})
export default class FetchTvProgramListMixin extends Vue {
  @Action(TV_PROGRAM_ACTION.LIST, { namespace: TV_PROGRAM_NAMESPACE })
  storeFetchTvProgramList!: (payload: TvProgramPayload) => Promise<TvProgramResult>

  get programList (): TvProgramResult['data'] | null {
    return this.$store.getters['$cache/result']([TV_PROGRAM_NAMESPACE, TV_PROGRAM_ACTION.LIST], this.tvProgramPayload)?.data || null
  }

  get tvProgramPayload (): TvProgramPayload {
    return {}
  }

  @Watch('tvProgramPayload', { deep: true })
  async fetchTvProgramList () {
    return this.storeFetchTvProgramList(this.tvProgramPayload)
  }

  serverPrefetch () {
    return this.fetchTvProgramList()
  }

  mounted () {
    return this.fetchTvProgramList()
  }
}
