"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutDataToJSONTyped = exports.LayoutDataToJSON = exports.LayoutDataFromJSONTyped = exports.LayoutDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function LayoutDataFromJSON(json) {
    return LayoutDataFromJSONTyped(json, false);
}
exports.LayoutDataFromJSON = LayoutDataFromJSON;
function LayoutDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'createdBy': !(0, runtime_1.exists)(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTs': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (new Date(json['createdTs'])),
        'createdTsRaw': !(0, runtime_1.exists)(json, 'createdTs') ? undefined : (json['createdTs']),
        'modifiedBy': !(0, runtime_1.exists)(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedTs': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (new Date(json['modifiedTs'])),
        'modifiedTsRaw': !(0, runtime_1.exists)(json, 'modifiedTs') ? undefined : (json['modifiedTs']),
        'skeleton': !(0, runtime_1.exists)(json, 'skeleton') ? undefined : (json['skeleton'].map(_1.BoxFromJSON)),
        'layoutFile': !(0, runtime_1.exists)(json, 'layoutFile') ? undefined : json['layoutFile'],
    };
}
exports.LayoutDataFromJSONTyped = LayoutDataFromJSONTyped;
function LayoutDataToJSON(value) {
    return LayoutDataToJSONTyped(value, false);
}
exports.LayoutDataToJSON = LayoutDataToJSON;
function LayoutDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'folderId': value.folderId,
        'label': value.label,
        'description': value.description,
        'createdBy': value.createdBy,
        'createdTs': value.createdTs === undefined ? undefined : (value.createdTs.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedTs': value.modifiedTs === undefined ? undefined : (value.modifiedTs.toISOString()),
        'skeleton': value.skeleton === undefined ? undefined : (value.skeleton.map(_1.BoxToJSON)),
        'layoutFile': value.layoutFile,
    };
}
exports.LayoutDataToJSONTyped = LayoutDataToJSONTyped;
