import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tv-program-item" }
const _hoisted_2 = { class: "tv-program-item__info" }
const _hoisted_3 = { class: "tv-program-item__tag" }
const _hoisted_4 = { class: "tv-program-item__time" }
const _hoisted_5 = { class: "tv-program-item__age-limit" }
const _hoisted_6 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgeLimit = _resolveComponent("AgeLimit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.time), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AgeLimit, { "age-limit": _ctx.ageLimit }, null, 8, ["age-limit"])
        ])
      ]),
      _createElementVNode("div", {
        class: "tv-program-item__title",
        title: _ctx.title
      }, _toDisplayString(_ctx.title), 9, _hoisted_6)
    ])
  ]))
}