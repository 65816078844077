import { SendMeasurementDataRequest } from '@rtl/api/dist'
import { ApiPayload } from '../api'

export { MeasurementResult } from '@rtl/api'

export interface MeasurementState {
  adoScriptLoaded: boolean;
}

export type MeasurementPayload = ApiPayload<SendMeasurementDataRequest>
export type MeasurementLogAdoPayload = {referer: string, url: string};
export type MeasurementAdoScriptLoadedPayload = MeasurementLogAdoPayload;

export enum MeasurementEventNames {
  checkAdBlock = 'checkAdBlock',
}

export enum MEASUREMENT_ACTION {
  POST = 'post',
  LOG_ADO = 'logAdo',
  ADO_SCRIPT_LOADED = 'adoScriptLoaded',
}

export enum MEASUREMENT_GETTER {
  API = 'api',
  ADO_LOG_REQUEST_DATA = 'adoLogRequestData',
}

export enum MEASUREMENT_MUTATION {
  SET_ADO_SCRIPT_LOADED = 'setAdoScriptLoaded',
}

export const MEASUREMENT_NAMESPACE = 'measurement'
