import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["content-list", { 'content-list--collapsed': _ctx.showBorder }])
  }, [
    _createElementVNode("div", {
      "data-slider-pane": "",
      class: _normalizeClass(["content-list__row", { 'content-list__row--slider': _ctx.showSlider }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (content, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["content-list__col", {
          'content-list__col--2': _ctx.cols === 2,
          'content-list__col--3': _ctx.cols === 3,
          'content-list__col--4': _ctx.cols === 4
        }])
        }, [
          _createVNode(_component_ContentCard, _mergeProps({
            "show-image": _ctx.showImage,
            "show-title": _ctx.showTitle,
            "show-lead": _ctx.showLead,
            "show-release-date": _ctx.showReleaseDate,
            "show-tag": _ctx.showTag,
            "show-duration": _ctx.showDuration,
            "show-gallery-badge": _ctx.showGalleryBadge,
            "show-border": _ctx.showBorder,
            "card-highlighted": _ctx.cardHighlighted,
            "title-highlighted": _ctx.titleHighlighted,
            size: _ctx.size
          }, _ctx.decorateContent(content)), null, 16, ["show-image", "show-title", "show-lead", "show-release-date", "show-tag", "show-duration", "show-gallery-badge", "show-border", "card-highlighted", "title-highlighted", "size"])
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}