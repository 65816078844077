
import { Options, Prop, Vue } from 'vue-property-decorator'

import AgeLimit0 from '../components/icons/AgeLimit0.vue'
import AgeLimit7 from '../components/icons/AgeLimit7.vue'
import AgeLimit12 from '../components/icons/AgeLimit12.vue'
import AgeLimit15 from '../components/icons/AgeLimit15.vue'
import AgeLimit16 from '../components/icons/AgeLimit16.vue'
import AgeLimit18 from '../components/icons/AgeLimit18.vue'

@Options({
  name: 'AgeLimit',
  components: {
    AgeLimit0,
    AgeLimit7,
    AgeLimit12,
    AgeLimit15,
    AgeLimit16,
    AgeLimit18
  }
})
export default class AgeLimit extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly ageLimit!: number
}
