import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tv-show-totem-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tv_show_totem = _resolveComponent("tv-show-totem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "data-slider-pane": "",
      class: _normalizeClass(["tv-show-totem-list__row", { 'tv-show-totem-list__row--slider': _ctx.showSlider }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (show, i) => {
        return (_openBlock(), _createBlock(_component_tv_show_totem, {
          class: "tv-show-totem-list__col",
          key: i,
          "tv-show": show
        }, null, 8, ["tv-show"]))
      }), 128))
    ], 2)
  ]))
}