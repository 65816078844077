import { Module } from 'vuex'
import { TagApi } from '@rtl/api'
import { TAG_GETTER, TAG_ACTION, TagState, TagResult, TagPayload } from './types'
import { API_GETTER, errorHandler } from '../api'

export function createTagModule<RootState> (namespaced = false): Module<TagState, RootState> {
  return {
    namespaced,
    state () {
      return {}
    },
    getters: {
      [TAG_GETTER.API]: (state, getters, rootState, rootGetters) => {
        return new TagApi(rootGetters[API_GETTER.CONFIG])
      }
    },
    actions: {
      async [TAG_ACTION.ITEM] ({ getters, rootGetters }, payload: TagPayload): Promise<TagResult> {
        const response = await errorHandler(() => getters[TAG_GETTER.API].getTagBySlug({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      }
    }
  }
}
