import { Options, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import { GetJWTResponse } from '../../gigya'
import { UserState, USER_ACTION, USER_GETTER, USER_NAMESPACE } from '../../store'

@Options({
  name: 'GetUserMixin'
})
export default class GetUserMixin extends Vue {
  @Getter(USER_GETTER.USER, { namespace: USER_NAMESPACE })
  user!: UserState["user"] | null

  @Action(USER_ACTION.JWT, { namespace: USER_NAMESPACE })
  jwt!: () => Promise<GetJWTResponse>
}
