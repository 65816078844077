"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadMultipartFormDataToJSONTyped = exports.UploadMultipartFormDataToJSON = exports.UploadMultipartFormDataFromJSONTyped = exports.UploadMultipartFormDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UploadMultipartFormDataFromJSON(json) {
    return UploadMultipartFormDataFromJSONTyped(json, false);
}
exports.UploadMultipartFormDataFromJSON = UploadMultipartFormDataFromJSON;
function UploadMultipartFormDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uploadForm': (0, _1.UploadFormDataFromJSON)(json['UploadForm']),
        'siteId': !(0, runtime_1.exists)(json, 'siteId') ? undefined : json['siteId'],
    };
}
exports.UploadMultipartFormDataFromJSONTyped = UploadMultipartFormDataFromJSONTyped;
function UploadMultipartFormDataToJSON(value) {
    return UploadMultipartFormDataToJSONTyped(value, false);
}
exports.UploadMultipartFormDataToJSON = UploadMultipartFormDataToJSON;
function UploadMultipartFormDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'UploadForm': (0, _1.UploadFormDataToJSON)(value.uploadForm),
        'siteId': value.siteId,
    };
}
exports.UploadMultipartFormDataToJSONTyped = UploadMultipartFormDataToJSONTyped;
