import { Module } from 'vuex'
import { errorHandler } from '../api'
import { HTML_CONTENT_ACTION, 
        HTML_CONTENT_MUTATION, 
        HTML_CONTENT_GETTERS, 
        HtmlContentState,
        HtmlContent, 
        HtmlContentPayload } from './types'


export function createHtmlContentModule<RootState> (namespaced = false): Module<HtmlContentState, RootState> {
  return {
    namespaced,
    state () {
      return {
        content: []
      }
    },
    getters: {
      [HTML_CONTENT_GETTERS.CONTENT_BY_TEMPLATE_URL]: (state: HtmlContentState) => (payload: string | null) => {
        return state.content.find((content) => {
          return content.templateUrl === payload
        })
      } 
    },
    mutations: {
      [HTML_CONTENT_MUTATION.HTML_TEMPLATE] (state: HtmlContentState, payload: HtmlContent) {
        if (payload.textResponse) {
          state.content.push(payload)
        }
      }
    },
    actions: {
      async [HTML_CONTENT_ACTION.FETCH] ({ commit }, payload: HtmlContentPayload): Promise<HtmlContent> {
        const result: HtmlContent = {templateUrl: payload, textResponse: null}

        await errorHandler(async (): Promise<any> => {
          const rawResponse = await fetch( new URL(payload!), {method: 'GET'})
          if (rawResponse.ok) {
            const textResponse = await rawResponse.text()
            result.textResponse = textResponse ? textResponse : null
            commit(HTML_CONTENT_MUTATION.HTML_TEMPLATE, result)
          }
          return result
        })

        return result
      },
    }
  }
}



