import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_runtime_template = _resolveComponent("v-runtime-template")!

  return (_ctx.template || _ctx.instance)
    ? (_openBlock(), _createBlock(_component_v_runtime_template, {
        key: 0,
        template: _ctx.template,
        parent: _ctx.instance
      }, null, 8, ["template", "parent"]))
    : _createCommentVNode("", true)
}