import { Options, Vue, Watch } from 'vue-property-decorator'
import { Storage } from '../../../utils'

const DEFAULT_PIPE_KEY = '*'

@Options({
  name: 'MarketingToolMixin',
  inject: { // Implement it natively, because @inject decorator not working across mixins
    marketingManager: {
      from: 'marketingManager'
    }
  }
})
export default class MarketingToolMixin extends Vue {
  readonly marketingManager!: {
    priority: Array<string>
    pipe?: Array<string> | null
    current?: number
  }

  isShow = true
  pipeKey = DEFAULT_PIPE_KEY
  storageKey = ''
  measureKey = ''
  lifetime = 7 * 24 * 60 * 60 * 1000 // 7 days
  isActivePipeItem = false

  get isEnabled (): boolean {
    return true // Implement a custom logic
  }

  get isExpired () {
    if (typeof window !== 'undefined') {
      const expire = Storage.get(this.storageKey)
      if (expire) {
        return Date.now() > expire + this.lifetime
      } else {
        return true
      }
    }
    return false
  }

  get isAvailable () {
    return (typeof window !== 'undefined') && this.isActivePipeItem && this.isShow && this.isExpired && this.isEnabled
  }

  setExpire () {
    Storage.set(this.storageKey, Date.now())
  }

  close (name: string | null, extraParams?: {[key:string]: any}) {
    this.isShow = false
    this.setExpire()
    this.measure('close', name, extraParams)
  }

  measure (action: string, name: string | null, extraParams?: {[key:string]: any}) {
    if (this.$gtm && this.$gtm.enabled()) {
      this.$gtm.trackEvent({
        event: 'interaction',
        event_name: `${action}_promotion`,
        promotion_name: name,
        ...extraParams
      })
    }
  }

  get pipeList () {
    return (this.marketingManager?.pipe ?? []).filter(item => !!item)
  }

  get pipeIndex () {
    return this.marketingManager?.current ?? 0
  }

  @Watch('pipeIndex')
  checkAvailable () {
    if (this.pipeKey === this.pipeList[this.pipeIndex]) {
      this.isActivePipeItem = this.isExpired && this.isEnabled
      if (!this.isActivePipeItem && this.pipeIndex < this.pipeList.length - 1 && this.marketingManager) {
        this.marketingManager.current = this.pipeIndex + 1
      }
    }
  }

  register () {
    if (this.marketingManager) {
      if (!this.marketingManager.pipe) {
        this.marketingManager.pipe = Array(this.marketingManager.priority.length).fill(null)
      }
      const index = this.marketingManager.priority.indexOf(this.pipeKey)
      if (index >= 0) {
        this.marketingManager.pipe[index] = this.pipeKey
      } else {
        this.marketingManager.pipe.push(DEFAULT_PIPE_KEY)
      }
    }
  }

  async beforeMount () {
    if (!(this.$root as any)?.mock) {
      this.register()
    }
  }

  async mounted () {
    this.checkAvailable()
  }

  beforeDestroy () {
    if (this.marketingManager?.current) {
      this.marketingManager.current = 0
    }
    if (this.marketingManager?.pipe) {
      this.marketingManager.pipe = null
    }
  }
}
