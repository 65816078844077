import { MeasurementApi, MeasurementResult } from '@rtl/api'
import { Module } from 'vuex'

import { API_GETTER, errorHandler } from '../api'
import {
  MEASUREMENT_ACTION,
  MEASUREMENT_GETTER,
  MEASUREMENT_MUTATION,
  MeasurementEventNames, MeasurementLogAdoPayload,
  MeasurementPayload,
  MeasurementState
} from './types'

export function createMeasurementModule<RootState> (namespaced = false): Module<MeasurementState, RootState> {
  return {
    namespaced,
    state () {
      return {
        adoScriptLoaded: false
      }
    },
    getters: {
      [MEASUREMENT_GETTER.API] (state, getters, rootState, rootGetters) {
        return new MeasurementApi(rootGetters[API_GETTER.CONFIG])
      },
      [MEASUREMENT_GETTER.ADO_LOG_REQUEST_DATA] () {
        return (referer: string, url: string) => {
          return {
            eventName: MeasurementEventNames.checkAdBlock,
            value: JSON.stringify({
              adBlocked: !window.ado,
              userAgent: window.navigator.userAgent,
              referrer: referer,
              url: url
            })
          }
        }
      }
    },
    actions: {
      async [MEASUREMENT_ACTION.POST] ({ getters, rootGetters }, payload: MeasurementPayload): Promise<MeasurementResult> {
        const response = await errorHandler(() => (getters[MEASUREMENT_GETTER.API] as MeasurementApi).sendMeasurementData({
          ...rootGetters[API_GETTER.PARAMS],
          ...payload
        }))

        return response
      },
      async [MEASUREMENT_ACTION.LOG_ADO] ({ getters, dispatch }, payload: MeasurementLogAdoPayload): Promise<MeasurementResult | undefined> {
        const referer = /:\/\//.test(payload.referer) ? payload.referer : location.origin + payload.referer
        const response = await dispatch(MEASUREMENT_ACTION.POST, getters[MEASUREMENT_GETTER.ADO_LOG_REQUEST_DATA](referer, payload.url))
        return response
      },
      async [MEASUREMENT_ACTION.ADO_SCRIPT_LOADED] ({ state, commit }) {
        if (state.adoScriptLoaded) {
          return
        }

        commit(MEASUREMENT_MUTATION.SET_ADO_SCRIPT_LOADED, true)
      }
    },
    mutations: {
      [MEASUREMENT_MUTATION.SET_ADO_SCRIPT_LOADED] (state, payload: MeasurementState['adoScriptLoaded']) {
        state.adoScriptLoaded = payload
      }
    }
  }
}
