import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "auth__label" }
const _hoisted_2 = { class: "auth__password" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "auth__field-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Eye = _resolveComponent("Eye")!
  const _component_Exclamation = _resolveComponent("Exclamation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        ref: "passwordField",
        type: "password",
        class: ["auth__form-control", { 'auth__form-control--error': _ctx.errorMsg }]
      }), null, 16, _hoisted_3),
      _createVNode(_component_Eye, {
        onClick: _withModifiers(_ctx.togglePassword, ["prevent"])
      }, null, 8, ["onClick"])
    ]),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createVNode(_component_Exclamation),
          _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}