"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.11.22.)
 * RTL 2021 API (2024.11.22.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPStatusCodeToJSONTyped = exports.HTTPStatusCodeToJSON = exports.HTTPStatusCodeFromJSONTyped = exports.HTTPStatusCodeFromJSON = exports.HTTPStatusCode = void 0;
/**
 * HTTP Status Code
 * @export
 * @enum {string}
 */
var HTTPStatusCode;
(function (HTTPStatusCode) {
    HTTPStatusCode[HTTPStatusCode["NUMBER_200"] = 200] = "NUMBER_200";
    HTTPStatusCode[HTTPStatusCode["NUMBER_302"] = 302] = "NUMBER_302";
    HTTPStatusCode[HTTPStatusCode["NUMBER_307"] = 307] = "NUMBER_307";
    HTTPStatusCode[HTTPStatusCode["NUMBER_404"] = 404] = "NUMBER_404";
    HTTPStatusCode[HTTPStatusCode["NUMBER_500"] = 500] = "NUMBER_500";
})(HTTPStatusCode = exports.HTTPStatusCode || (exports.HTTPStatusCode = {}));
function HTTPStatusCodeFromJSON(json) {
    return HTTPStatusCodeFromJSONTyped(json, false);
}
exports.HTTPStatusCodeFromJSON = HTTPStatusCodeFromJSON;
function HTTPStatusCodeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.HTTPStatusCodeFromJSONTyped = HTTPStatusCodeFromJSONTyped;
function HTTPStatusCodeToJSON(value) {
    return HTTPStatusCodeToJSONTyped(value, false);
}
exports.HTTPStatusCodeToJSON = HTTPStatusCodeToJSON;
function HTTPStatusCodeToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.HTTPStatusCodeToJSONTyped = HTTPStatusCodeToJSONTyped;
