import {
  ErrorData,
  HTTPStatusCode,
  ContentData
} from '@rtl/api'

  export type RssPayload = string

  export interface RssFeedInterface {
    rssFeedUrl: string,
    rssFeedData: ContentData[]
  }
  
  export interface RssState {
    rssFeeds: RssFeedInterface[]
  }

  export enum RSS_GETTERS {
    FEED_BY_URL = 'getRssFeedByUrl'
  }

  export enum RSS_MUTATION {
    RSS_FEED = 'setRssFeed'
  }

  export enum RSS_ACTION {
    FETCH = 'fetch'
  }
  
  export const RSS_NAMESPACE = 'rss'

  // todo?
  export interface RssResponse {
    body: ReadableStream<Uint8Array> | null,
    status?: HTTPStatusCode,
    error?: ErrorData
}
