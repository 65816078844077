
import { MenuItem } from '@rtl/api'
import { Options, Prop, Ref, Vue } from 'vue-property-decorator'

import ChevronDown from '../icons/ChevronDown.vue'

@Options({
  name: 'TvShowHeroNav',
  components: {
    ChevronDown
  }
})
export default class TvShowHeroNav extends Vue {
  @Prop({ type: Array })
  readonly menuItems!: MenuItem[] | undefined

  @Prop({ type: Boolean, default: false })
  readonly noPaddingLeft!: boolean

  @Ref('heroNav')
  heroNav!: HTMLElement

  showNavMore = true
  lastNavWidth = 0

  navObserver: ResizeObserver | null = null

  hideMenuItems () {
    const heroNavList = document.querySelector('.tv-show-hero__nav-list')
    this.showNavMore = true

    if (heroNavList) {
      const heroNavListWidth = heroNavList.clientWidth
      let itemsWidth = this.calcLiElemsWidth(heroNavList.children || [])

      // last element is the more button and we don't want to hide it (-2)
      for (let i = heroNavList.children.length - 2; i >= 0; i--) {
        const liElem = heroNavList.children.item(i)

        if (liElem) {
          const liElemWidth = liElem.clientWidth

          liElem.classList.add('tv-show-hero__nav-item--hidden')
          itemsWidth -= liElemWidth

          if (itemsWidth < heroNavListWidth) {
            break
          }
        }
      }
    }
  }

  showMenuItems () {
    const heroNavList = document.querySelector('.tv-show-hero__nav-list')
    this.showNavMore = false

    if (heroNavList) {
      const heroNavListWidth = heroNavList.clientWidth
      let itemsWidth = this.calcLiElemsWidth(heroNavList.children || [])

      for (let i = 0; i < heroNavList.children.length - 1; i++) {
        const liElem = heroNavList.children.item(i)

        if (liElem) {
          if (liElem.classList.contains('tv-show-hero__nav-item--hidden')) {
            liElem.classList.remove('tv-show-hero__nav-item--hidden')
            const liElemWidth = liElem.clientWidth

            if ((itemsWidth + liElemWidth) > heroNavListWidth) {
              liElem.classList.add('tv-show-hero__nav-item--hidden')
              this.showNavMore = true
              break
            }

            itemsWidth += liElemWidth
          }
        }
      }
    }
  }

  showAllMenuItems () {
    this.navObserver?.unobserve(this.heroNav)
    const heroNavList = document.querySelector('.tv-show-hero__nav-list')

    if (heroNavList) {
      for (const liElem of heroNavList.children) {
        liElem.classList.remove('tv-show-hero__nav-item--hidden')
      }
    }

    this.showNavMore = false
  }

  calcLiElemsWidth (liElems: HTMLCollection): number {
    let liElemsWidth = 0

    for (const elem of liElems) {
      liElemsWidth += elem.clientWidth
    }

    return liElemsWidth
  }

  mounted () {
    this.navObserver = new ResizeObserver(elems => {
      const elem = elems.pop()

      if (elem && elem.contentRect.width !== this.lastNavWidth) {
        const heroNavList = document.querySelector('.tv-show-hero__nav-list')

        if (heroNavList) {
          const heroNavListWidth = heroNavList.clientWidth
          const liElemsWidth = this.calcLiElemsWidth(heroNavList.children)

          if (liElemsWidth > heroNavListWidth) {
            this.hideMenuItems()
          } else {
            this.showMenuItems()
          }
        }

        this.lastNavWidth = elem.contentRect.width
      }
    })

    this.navObserver?.observe(this.heroNav)
  }

  unmounted () {
    this.navObserver?.disconnect()
  }

  dropdownClick (event: PointerEvent) {
    const elem = event.currentTarget as HTMLElement
    if (elem.classList.contains('open')) {
      elem.classList.remove('open')
      elem.blur()
    } else {
      elem.classList.add('open')
    }
  }
}
