
import { mixins, Options } from 'vue-property-decorator'

import ContentCardMixin from '../../mixins/ContentCardMixin'
import GalleryFull from '../icons/GalleryFull.vue'
import Play from '../icons/Play.vue'
import Image from '../Image.vue'
import NavLink from '../NavLink.vue'
import Tag from '../Tag.vue'

@Options({
  name: 'ContentCardImg',
  components: {
    GalleryFull,
    Image,
    NavLink,
    Play,
    Tag
  }
})
export default class ContentCardImg extends mixins(ContentCardMixin) {}
