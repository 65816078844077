
import { SiteData, SiteTypes } from '@rtl/api'
import { mixins, Options, Prop } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'

import TvShowHeroWidgetBlock from '../components/widget/TvShowHeroWidgetBlock.vue'
import { GetPageMixin } from '../mixins'
import { WidgetMeta } from './WidgetMixin'

export const meta: WidgetMeta = {
  label: 'Műsor hero',
  fields: {
    description: {
      type: String,
      props: {
        placeholder: 'Leírás'
      }
    },
    btnUrl: {
      type: String,
      props: {
        placeholder: 'CTA link (url)'
      }
    },
    btnLabel: {
      type: String,
      props: {
        placeholder: 'CTA link (szöveg)'
      }
    },
    fullBroadcasts: {
      type: Boolean,
      default: true,
      props: {
        label: 'Teljes adások'
      }
    }
  },
  timing: false
}

@Options({
  name: 'TvShowHeroWidget',
  components: {
    TvShowHeroWidgetBlock
  }
})
export default class TvShowHeroWidget extends mixins(GetPageMixin) {
  @Prop({
    type: String
  })
  readonly description: string | undefined

  @Prop({
    type: [String, Object]
  })
  readonly btnUrl: RouteLocationRaw | undefined

  @Prop({
    type: String
  })
  readonly btnLabel!: string

  @Prop({
    type: Boolean,
    default: true
  })
  readonly fullBroadcasts!: boolean

  get site (): SiteData | undefined {
    return this.page?.site
  }

  get isShow (): boolean {
    return this.site?.type?.toLowerCase() === SiteTypes.Show
  }

  get cover (): string | null {
    const params = this.site?.parameters

    if (params?.design?.tvshowCoverImageUrls) {
      const imageUrls = params.design.tvshowCoverImageUrls as unknown as { 'tvshowCoverImageUrl': string }
      return imageUrls.tvshowCoverImageUrl
    }

    return null
  }

  get logo (): string | null {
    const params = this.site?.parameters

    if (params?.design?.tvshowImageUrls) {
      const imageUrls = params.design.tvshowImageUrls as unknown as { 'tvshowImageUrl': string }
      return imageUrls.tvshowImageUrl
    }

    return null
  }

  get menu () {
    return this.page?.menu?.contentMenu?.menuItems || []
  }

  get info () {
    return this.description || this.site?.parameters?.design?.tvshowDescriptionText
  }

  get extraBtnLabel () {
    return this.btnLabel || this.site?.parameters?.design?.tvshowCtaText
  }

  get extraBtnUrl () {
    return this.btnUrl || this.site?.parameters?.design?.tvshowCtaLink
  }
}
