import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_page = _resolveComponent("error-page")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_dynamic_layout = _resolveComponent("dynamic-layout")!

  return (_openBlock(), _createBlock(_component_dynamic_layout, null, {
    default: _withCtx(() => [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_error_page, {
            key: 0,
            error: _ctx.error
          }, null, 8, ["error"]))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
    ]),
    _: 1
  }))
}