
import { HeadingTag } from '@rtl/api'
import { Options, Prop, Vue } from 'vue-property-decorator'

import Live from './icons/Live.vue'

type Rgb = {
  r: number
  g: number
  b: number
}

@Options({
  name: 'Tag',
  components: {
    Live
  }
})
export default class Tag extends Vue {
  @Prop({ type: Object, required: true })
  readonly tag!: HeadingTag

  @Prop({ type: Boolean, default: false })
  readonly showHashtag!: boolean

  @Prop({ type: Boolean, default: true })
  readonly showColor!: boolean

  @Prop({ type: Boolean, default: false })
  readonly forceTagLink!: boolean

  @Prop({ type: Boolean, default: false })
  readonly isLive!: boolean

  get url () {
    return (!this.forceTagLink && this.tag.site?.url) || this.tag.url
  }

  isContrast () {
    if (this.tag.tagColor) {
      const rgb = this.hexToRgb(this.tag.tagColor)
      if (rgb) {
        return ((Math.round(rgb.r * 299) + Math.round(rgb.g * 587) + Math.round(rgb.b * 114)) / 1000) >= 160
      }
    }
    return true
  }

  hexToRgb (hex: string): Rgb | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }
}
