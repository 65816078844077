export type ApiPayload<
  T,
  PartialKeys extends keyof T = Exclude<T, T>,
  OmitKeys extends keyof T = Exclude<T, T>
> = Omit<T, 'requestReferer' | OmitKeys | PartialKeys>
  & Partial<Pick<T, PartialKeys>>

export interface ApiState {
  accessToken: string | null,
  path: string | null
}

export enum API_GETTER {
  CONFIG = 'apiConfig',
  PARAMS = 'apiParams'
}

export enum API_MUTATION {
  TOKEN = 'setAccessToken',
  PATH = 'setPath'
}

export const API_NAMESPACE = 'api'
