import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "image-gallery__content" }
const _hoisted_2 = { class: "image-gallery__badge" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GalleryOutline = _resolveComponent("GalleryOutline")!

  return (_ctx.content?.url)
    ? (_openBlock(), _createElementBlock("figure", {
        key: 0,
        class: "image image-gallery",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.galleryClick()))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_GalleryOutline)
          ]),
          _createElementVNode("img", {
            src: _ctx.leadImage,
            alt: _ctx.leadText
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("figcaption", null, [
          (_ctx.leadText)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.leadText), 1))
            : _createCommentVNode("", true),
          (_ctx.leadText && _ctx.source)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " - "))
            : _createCommentVNode("", true),
          (_ctx.source)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Forrás: " + _toDisplayString(_ctx.source), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}